import React from "react";
import TransparentLoader from "../Global/TransparentLoader";
import ChatMessage from "./ChatMessage";
//hooks

import { NavLink } from "react-router-dom";

const AllMessages = ({ user, messages, isLoading, error }) => {
  if (isLoading) return <TransparentLoader />;
  if (error) return <h1>Molimo pokušajte kasnije</h1>;
  if (user.role === parseInt(process.env.REACT_APP_COMPANY))
    return (
      <div className="all-messages scroll ">
        {messages?.map((message) => (
          <NavLink key={message.id} to={`/pocetna/poruke/${message.user.id}`}>
            <ChatMessage message={message} />
          </NavLink>
        ))}
      </div>
    );
  return (
    <div className="all-messages scroll">
      {messages?.map((message) => (
        <NavLink key={message.id} to={`/pocetna/poruke/${message.company.id}`}>
          <ChatMessage message={message} />
        </NavLink>
      ))}
    </div>
  );
};

export default AllMessages;
