import React from "react";

function TextAreaInput(props) {
  return (
    <div className={`input-textarea  ${props.className}`}>
      <label className="input-label">{props.label}</label>
      {props.touched && props.errors ? (
        <p className="input-errors">{props.errors}</p>
      ) : (
        ""
      )}
      <textarea
        autoComplete="off"
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        type={props.type}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
        className={`textarea box-drop-shadow ${props.inputClassname}`}
        rows="4"
      />
    </div>
  );
}

export default TextAreaInput;
