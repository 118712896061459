import React, { useState } from 'react'
import usePackages from '../../hooks/offers/usePackages'
import PricingModelItem from './PricingModelItem'
import { useTranslation } from 'react-i18next'
import GenerateProformaInvoice from './GenerateProformaInvoice'

const PricingModels = () => {
    const { data: packages } = usePackages()
    const [showModal, setShowModal] = useState(false)
    const { t } = useTranslation()

    return (
        <div className='pricing-container'>
            <GenerateProformaInvoice showModal={showModal} setShowModal={setShowModal} />
            <h2 className='pricing-models-title' >{t('pricingModels')}</h2>
            <ul className='pricing-list'>
                {packages?.map((pcg) => <PricingModelItem key={pcg?.id} {...pcg} setShowModal={setShowModal} />)}
            </ul>
        </div>
    )
}

export default PricingModels