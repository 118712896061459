import i18n from "../i18n";
import gbFlag from "../../assets/flags/gb.png";
import bscm from "../../assets/flags/bscm.png";
import mkd from "../../assets/flags/nmk.jpeg";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import axios from "../../apis/axiosInstance";
import useAxiosFetch from "../../hooks/useAxiosFetch";

export const SwitchLanguage = ({ styleClass }) => {
  const { user, auth } = useAuth();

  const { error, axiosFetch } = useAxiosFetch();

  const changeLanguageHandler = async (lang) => {

    await i18n.changeLanguage(lang);
    if (user) {
      await axiosFetch({
        axiosInstance: axios,
        method: "get",
        url: `/lang/${lang}`,
      });
      auth();
    }
  };

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user?.user?.lang);

    }
  }, [user]);

  if (error) return <span>Molimo ponovo pokrenite aplikaciju</span>;
  return (
    <div className={`switch-lang  ${styleClass}`}>
      <button
        className="switch-lang-btn"
        onClick={() => changeLanguageHandler("en")}
      >
        <img src={gbFlag} alt="gbFlag" className="switch-lang-img" />
      </button>

      <button
        className="switch-lang-btn"
        onClick={() => changeLanguageHandler("srb")}
      >
        <img src={bscm} alt="balkanFlag" className="switch-lang-img" />
      </button>
      <button
        className="switch-lang-btn"
        onClick={() => changeLanguageHandler("mkd")}
      >
        <img src={mkd} alt="mkdFlag" className="switch-lang-img" />
      </button>
    </div>
  );
};
