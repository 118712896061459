import React from "react";
import Lottie from "lottie-react";
import circleLoader from "../../../assets/animations/jobLoader2.json";

const OfferLoader = () => {
  return (
    <div className="offer-loader">
      <div className="offer-loader-anim">
        <Lottie animationData={circleLoader} autoPlay={true} loop={true} />
      </div>
    </div>
  );
};

export default OfferLoader;
