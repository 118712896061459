import React from "react";
//editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const TextEditor = (props) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={props?.value ? props.value : ""}
      onChange={(event, editor) => {
        const data = editor.getData();
        props.setEditorText(data);
      }}
      config={{
        toolbar: [
          "Heading",
          "|",
          "Bold",
          "Italic",
          "Link",
          "BlockQuote",
          "Undo",
          "numberedList",
          "bulletedList",
          "Redo",
        ],

        removePlugins: [
          "EasyImage",
          "ImageUpload",
          "Table",
          "TableToolbar",
          "MediaEmbed",
        ],
      }}
    />
  );
};

export default TextEditor;
