import React from "react";
import { useTranslation } from "react-i18next";
import voidLogo from "../../assets/images/void.svg";
import Lottie from "lottie-react";
import animationData from "../../assets/animations/rocket.json";

const NoData = () => {
  const { t } = useTranslation();

  return (
    <div className="nodata-screen">
      <Lottie
        animationData={animationData}
        style={{ background: "transparent", height: 300 }}
      />
      <h3 className="nodata-screen-title">{t("nothing_here")}</h3>
    </div>
  );
};

export default NoData;
