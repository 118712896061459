import axios from "axios";
import { useMutation } from "react-query";


const createInvoice = async (values) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/invoice`, values, {
        headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
        }, responseType: 'blob',
    });
};

const usePreInvoice = () => {


    return useMutation(
        async (values) => {
            return createInvoice(values);
        },
        {
            onSuccess: async (data) => {
                try {
                    const url = window.URL.createObjectURL(new Blob([data?.data], { type: 'application/pdf' }));
                    window.open(url, '_blank'); // Open the PDF in a new tab
                } catch (error) {
                    console.error("Error opening PDF:", error);
                }
            },
        }
    );
};

export default usePreInvoice;
