import axios from "axios";
import { useQuery } from "react-query";

const fetchFunction = (slug) => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}/posts/${slug}`);
};

const useArticleFetch = (slug) => {
  return useQuery([`posts/${slug}`], () => fetchFunction(slug));
};

export default useArticleFetch;
