import React from "react";
import { Link } from "react-router-dom";
import LinkItem from "../Events/LinkItem";
//motion
import { fadeIn, moveHamburger } from "../Functions/Animations";
import { motion } from "framer-motion";
//hooks
import useAuth from "../../hooks/useAuth";
import NotificationMessage from "./NotificationMessage";
import { SwitchLanguage } from "../../languages/components/SwitchLanguage";
import { useTranslation } from "react-i18next";

const HamburgerCompany = () => {
  const { logout, user } = useAuth();
  const { t } = useTranslation();

  return (
    <motion.div
      animate="show"
      initial="hidden"
      exit="exit"
      variants={moveHamburger}
      className="hamburger box-drop-shadow"
    >
      <Link to="/pocetna/profil" className="hamburger-profile">
        <img
          className="hamburger-profile-img"
          alt="profile"
          src={user?.user.logo}
        />
        <span className="hamburger-profile-text">{user.user.name}</span>
      </Link>
      <SwitchLanguage styleClass="hamburger-lang-switch" />
      <motion.ul variants={fadeIn} className="hamburger-list">
        <li>
          <LinkItem title={t("nav_home")} link="/pocetna-stranica" />
        </li>
        <li >
          <LinkItem title={t("nav_events")} link="/dogadjaji" />
        </li>
        <li >
          <LinkItem title={t("nav_blog")} link="/blog" />
        </li>
        <li >
          <LinkItem title={t("nav_forum")} link="/pocetna/forum/teme" />
        </li>
        <li >
          <LinkItem title={t("my_profile")} link="/pocetna/profil" />
        </li>
        <li >
          <LinkItem
            title={t("create_job_offer")}
            link="/pocetna/kreiraj-oglas"
          />
        </li>
        <li >
          <LinkItem
            title={t("create_project_offer")}
            link="/pocetna/kreiraj-projekat"
          />
        </li>
        <li >
          <LinkItem
            title={t("my_offers")}
            link="/pocetna/moji-oglasi/poslovi"
          />
        </li>
        <li>
          <LinkItem title={t("nav_offers")} link="/pocetna/oglasi" />
        </li>
        <li >

          <LinkItem title={t("messages")} link="/pocetna/poruke" />
        </li>
        <li >
          <LinkItem title={t("candidates")} link="/pocetna/korisnici" />
        </li>

        <li >
          <LinkItem title={t("nav_companies")} link="/pocetna/firme" />
        </li>

        <li >
          <LinkItem title={t("nav_aboutUs")} link="/o-nama" />
        </li>
        <li onClick={() => logout()}>
          <LinkItem title={t("nav_logOut")} link="/login" />
        </li>
      </motion.ul>
    </motion.div>
  );
};
export default HamburgerCompany;
