import React from "react";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { popup } from "../../Functions/Animations";
import { useTranslation } from "react-i18next";

import CompanyDropDownItems from "./CompanyDropDownItems";
import useAuth from "../../../hooks/useAuth";

function CompanyDropdownMenu() {
  const { logout } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <motion.ul
        className="dropdown"
        initial="hidden"
        animate="show"
        variants={popup}
      >
        <CompanyDropDownItems />
        <li onClick={() => logout()}>
          <Link to="/" className="dropdown-item">
            {t("nav_logOut")}
          </Link>
        </li>
      </motion.ul>
    </>
  );
}

export default CompanyDropdownMenu;
