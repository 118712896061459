import React, { useEffect, useState } from "react";

//icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
//formik
import { FieldArray, Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { isNumber } from "lodash";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const schema = Yup.object({
  new_course: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Obavezno polje"),
    })
  ),
});

const UserCourses = ({ courses }) => {
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const initialValues = {
    new_course:
      courses?.length > 0
        ? courses
        : [
            {
              name: "",
              document1: "",
            },
          ],
  };

  const coursesChangeHandler = async (values) => {
    const formData = new FormData();
    const newValues = values.new_course.filter((value) => !value.id);
    const oldValues = values.new_course.filter((value) => isNumber(value.id));

    oldValues?.map(
      (value) => (
        formData.append(`course[${value.id}][name]`, value.name),
        formData.append(`course[${value.id}][file]`, value.document1)
      )
    );
    newValues?.map(
      (value) => (
        formData.append(`new_course[new][name]`, value.name),
        formData.append(`new_course[new][file]`, value.document1)
      )
    );

    try {
      const data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/update-courses`,
        formData,
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      setMessage(data.data.message);
    } catch (error) {
      setMessage("Doslo je do greske");
    }
  };

  return (
    <div className="usersettings-item">
      <span className="usersettings-item-title">
        {t("non_formal_education")}
      </span>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(value) => {
          coursesChangeHandler(value);
        }}
      >
        {({ values, setFieldValue, touched, handleBlur }) => (
          <Form encType="multipart/form-data" className="usersettings-form">
            <FieldArray name="new_course">
              {({ insert, push, remove }) => (
                <div>
                  {values.new_course.map((element, index) => (
                    <div
                      key={index}
                      className=" form-element inline-inputs-desktop"
                    >
                      {values.new_course.length > 1 && (
                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className="input-trash-btn"
                        >
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            color="red"
                          ></FontAwesomeIcon>
                        </button>
                      )}
                      <div className="input input-padding">
                        <label
                          className="input-label"
                          htmlFor={`new_course.${index}.name`}
                        >
                          {t("skills")} , {t("knowledge")}, {t("competitions")}
                        </label>
                        <Field
                          name={`new_course.${index}.name`}
                          type="text"
                          className="input-field"
                        />

                        <ErrorMessage
                          name={`new_course.${index}.name`}
                          className="field-error"
                        >
                          {(msg) => <span className="input-errors">{msg}</span>}
                        </ErrorMessage>
                      </div>
                      <div className="input input-padding">
                        <label
                          className="input-label"
                          htmlFor={`new_course.${index}.document1`}
                        >
                          DIPLOMA , {t("certificate")}
                        </label>
                        <input
                          name={`new_course.${index}.document1`}
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(event) => {
                            setFieldValue(
                              `new_course.${index}.document1`,
                              event.currentTarget.files[0]
                            );
                          }}
                        />
                        {values.new_course[index]?.document1 && (
                          <a
                            rel="noreferrer"
                            target="_blank"
                            className="usersettings-file-link"
                            href={`http://studenter.org/${values.new_course[index]?.document1}`}
                          >
                            {values.new_course[index].name}
                          </a>
                        )}
                        <ErrorMessage
                          name={`new_course.${index}.document1`}
                          component="div"
                          className="field-error"
                        >
                          {(msg) => <span className="input-errors">{msg}</span>}
                        </ErrorMessage>
                      </div>
                    </div>
                  ))}
                  <div className="form-add-more">
                    <button
                      type="button"
                      onClick={() =>
                        values.new_course.length < 5 &&
                        push({ name: "", document1: "" })
                      }
                    >
                      {values.new_course.length < 5 && (
                        <FontAwesomeIcon
                          className="form-add-more-icon"
                          icon={faPlusCircle}
                          color="#64748b"
                        />
                      )}
                    </button>
                  </div>
                  <span className="changes-message  input-padding">
                    {message}
                  </span>
                  <div className="form-submit-wrapper input-padding">
                    <button type="submit" className="form-submit">
                      {t("save")}
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserCourses;
