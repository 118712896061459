import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const EventCard = (event) => {
  const {
    title,
    type,
    company,
    slug,
    career_field,
    meta_description,
    city,
    created_at,
  } = event;

  function createMarkup() {
    return { __html: meta_description };
  }

  const { t } = useTranslation();
  if (event)
    return (
      <div className="keen-slider__slide   event-card-slide">
        <img
          className="event-card-img"
          alt="logo"
          src={`https://studenter.org/${event?.image}`}
        />
        <span
          //onClick={() => filterByType(type)}
          className="slider-offer-type hide-for-mobile"
        >
          {t("events_title")}
        </span>
        <div className="flexCol gap-16 padding-16 flex-space-between width-100 height-100">
          <div className="flexCol gap-16 pt-24">
            <span className="job-card-slide-title">{event?.title}</span>
            <span className="event-card-slide-text-desc">
              {event?.location}
            </span>
            <span className="event-card-slide-text-desc">
              {event?.subtitle}
            </span>
          </div>

          <Link to={`/dogadjaji/dogadjaj/${slug}`} className="slider-cta">
            {t("show_more")}
          </Link>
        </div>
      </div>
    );
};

export default EventCard;
