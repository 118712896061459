import React, { useEffect, useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

import MainSliderLoader from "./MainSliderLoader";
import EventCard from "./EventCard";
import useEventsPreview from "../../hooks/ladning/useEventsPreview";

export default () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slides: {
      perView: 1,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });
  const { data: events, isLoading, isError } = useEventsPreview();
  if (isLoading) return null;
  if (isError) return null;
  if (events?.length < 1) return null;

  return (
    <div className="slider-s">
      {events ? (
        <div ref={sliderRef} className="keen-slider" style={{ flexGrow: 1 }}>
          {events?.map((job) => (
            <EventCard {...job} key={job.id} />
          ))}
        </div>
      ) : (
        <MainSliderLoader />
      )}
      {loaded && instanceRef?.current && (
        <>
          <Arrow
            left
            onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
            disabled={currentSlide === 0}
          />

          <Arrow
            onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
            disabled={
              currentSlide ===
              instanceRef.current.track.details.slides.length - 1
            }
          />
        </>
      )}
    </div>
  );
};

function Arrow(props) {
  const disabeld = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabeld}`}
      viewBox="0 0 24 24"
      width="12"
      height="12"
    >
      {props.left && (
        <g transform="scale(0.7)">
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        </g>
      )}
      {!props.left && (
        <g transform="scale(0.7)">
          <path
            d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"
            strokeWidth="1"
          />
        </g>
      )}
    </svg>
  );
}
