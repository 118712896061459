import { useTranslation } from "react-i18next";
import "../../../languages/i18n";
import { Link } from "react-router-dom";

import React from "react";

const CompanyDropDownItems = () => {
  const { t } = useTranslation();

  return (
    <>
      <li>
        <Link className="dropdown-item" to="/pocetna/profil">
          {t("my_profile")}
        </Link>
      </li>
      <li>
        <Link className="dropdown-item" to="/pocetna/kreiraj-oglas">
          {t("create_job_offer")}
        </Link>
      </li>
      <li>
        <Link className="dropdown-item" to="/pocetna/kreiraj-projekat">
          {t("create_project_offer")}
        </Link>
      </li>
      <li>
        <Link className="dropdown-item" to="/pocetna/moji-oglasi/poslovi">
          {t("my_offers")}
        </Link>
      </li>
    </>
  );
};

export default CompanyDropDownItems;
