import React from "react";
import Footer from "../Global/Footer";
import NavigationBar from "../NavBar/NavigationBar";
//animations
import { fadeIn, moveLeft } from "../../components/Functions/Animations";
import { motion } from "framer-motion";
import { Outlet } from "react-router";
import BlogHomeHeader from "./BlogHomeHeader";
import BlogArticles from "../../pages/Blog/BlogArticles";
import { Helmet } from "react-helmet-async";

const BlogHome = () => {
  return (
    <div className="blog">
      <Helmet>
        <title>Studenter | Blog</title>
        <meta
          name="description"
          content="Dobro došli na novi blog StudENTER platforme."
        />
        <link rel="canonical" href="/blog" />
        <meta
          name="keywords"
          content="StudENTER, blog, student, privreda, posao, oglasi"
        />
      </Helmet>

      <BlogArticles />
    </div>
  );
};

export default BlogHome;
