import React, { useState } from "react";
//components
import InputField from "../Inputs/InputField";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useTranslation } from "react-i18next";

const PasswordChange = () => {
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const passwordChangeHandler = async (values) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/update-password`,
        values,
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      setMessage(data.data.message);
    } catch (error) {
      setMessage(
        error.response.data.password + " " || error.response.data.message
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required(t("required_field")),
      password: Yup.string().required(t("required_field")),
      password_confirmation: Yup.string().required(t("required_field")),
    }),
    onSubmit: (values) => {
      passwordChangeHandler(values);
    },
  });
  return (
    <div className="usersettings-item">
      <span className="usersettings-item-title">{t("password")}</span>
      <form onSubmit={formik.handleSubmit} className="usersettings-form">
        <div className="inline-inputs-desktop">
          <InputField
            className="input-padding"
            type="password"
            placeholder=""
            label={t("old_password")}
            name="old_password"
            onBlur={formik.handleBlur}
            touched={formik.touched.old_password}
            onChange={formik.handleChange}
            errors={formik.errors.old_password}
            value={formik.values.old_password}
            isPasswordVisible={true}
          ></InputField>

          <InputField
            className="input-padding"
            type="password"
            placeholder=""
            label={t("new_password")}
            name="password"
            onBlur={formik.handleBlur}
            touched={formik.touched.password}
            onChange={formik.handleChange}
            errors={formik.errors.password}
            value={formik.values.password}
            isPasswordVisible={true}
          />

          <InputField
            className="input-padding"
            type="password"
            isPasswordVisible={true}
            placeholder=""
            label={t("confirm_password")}
            name="password_confirmation"
            onBlur={formik.handleBlur}
            touched={formik.touched.password_confirmation}
            onChange={formik.handleChange}
            errors={formik.errors.password_confirmation}
            value={formik.values.password_confirmation}
          />
        </div>
        <span className="changes-message input-padding">{message} </span>
        <div className="form-submit-wrapper input-padding">
          <button type="submit" className="form-submit">
            {t("save")}
          </button>
        </div>
      </form>
    </div>
  );
};
export default PasswordChange;
