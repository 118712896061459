import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import { TRANSLATIONS_SRB } from "./locales/srb/translation_srb";
import { TRANSLATIONS_EN } from "./locales/en/translation_en";
import { TRANSLATIONS_MKD } from "./locales/mkd/translation_mkd";

const fallbackLng = ["srb"];
const availableLanguages = ["srb", "en", 'mkd'];

i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng,
    detection: {
      checkWhitelist: true,
    },
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      srb: {
        translation: TRANSLATIONS_SRB,
      },
      mkd: {
        translation: TRANSLATIONS_MKD,
      },
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
