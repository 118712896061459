import React from "react";
import Lottie from "lottie-react";
import circleLoader from "../../assets/animations/circleLoader.json";
import { useIsFetching } from "react-query";

const FilterLoader = () => {
  const isFetching = useIsFetching();

  return (
    <div className="filter-circleloader">
      {isFetching ? <Lottie animationData={circleLoader} /> : null}
    </div>
  );
};

export default FilterLoader;
