import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CompanyApply = () => {
  const { t } = useTranslation();
  return (
    <div className="offer-page-apply">
      <h3 className="offer-page-apply-title">{t("create_offer")}</h3>

      <span className="offer-apply-description">
        {t("offer_ads_description")}
      </span>

      <Link to="/pocetna/kreiraj-oglas" className="offer-page-apply-btn">
        {t("create_job_offer")}
      </Link>
      <Link to="/pocetna/kreiraj-projekat" className="offer-page-apply-btn">
        {t("create_project_offer")}
      </Link>
    </div>
  );
};

export default CompanyApply;
