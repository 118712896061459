import React from "react";
import { useTranslation } from "react-i18next";
import apply from "../../../assets/images/apply.svg";

const AlreadyApplied = () => {
  const { t } = useTranslation();
  return (
    <div className="offer-page-apply">
      <h3 className="offer-page-apply-title">{t("offer_applied")}</h3>
      <img className="offer-apply-img" src={apply} alt="apply" />
    </div>
  );
};

export default AlreadyApplied;
