import { useTranslation } from "react-i18next";
import UserDelete from "../UserSettings/UserDelete";
import CompanyDelete from "./CompanyDelete";
import CompanyInfo from "./CompanyInfo";
import CompanyPassword from "./CompanyPassword";

const CompanySettings = () => {
  const { t } = useTranslation();
  return (
    <div className="usersettings box-drop-shadow">
      <div className="usersettings-heading">
        <span className="usersettings-title">{t("company_info")}</span>
      </div>
      <CompanyInfo />
      <CompanyPassword />
      <CompanyDelete />
    </div>
  );
};
export default CompanySettings;
