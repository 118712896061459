import React from "react";
//component
import Banner from "../../components/Global/Banner";
import Apply from "../../components/Events/Apply";
import Loader from "../../components/Global/Loader";
import PumaForm from "../../components/Events/EventForms/PumaForm";
import YoursFrom from "../../components/Events/EventForms/YoursForm";
import EventDescription from "../../components/Events/EventDescription";
import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import ApplyGuest from "../../components/Events/ApplyGuest";
import useAuth from "../../hooks/useAuth";
import Time4HRForm from "../../components/Events/EventForms/Time4HRForm";
import ThinkDesignForm from "../../components/Events/EventForms/ThinkDesignForm";
import ApplyOnlyButton from "../../components/Events/EventForms/ApplyOnlyButton";
import useEventFetch from "../../hooks/events/useEventFetch";
import UnapredForm from "../../components/Events/EventForms/UnapredForm";
import KSTForm from "../../components/Events/EventForms/KSTForm";
import PalicForm from "../../components/Events/EventForms/PalicForm";
import QualityFestivalForm from "../../components/Events/EventForms/QualityFestivalForm";
import Unapred2Form from "../../components/Events/EventForms/Unapred2Form";
import Kst10Form from "../../components/Events/EventForms/Kst10Form";

const EventPage = () => {
  const { user } = useAuth();
  const { slug } = useParams();
  const { data: event, isLoading, error } = useEventFetch(slug);

  const components = {
    48: Kst10Form,
    39: Unapred2Form,
    38: ApplyOnlyButton,
    37: ApplyOnlyButton,
    36: ApplyOnlyButton,
    35: ApplyOnlyButton,
    34: ApplyOnlyButton,
    33: ApplyOnlyButton,
    26: QualityFestivalForm,
    24: ApplyOnlyButton,
    23: PumaForm,
    21: PalicForm,
    18: KSTForm,
    17: ApplyOnlyButton,
    16: ApplyOnlyButton,
    15: ApplyOnlyButton,
    14: ApplyOnlyButton,
    12: UnapredForm,
    11: ThinkDesignForm,
    10: Time4HRForm,
    9: ApplyOnlyButton,
    7: YoursFrom,
    6: PumaForm,
    4: Apply,
    3: Apply,
    2: Apply,
    1: Apply,
  };
  //124
  const ApplyComponent = components[event?.data?.data?.id];

  if (isLoading) return <Loader />;
  if (error) return <h1>izvinjavamo se, doslo je do greske</h1>;
  return (
    <div className="event-page">
      <Helmet>
        <title>{event?.data?.data?.title}</title>
        <meta name="description" content={event?.data?.data?.subtitle} />
        <link rel="canonical" href={`/dogadjaji/dogadjaj/${slug}`} />
      </Helmet>
      <Banner
        h1={event?.data?.data?.title}
        h3={event?.data?.data?.subtitle}
        span={event?.data?.data?.location}
        img={`https://studenter.org/${event?.data?.data?.image}`}
      />
      <EventDescription
        eventImages={event?.data?.data?.eventImages}
        description={event?.data?.data?.description}
      />
      <div className="apply-wrapper">
        {components[event?.data?.data?.id] &&
          event?.data?.data?.submission_open === 1 &&
          user && (
            <ApplyComponent
              slug={slug}
              id={event?.data?.data?.id}
              canDownload={event?.data?.data?.can_download}
            />
          )}
        {!user && <ApplyGuest />}
      </div>
    </div>
  );
};

export default EventPage;
