import React from "react";
import PublicRoutes from "./PublicRoutes";
//context

import ProtectedRoutes from "./ProtectedRoutes";
//loader
import Loader from "../Global/Loader";
import useAuth from "../../hooks/useAuth";

const Routeing = () => {
  const { user } = useAuth();

  if (user === undefined) return <Loader />;
  if (user.role) return <ProtectedRoutes />;
  if (!user) return <PublicRoutes />;
};

export default Routeing;
