import React from "react";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router";

const Users = () => {
  return (
    <div className="users">
      <Helmet>
        <title>Korisnici</title>
        <meta
          name="description"
          content="Potreban Vam je radnik? Ovde možete pronaći potencijalnog radnika iz svih polja edukacije"
        />
        <link rel="canonical" href="/pocetna/korisnici" />
      </Helmet>
      <Outlet />
    </div>
  );
};

export default Users;
