import React, { useState } from "react";
//components

import ErrorScreen from "../../components/Global/ErrorScreen";
import FilterProjects from "../../components/Offers/Projects/FilterProjects";
import { useSearchParams } from "react-router-dom";
import NoData from "../../components/Global/NoData";
import GooglePlay from "../../components/Global/GooglePlay";
import { useInfiniteQuery } from "react-query";
import { useTranslation } from "react-i18next";
import useDebounce from "../../hooks/useDebounce";
import OfferCardHorizontal from "../../components/Offers/Global/OfferCardHorizontal";
import Rocket from "../../components/Global/Rocket";
import ProjectCardHorizontal from "../../components/Offers/Global/ProjectCardHorizontal";

const ProjectsPage = () => {
  const [searchParams] = useSearchParams();
  const [isPaid, setIsPaid] = useState(0);
  const [hasDeadline, setHasDeadline] = useState(0);

  const { t } = useTranslation();

  const fetchInfiniteProjects = async ({ pageParam = 1, queryKey }) => {
    const response = await fetch(`${
      process.env.REACT_APP_BASE_URL
    }/projects?page=${pageParam}&search=${
      searchParams.get("query") ? searchParams.get("query") : ""
    }&sort=${searchParams.get("sort") ? searchParams.get("sort") : ""}
    &salary=${searchParams.get("salary") ? searchParams.get("salary") : ""}
    &project_deadline=${
      searchParams.get("project_deadline")
        ? searchParams.get("project_deadline")
        : ""
    }
    &career_field=${
      searchParams.get("career_field") ? searchParams.get("career_field") : ""
    }`);
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    return response.json();
  };

  const debounced = useDebounce(searchParams.get("query"), 500);
  const arr = [
    searchParams.get("career_field"),
    searchParams.get("project_deadline"),
    searchParams.get("sort"),
    searchParams.get("salary"),
  ];
  const {
    data: projects,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    error,
  } = useInfiniteQuery(["projects", [debounced, arr]], fetchInfiniteProjects, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
        return lastPage?.meta?.current_page + 1;
      return false;
    },
  });

  if (error) return <ErrorScreen />;

  return (
    <div className="offers">
      <div className="width-100">
        {projects?.pages[0]?.data?.length < 1 && isLoading === false && (
          <Rocket />
        )}
        <div className="cards-horizontal">
          {projects?.pages?.map((page) =>
            page?.data?.map((project) => (
              <ProjectCardHorizontal
                showCompanyButton={true}
                showDetailsButton={true}
                key={project.id}
                {...project}
                type="projekti"
              />
            ))
          )}
          {hasNextPage && (
            <button className="offers-load-more-btn" onClick={fetchNextPage}>
              {t("load_more")}
            </button>
          )}
        </div>
      </div>
      <div>
        <FilterProjects
          setIsPaid={setIsPaid}
          isPaid={isPaid}
          hasDeadline={hasDeadline}
          setHasDeadline={setHasDeadline}
        />
      </div>
    </div>
  );
};

export default ProjectsPage;
