import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const SearchInput = ({ value, onChange, name, label }) => {
  return (
    <div className="input">
      <label className="input-label">{label}</label>
      <input
        onChange={onChange}
        value={value}
        type="text"
        className="input-field"
        autoComplete="off"
        name={name}
      />
      <FontAwesomeIcon
        className="input-search-btn"
        color="#94a3b8"
        icon={faSearch}
        size="1x"
      />
    </div>
  );
};

export default SearchInput;
