import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function ApplyGuest() {
  const { t } = useTranslation();
  return (
    <div className="applyguest">
      <span className="applyguest-title">{t("apply")}</span>
      <span className="applyguest-text">{t("events_apply_text")}</span>
      <span className="applyguest-text">
        {t("login_noAccount")}
        <Link className="applyguest-link" to="/auth/fizicko-lice/register">
          {t("register")}
        </Link>
      </span>
      <span className="applyguest-text">
        {t("already_have_acc")}
        <Link className="applyguest-link" to="/auth/fizicko-lice">
          {t("login")}
        </Link>
      </span>
    </div>
  );
}

export default ApplyGuest;
