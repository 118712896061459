import React from "react";
import { Oval } from "react-loader-spinner";

const ClassicLoader = () => {
    return (
        <div
            style={{
                height: 250,
                width: "100%",
                background: "transparent",
                borderRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Oval
                height={50}
                width={50}
                color="#53689C"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#cbd5e1"
                strokeWidth={2}
                strokeWidthSecondary={2}
            />
        </div>
    );
};

export default ClassicLoader;
