import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import ErrorScreen from "../Global/ErrorScreen";
import Message from "./Message";
import { useParams } from "react-router-dom";
import NoData from "../Global/NoData";
import useMessages from "../../hooks/forum/useMessages";
import NewMessage from "./NewMessage";
import Unauthorized from "./Unauthorized";
import useAuth from "../../hooks/useAuth";
import { fadeIn2 } from "../Functions/Animations";

const Messages = () => {
  const { dId } = useParams();
  const textAreaRef = useRef();
  const [myMessage, setMyMessage] = useState("");
  const { user } = useAuth();

  const {
    data: messages,
    isLoading,
    fetchNextPage,
    hasNextPage,
    error,
  } = useMessages(dId);

  if (isLoading) return null;
  if (error) return <ErrorScreen />;

  return (
    <div className="forum-wrapper">
      <h1 className="forum-title">Konverzacija</h1>
      <motion.div
        className="forum-content-messages"
        variants={fadeIn2}
        animate="show"
        initial="hidden"
      >
        {messages?.pages?.map((page) => page?.data.length) < 1 && <NoData />}
        {messages?.pages?.map((page) =>
          page?.data?.map((m) => (
            <Message
              message={m}
              key={m.id}
              textAreaRef={textAreaRef}
              setMyMessage={setMyMessage}
            />
          ))
        )}
        {user ? (
          <NewMessage
            textAreaRef={textAreaRef}
            setMyMessage={setMyMessage}
            myMessage={myMessage}
          />
        ) : (
          <Unauthorized />
        )}
      </motion.div>
    </div>
  );
};

export default Messages;
