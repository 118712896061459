import React, { useEffect, useRef } from "react";
import useListsFetch from "../../../hooks/useListsFetch";
import SearchInput from "../../Inputs/SearchInput";
import SelectField from "../../Inputs/SelectField";

import {
  type,
  sortOffers,
  sortOffersEng,
  typeEng,
} from "../../../constants/LocalData";
import CheckboxInput from "../../Inputs/CheckboxInput";
import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../../languages/i18n";
import useCareerFields from "../../../hooks/lists/useCareerFields";
import useCities from "../../../hooks/lists/useCities";
import FilterLoader from "../../Companies/FilterLoader";
import GooglePlay from "../../Global/GooglePlay";

const FilterJobs = ({ isPaid, setIsPaid }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({});
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      search: searchParams.get("query") || "",
      city: searchParams.get("city") || "",
      sort: searchParams.get("sort") || "",
      type: searchParams.get("type") || "",
      salary: searchParams.get("salary") || "",
      career_field: searchParams.get("career_field") || "",
    },
  });
  let searchObj = {};
  useEffect(() => {
    if (formik.values.search) {
      searchObj.query = formik.values.search;
    }
    if (formik.values.duration) {
      searchObj.duration = formik.values.duration;
    }
    if (formik.values.city) {
      searchObj.city = formik.values.city;
    }
    if (formik.values.sort) {
      searchObj.sort = formik.values.sort;
    }
    if (formik.values.type) {
      searchObj.type = formik.values.type;
    }
    if (formik.values.career_field) {
      searchObj.career_field = formik.values.career_field;
    }
    if (formik.values.salary) {
      searchObj.salary = formik.values.salary;
    }
    searchObj && setSearchParams(searchObj);
  }, [formik.values]);

  const handleReset = () => {
    setSearchParams();
    setIsPaid(false);
  };

  const { data: careerFields } = useCareerFields();
  const { data: cities } = useCities();

  return (
    <form className="filter-component">
      {/* <h2 className="filter-component-title">{t("filter_jobs_title")}</h2> */}
      <FilterLoader />
      <SearchInput
        name="search"
        label={t("filter_search")}
        onChange={formik.handleChange}
        value={
          searchParams.get("query")
            ? searchParams.get("query")
            : formik.values.search
        }
      />
      <SelectField
        label={t("filter_city")}
        name="city"
        placeholder={t("filter_city_placeholder")}
        onChange={formik.handleChange}
        value={formik.values.city}
        list={cities?.data?.data}
      />
      <SelectField
        label={t("filter_field")}
        name="career_field"
        placeholder={t("filter_field_placeholder")}
        onChange={formik.handleChange}
        value={formik.values.career_field}
        list={careerFields?.data?.data}
      />
      <SelectField
        label={t("filter_type")}
        name="type"
        placeholder={t("filter_type_placeholder")}
        onChange={formik.handleChange}
        value={formik.values.type}
        list={i18n.language === "en" ? typeEng : type}
      />

      <SelectField
        label={t("filter_sort")}
        name="sort"
        placeholder={t("filter_sort_placeholder")}
        onChange={formik.handleChange}
        value={formik.values.sort}
        list={i18n.language === "en" ? sortOffersEng : sortOffers}
      />

      <CheckboxInput
        value={isPaid}
        onChange={(e) => {
          setIsPaid(e.target.checked);
          formik.setFieldValue("salary", e.target.checked);
        }}
        title={t("filter_withSalary")}
        checked={isPaid}
        name="salary"
        checkboxText={t("yes")}
      />

      {(formik.values.search ||
        formik.values.city ||
        formik.values.career_field ||
        formik.values.type ||
        formik.values.sort ||
        formik.values.salary) && (
        <button
          type="button"
          onClick={() => handleReset()}
          className="input-reset-filter"
        >
          {t("filter_reset")}
        </button>
      )}
      <GooglePlay />
    </form>
  );
};

export default FilterJobs;
