import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import useAxiosFetch from "../../../hooks/useAxiosFetch";
import { downloadFile } from "../../Functions/DownloadFile";

const ProjectApplication = ({ app }) => {
  const {
    response: document,
    error,
    isLoading,
    axiosFetch,
    fullResponse,
  } = useAxiosFetch();

  const downLoadDoucment = async (url) => {
    let ext = url.split(/[#?]/)[0].split(".").pop().trim();
    try {
      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          Accept: "application/octet-stream",
          authorization: localStorage.getItem("token"),
        },
      });
      if (response) {
        downloadFile({ response: response.data, type: "image/*", ext });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className="application-project">
      <Link className="space-between" to={`/pocetna/korisnici/${app.user.id}`}>
        <img src={app?.user?.image} alt="IMG" className="application-img" />
        <span className="application-title">{app.user?.name}</span>
        <span className="application-text-light">{app.user?.email}</span>
        <span className="application-text-light">
          Ponuđena cena: {app?.price ? app.price : "Nepoznato"}
        </span>
        <span className="application-text-light">{app.created_at}</span>
      </Link>
      {app.note && (
        <div className="application-note-wrapper">
          <span className="application-text-light">Napomena:</span>
          <span className="application-note">{app.note}</span>
        </div>
      )}

      <div className="application-documents">
        {app.documents?.length > 0 &&
          app.documents.map((doc, index) => (
            <button
              key={index}
              onClick={() => downLoadDoucment(doc.path)}
              className="application-download-btn"
            >
              Dokument {index + 1} <FontAwesomeIcon icon={faDownload} />
            </button>
          ))}
      </div>
    </div>
  );
};

export default ProjectApplication;
