import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import useDiscussionDelete from "../../hooks/forum/useDiscussionDelete";
import useAdministratior from "../../hooks/useAdministrator";
import DeletePrompt from "./DeletePrompt";

const Discussion = ({ discussion, color }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isAdmin = useAdministratior();
  const { id } = useParams();
  const { mutate: deleteDiscussion } = useDiscussionDelete(id);

  return (
    <div className="forum-item">
      <Link to={`${discussion.id}`} className={`forum-color-circle ${color}`} />

      <div className="forum-item-content">
        <Link to={`${discussion.id}`}>
          <span className="forum-item-title">{discussion.title}</span>
          <p className="forum-item-description">{discussion.description}</p>
        </Link>
        <span className="forum-item-text">Poruke: {discussion.totalPosts}</span>
        {isAdmin && (
          <FontAwesomeIcon
            icon={faTrashAlt}
            color="black"
            className="forum-item-delete"
            onClick={() => setIsModalOpen(true)}
          />
        )}
      </div>
      <DeletePrompt
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        deleteFunction={deleteDiscussion}
        id={discussion.id}
      />
    </div>
  );
};

export default Discussion;
