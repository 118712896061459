import React from "react";
import { Link, useSearchParams } from "react-router-dom";

const CompanyJobCard = (job) => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const {
    title,
    type,
    company,
    slug,
    career_field,
    city,
    mobile_description,
    created_at,
  } = job;
  function createMarkup() {
    return { __html: mobile_description };
  }

  const filterByCareer = (id, e) => {
    e.stopPropagation();
    setSearchParams({ career_field: id });
    window.scrollTo(0, 0);
  };
  const filterByType = (value) => {
    setSearchParams({ type: value });
    window.scrollTo(0, 0);
  };

  if (job)
    return (
      <div className="offer gap-16">
        <div className="flexCol gap-16">
          <Link
            to={`/pocetna/oglasi/poslovi/${slug}`}
            className="flexRow flex-space-between gap-16"
          >
            <div className="flexCol  offer-text-wrapper gap-16">
              <div className="flexCol gap-4">
                <span className="offer-title"> {title}</span>
                <span className="offer-title-s"> {company?.name}</span>
              </div>

              {/* <div>
              <span className="offer-text-l">{created_at}</span>
            </div> */}
              <div className="flexRow gap-16 flexWrap">
                {career_field?.name && (
                  <span
                    onClick={(e) => filterByCareer(career_field?.id, e)}
                    className="offer-filter-btn-career"
                  >
                    {career_field?.name}
                  </span>
                )}
                {city?.name && (
                  <span
                    // onClick={() => filterByType(type)}
                    className="offer-filter-btn-location"
                  >
                    {city?.name}
                  </span>
                )}
                <span
                  onClick={() => filterByType(type)}
                  className="offer-filter-btn-type"
                >
                  {type}
                </span>
              </div>
            </div>
          </Link>
          <div className="flexCol gap-8">
            <span
              className="offer-text-desc"
              dangerouslySetInnerHTML={createMarkup()}
            />
          </div>
        </div>
      </div>
    );
};

export default CompanyJobCard;
