import React from "react";
import BlogArticle from "../../components/Blog/BlogArticle";
import { motion } from "framer-motion";
import { fadeIn, fadeInModern } from "../../components/Functions/Animations";
import ErrorScreen from "../../components/Global/ErrorScreen";
import useScrollPosition from "../../hooks/useScrollPosition";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery } from "react-query";
import NoData from "../../components/Global/NoData";
import OvalLoader from "../../components/Offers/Global/OvalLoader";

const BlogArticles = () => {
  const scrollPosition = useScrollPosition();
  const { t } = useTranslation();

  const fetchBlog = async ({ pageParam = 1, queryKey }) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/posts?page=${pageParam}`
    );
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    return response.json();
  };
  const {
    data: articles,
    isLoading,
    fetchNextPage,
    hasNextPage,
    error,
  } = useInfiniteQuery(["blog"], fetchBlog, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
        return lastPage?.meta?.current_page + 1;
      return false;
    },
  });
  if (isLoading) return <OvalLoader />;
  if (error) return <ErrorScreen />;
  return (
    <div className="blog-container">
      <motion.div
        variants={fadeInModern}
        animate="show"
        initial="hidden"
        className="blog-articles"
      >
        {articles?.pages?.map((articles) =>
          articles?.data?.map((article) => (
            <BlogArticle key={article.id} article={article} />
          ))
        )}
      </motion.div>
      {hasNextPage && (
        <button className="blog-load-more-btn" onClick={fetchNextPage}>
          {t("load_more")}
        </button>
      )}
    </div>
  );
};

export default BlogArticles;
