import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useAxiosPost from "../../hooks/useAxiosPost";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router";

const UserDelete = ({ id }) => {
  const [checked, setChecked] = useState(false);
  const [message, setMessage] = useState(false);
  const { t } = useTranslation();
  const { response, error, loading, axiosPost } = useAxiosPost();
  const { auth } = useAuth();
  const navigation = useNavigate();

  const deleteAccount = () => {
    if (checked) {
      setMessage("");
      axiosPost({
        url: `${process.env.REACT_APP_BASE_URL}/deactivate-account`,
      })
        .then(() => {
          !error && auth();
        })
        .then(() => {
          !error && navigation("/o-nama");
        });
    } else {
      setMessage("Morate štiklirati polje za deaktivaciju");
    }
  };

  return (
    <div className="usersettings-item">
      <span className="usersettings-item-title">{t("deactivateAcc")}</span>
      <span className="usersettings-item-subtitle">
        {t("deactivateAccText")}
      </span>
      <form className="usersettings-form">
        <label className="input-label">{t("are_you_sure")}</label>
        <input
          type="checkbox"
          onChange={() => setChecked(!checked)}
          value={checked}
          className="usersettings-delete-checkbox"
        />
        {error && <span>{t("errContact")}</span>}
        <span className="usersettings-message">{message}</span>
        <span className="usersettings-message">{response?.message}</span>
        <div className="form-submit-wrapper input-padding">
          <button
            type="button"
            onClick={() => deleteAccount()}
            className="form-submit-delete"
          >
            {t("deactivateAcc")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserDelete;
