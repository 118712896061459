import React, { useState } from "react";

//anim
import { motion } from "framer-motion";
import { authVariants } from "../../Functions/Animations";
//components
import {
  pumaTypeOfParticipants,
  yoursTypeOfParticipants,
} from "../../../constants/LocalData";
import { Link } from "react-router-dom";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
//hooks
import useAuth from "../../../hooks/useAuth";
import useAxiosPost from "../../../hooks/useAxiosPost";
import { MutatingDots } from "react-loader-spinner";
import SelectFieldNameValue from "../../Inputs/SelectFieldNameValue";
import WhiteInput from "../../Inputs/WhiteInput";
const timeTypeOfParticipants = [{ id: 0, name: "Učesnik" }];

const Time4HRForm = ({ id, slug }) => {
  const { user } = useAuth();
  const { response, error, isLoading, axiosPost } = useAxiosPost();
  const [participants, setParticipants] = useState([""]);

  const formik = useFormik({
    initialValues: {
      event_id: id,
      guests: "",
      as_a: "",
    },
    validationSchema: Yup.object({
      as_a: Yup.string().required("Obavezno polje"),
      guests: Yup.string().required("Obavezno polje"),
    }),
    onSubmit: (values) => {
      axiosPost({
        url: `${process.env.REACT_APP_BASE_URL}/submission`,
        data: values,
      });
    },
  });

  if (user)
    return (
      <>
        <div className="apply">
          <h2 className="apply-title">Prijava</h2>
          <span className="apply-text">
            Ukoliko ste zainteresovani da prisustvujete događaju kliknite na
            dugme ispod kako biste se prijavili.
          </span>

          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={authVariants}
          >
            <form onSubmit={formik.handleSubmit} className="apply-form">
              <WhiteInput
                className="input-padding"
                type="text"
                placeholder=""
                label="IME I PREZIME"
                name="guests"
                onBlur={formik.handleBlur}
                touched={formik.touched.guests}
                onChange={formik.handleChange}
                errors={formik.errors.guests}
                value={formik.values.guests}
              />
              <SelectFieldNameValue
                name="as_a"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.as_a}
                list={timeTypeOfParticipants}
                inputLabelClassName="white-input-label"
                label="UČESNIK"
                onBlur={formik.handleBlur}
                touched={formik.touched.as_a}
                errors={formik.errors.as_a}
                errorsClassName="white-input-label"
              />
              <span className="auth-message">
                {isLoading && (
                  <MutatingDots secondaryColor="#1e2a3b" color="#c9d5fd" />
                )}
                {error?.message && error.message}
              </span>
              <span className="auth-message">
                {response?.message && response.message}
              </span>
              <motion.button
                whileHover={{ scale: 1.02 }}
                type="submit"
                className="apply-submit-btn"
              >
                PRIJAVI SE
              </motion.button>
              {(parseInt(process.env.REACT_APP_SUPER_ADMIN) === user.role ||
                parseInt(process.env.REACT_APP_ADMIN) === user.role) && (
                <a
                  className="apply-download-btn"
                  target="_blank"
                  download="filename"
                  href={`${process.env.REACT_APP_BASE_URL}/event-export/${slug}`}
                >
                  PREUZMI PRISTIGLE PRIJAVE
                </a>
              )}
            </form>
          </motion.div>
        </div>
      </>
    );
  return (
    <div className="apply">
      <h3 className="apply-text">
        Samo se registrovani studenti mogu prijaviti za dogadjaj
      </h3>
      <Link to="/auth/fizicko-lice" className="apply-download-btn">
        Prijavi se
      </Link>
    </div>
  );
};

export default Time4HRForm;
