import React from "react";
import useTopics from "../../hooks/forum/useTopics";
import { colors } from "./Colors";
import ErrorScreen from "../Global/ErrorScreen";
import Topic from "./Topic";
import CreateTopic from "./CreateTopic";
import useAuth from "../../hooks/useAuth";
import Unauthorized from "./Unauthorized";
import { motion } from "framer-motion";
import { fadeIn2, moveLeft } from "../Functions/Animations";

const Topics = () => {
  const {
    data: topics,
    isLoading,
    fetchNextPage,
    hasNextPage,
    error,
  } = useTopics();
  const { user } = useAuth();
  if (isLoading) return null;
  if (error) return <ErrorScreen />;
  return (
    <div className="forum-wrapper">
      <h1 className="forum-title">Teme</h1>
      <motion.div
        className="forum-content"
        variants={fadeIn2}
        animate="show"
        initial="hidden"
      >
        {topics?.pages?.map((page) =>
          page?.data?.map((topic) => (
            <Topic
              topic={topic}
              key={topic.id}
              color={colors[Math.floor(Math.random() * colors.length)]}
            />
          ))
        )}
        {user ? <CreateTopic /> : <Unauthorized />}
      </motion.div>
    </div>
  );
};

export default Topics;
