import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Event({ event }) {
  let subtitle = event.subtitle;
  const { t } = useTranslation();
  function createMarkup() {
    return { __html: subtitle };
  }
  return (
    <div className="event">
      <Link to={`/dogadjaji/dogadjaj/${event.slug}`}>
        <img
          className="event-img"
          src={`https://studenter.org/${event?.image}`}
          alt="EVENTIMG"
        />
      </Link>
      <div className="event-text">
        <Link to={`/dogadjaji/dogadjaj/${event.slug}`}>
          <span className="event-title">{event.title}</span>
        </Link>
        <span
          className="event-desc"
          dangerouslySetInnerHTML={createMarkup()}
        ></span>
        <span className="event-date">{event.date}</span>
        <span className="event-date">{event.location}</span>
      </div>
      {event?.submission_open == "1" && (
        <Link to={`/dogadjaji/dogadjaj/${event.slug}`}>
          <button className="event-btn box-drop-shadow">
            {t("events_apply")}
          </button>
        </Link>
      )}
    </div>
  );
}
