import React from "react";
import { Link } from "react-router-dom";

const JobCard = (job) => {
  const {
    title,
    type,
    company,
    slug,
    career_field,
    meta_description,
    city,
    created_at,
  } = job;
  function createMarkup() {
    return { __html: meta_description };
  }

  if (job)
    return (
      <Link
        to={`/pocetna/oglasi/poslovi/${slug}`}
        className="keen-slider__slide job-card-slide gap-16"
      >
        <div className="flexRow flex-space-between gap-16 mobile-column">
          <div className="flexCol gap-16 job-card-slide-text-wrapper">
            <span className="job-card-slide-title"> {title}</span>
            <span className="job-card-slide-text-l"> {company?.name}</span>
            <div className="flexRow gap-16 flexWrap">
              {career_field?.name && (
                <span
                  //   onClick={(e) => filterByCareer(career_field?.id, e)}
                  className="offer-filter-btn-career"
                >
                  {career_field?.name}
                </span>
              )}
              {city?.name && (
                <span
                  // onClick={() => filterByType(type)}
                  className="offer-filter-btn-location"
                >
                  {city?.name}
                </span>
              )}
            </div>

            <span
              className="job-card-slide-text-desc"
              dangerouslySetInnerHTML={createMarkup()}
            />
          </div>

          <div className="flexCol flex-end slider-type-wrapper">
            <span
              //onClick={() => filterByType(type)}
              className="slider-offer-type hide-for-mobile"
            >
              {type}
            </span>
            <span
              //onClick={() => filterByType(type)}
              className="slider-offer-type-mobile hide-for-desktop"
            >
              {type}
            </span>
            <img
              className="job-card-slide-img"
              alt="logo"
              src={`https://studenter.org/${company?.logo}`}
            />
          </div>
        </div>
      </Link>
    );
};

export default JobCard;
