import React from "react";
import useCompanyJobsFetch from "../../../hooks/companies/useCompanyJobsFetch";
import CompanyJobCard from "./CompanyJobCard";
import { useTranslation } from "react-i18next";
const CompanyJobOffers = (props) => {
  const { data: jobs, error, isLoading } = useCompanyJobsFetch(props.slug);
  const { t } = useTranslation();
  return (
    <div className="company-jobs" style={{ flex: 1 }}>
      {jobs?.length < 1 && !isLoading && (
        <div className="offer">
          <span className="offer-title-s">{t("no_offers")}</span>
        </div>
      )}
      {jobs?.map((job) => (
        <CompanyJobCard key={job.id} {...job} />
      ))}
    </div>
  );
};

export default CompanyJobOffers;
