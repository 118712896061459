import React from "react";
import useAxiosFetch from "../../hooks/useAxiosFetch";
import axios from "../../apis/axiosInstance";

const NotificationMessage = () => {
  const {
    fullResponse: number,
    error,
    isLoading,
    axiosFetch,
  } = useAxiosFetch();

  const getNotifications = async () => {
    axiosFetch({
      axiosInstance: axios,
      method: "get",
      url: `/check-messages`,
    });
  };
  React.useEffect(() => {
    getNotifications();
  }, []);

  if (error) return null;
  if (number < 1) return null;
  if (isLoading) return <span className="notification-circle">0</span>;
  return (
    <span className="notification-circle">{number < 10 ? number : "9+"}</span>
  );
};

export default NotificationMessage;
