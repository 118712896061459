import React from "react";
import { useParams } from "react-router";

import useAuth from "../../hooks/useAuth";

const ChatMessage = ({ message }) => {
  const { id } = useParams();
  const { user } = useAuth();
  const [isSeen, setIsSeen] = React.useState(message.is_read);
  function createMarkup() {
    return { __html: message.text };
  }

  React.useEffect(() => {
    if (id == message.company_id || id == message.user_id) {
      setIsSeen(2);
    }
  }, [id]);

  if (user.role === parseInt(process.env.REACT_APP_COMPANY))
    return (
      <div
        className={
          id == message?.user?.id
            ? "chat-message-active chat-message"
            : "chat-message"
        }
      >
        <img
          className={
            id == message?.user?.id
              ? "chat-message-img chat-message-active-mobile "
              : "chat-message-img"
          }
          alt="profilepic"
          src={`https://studenter.org/${message.user?.image}`}
        />
        <div className="chat-message-text">
          <span className="chat-message-name">{message?.user?.name}</span>
          <span className="chat-message-msg hide-for-mobile">
            {message.text}
          </span>
        </div>
      </div>
    );
  return (
    <div
      className={
        id == message.company_id
          ? "chat-message-active chat-message"
          : "chat-message"
      }
    >
      {message.direction === 0 && isSeen === 0 && (
        <span className="chat-message-unread">1</span>
      )}

      <img
        className={
          id == message.company_id
            ? "chat-message-active-mobile chat-message-img"
            : "chat-message-img"
        }
        alt="profilepic"
        src={`https://studenter.org/${message.company?.logo}`}
      />
      <div className="chat-message-text">
        <span className="chat-message-name">{message.company.name}</span>
        <span
          className="chat-message-msg"
          dangerouslySetInnerHTML={createMarkup()}
        />
      </div>
    </div>
  );
};

export default ChatMessage;
