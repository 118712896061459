import React, { useState, useEffect } from "react";
//Components
import logoText from "../../assets/images/logo-text.png";
import { Link, useLocation } from "react-router-dom";
//anim
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faBars,
  faBell,
  faCommentAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import NotificationMessage from "./NotificationMessage";
import HamburgerStudent from "./HamburgerStudent";
import { SwitchLanguage } from "../../languages/components/SwitchLanguage";
import StudentList from "./ListItems/StudentList";
import StudentDropdownMenu from "./Dropdowns/StudentDropDown";
import Notifications from "./Notifications/Notifications";

function StudentNavBar({ navigationStyleBar }) {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const { user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    setIsHamburgerOpen(false);
  }, [location]);

  return (
    <motion.div>
      <div className={navigationStyleBar}>
        <div className="nav-top-menu ">
          <Link to="/dogadjaji">
            <img src={logoText} alt="STUDENTER" className="nav-logo" />
          </Link>
          <SwitchLanguage styleClass="hide-for-mobile" />
          <div
            className="nav-profile-info hide-for-mobile"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <span className="nav-profile-name">{user?.user?.name}</span>
            <img
              src={user?.user?.image}
              alt="Profile"
              className="nav-profile-img"
            />
            <FontAwesomeIcon
              icon={isDropdownOpen ? faAngleUp : faAngleDown}
              color="white"
            />
            {isDropdownOpen && <StudentDropdownMenu />}
          </div>
        </div>
        <div className="nav-bottom-menu hide-for-mobile">
          <StudentList />
          <div className="nav-notifications  hide-for-mobile">
            <Link className="position-relative" to="/pocetna/poruke">
              <NotificationMessage />
              <FontAwesomeIcon
                icon={faCommentAlt}
                color="white"
                className="nav-notification-icon"
              />
            </Link>
            <FontAwesomeIcon
              icon={faBell}
              color="white"
              className="nav-notification-icon "
              onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
            />
            {isNotificationsOpen && (
              <Notifications setIsNotificationsOpen={setIsNotificationsOpen} />
            )}
          </div>
        </div>
      </div>
      {/* <Link to="/pocetna/poruke">
        <FontAwesomeIcon
          icon={faBell}
          color="white"
          className="nav-notification-icon nav-mobile-notification-icon hide-for-desktop"
        />
      </Link> */}
      <FontAwesomeIcon
        icon={isHamburgerOpen ? faTimes : faBars}
        size="2x"
        color="white"
        className="hamburger-btn hide-for-desktop"
        onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}
      />
      {isHamburgerOpen && <HamburgerStudent />}
    </motion.div>
  );
}

export default StudentNavBar;
