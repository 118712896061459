import React from "react";
import AuthVideo from "./AuthVideo";
import Gradient from "./Gradient";
import { SwitchLanguage } from "../../languages/components/SwitchLanguage";
import Phone from "./Phone";
import { Outlet } from "react-router";
import googlePlay from "../../assets/socials/google-play-badge.png";
const Auth = () => {
  return (
    <div className="auth">
      <Gradient />
      <AuthVideo />
      <SwitchLanguage styleClass="auth-lang-switch" />
      <Phone />
      <Outlet />
    </div>
  );
};

export default Auth;
