import React from "react";

//hooks
import useAuth from "../../hooks/useAuth";
import CompanyRoutes from "./CompanyRoutes";
import StudentRoutes from "./StudentRoutes";

const ProtectedRoutes = () => {
  const { user } = useAuth();

  if (parseInt(process.env.REACT_APP_COMPANY) === user.role) {
    return <CompanyRoutes />;
  }
  if (parseInt(process.env.REACT_APP_SUPER_ADMIN) === user.role) {
    return <StudentRoutes />;
  }
  if (parseInt(process.env.REACT_APP_ADMIN) === user.role) {
    return <StudentRoutes />;
  }
  if (parseInt(process.env.REACT_APP_STUDENT) === user.role) {
    return <StudentRoutes />;
  }
  return <h1>Doslo je do greske</h1>;
};
export default ProtectedRoutes;
