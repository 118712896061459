import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

const axiosPost = async (values) => {
  return axios.post(`${process.env.REACT_APP_BASE_URL}/forum`, values, {
    headers: {
      "Content-Type": "application/json",
      authorization: localStorage.getItem("token"),
    },
  });
};

const useCreateTopic = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (values) => {
      return axiosPost(values);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`topics`]);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      },
    }
  );
};

export default useCreateTopic;
