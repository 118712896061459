export const TRANSLATIONS_MKD = {
    about_studenter:
        "StudENTER e платформа за поврзување на млади луѓе и економија. <br>Платформата од една страна на младите луѓе им нуди можност брзо и лесно да пласираат и валоризираат своите стекнати теориски и практични знаења  и да се вклучат  во реалните бизнис текови .Од друга страна , на деловните  субјекти им нуди единствена прилика да пристапат до базата на учени и перспективни млади луѓе  и да ги задоволат своите потреби за реализација  на проекти / истражување / испитување  , или едноставно да пронајдат кандидат  за нов колега.  <br> По примерот на другите европски Универзитети , кои имаат  развиена пракса на соработка со студентите и бизнисот , а на иницијатива на студентите од Универзитетот во Белград , е развиена платформата StudENTER.Иницијативата ја прифатија и другите универзитети во регионот.Техничката поддршка  во развојот на платформа ја пружи IT  компанијата Projectaland.<br>Регистрирај се , регистрацијата е бесплатна.",
    events_title: "Настани",
    events_subtitle: "Погледнете ги следните настани",
    events_apply: "Пријава",
    events_apply_text: "Мора да бидете најавени",
    nav_home: "Почетна страница",
    nav_events: "Настани",
    nav_blog: "Бизнис вести",
    nav_aboutUs: "За нас",
    nav_forum: "Форум",
    nav_companies: "Компании",
    nav_offers: "Огласи",
    nav_messages: "Пораки",
    nav_students: "Студенти",
    nav_logIn: "Пријави се",
    nav_logOut: "Одјави се",
    blog_title: "Актуелности",
    aboutus_title: "На ЕНТЕР до работа ",
    candidates: "Кандидати",
    candidate: "Кандидат",
    load_more: "Вчитај повеќе",
    show_more: "Дознај повеќе",
    filter_companies_title: "Филтрирај комапании",
    filter_students_title: "Филтрирај кандидати",
    filter_projects_title: "Филтрирај проекти",
    filter_jobs_title: "Филтрирај работни места",
    filter_search: "Пребарување",
    filter_country: "Земја",
    filter_city: "Град",
    filter_field: "Област на  работење",
    filter_reset: "Ресетирај ги филтерите",
    filter_country_placeholder: "--Одбери земја--",
    filter_city_placeholder: "--Одбери град--",
    filter_field_placeholder: "--Одбери поле--",
    filter_level_placeholder: "--Одбери ниво--",
    filter_type: "TIP",
    my_comment: "Мој Коментар",
    filter_type_placeholder: "--Одбери тип--",
    filter_sort: "Сортирај",
    filter_sort_placeholder: "--Сортирај по--",
    filter_withPrice: "Со цена",
    filter_withDeadline: "Со рок за реализијација",
    filter_withSalary: "Со плата",
    filter_scienceField: "Научна област",
    filter_scienceSubField: "Научна подобласт",
    filter_levelOfEducation: "Ниво на образование",
    filter_year_placeholder: "--Одбери година--",
    select: "--Одбери--",
    yes: "Да",
    no: "Не",
    name: "Име",
    name_offer: "Назив на огласот",
    login: "Пријави се",
    register: "Регистрирај се",
    registerCompany: "Регистрирај се како компанија",
    until: "Важи до:",
    email: 'E-адреса',
    password: "Лозинка",
    confirm_password: "Повторија ја лозинката",
    forgot_password: "Заборавена лозинка",
    create_job_offer: "Креирај оглас за работа ",
    create_project_offer: "Креирај оглас за проект",
    create_offer: "Креирај оглас",
    projects: "Проекти",
    jobs: "Работа",
    student: "Студент",
    company: "Компанија",
    error: "Дојде до грешка",
    no_offers: "Моментално нема активни огласи",
    apply: "Аплицирај",
    price: "Цена",
    deadline: "Рок",
    addFiles: "Додај датотека",
    show_details: "Прикажи ги детаљите",
    company_page: "Страница на компанијата",
    contact: "Контактирај",
    offer_jobs: "Работни места",
    offer_projects: "Проекти",
    offer_apply: "Конкурирај",
    offer_applied: "Веќе сте конкурирале",
    offer_companyDetails: "Покажи ги детаљите  за компанијата ",
    offer_apply_description:
        "Доколку би сакале да аплицирате за проект /работа ве советуваме  да го пополните вашиот StudENTER  профил  , на тој начин платформата StudENTER    ќе генерира CV   и ќе го проследи на работодавачот , доколку имате свое CV   истотака можете да го добиете на страницата Мој профил.",
    offer_create_offer: "Креирај оглас",
    offer_ads_description:
        "Доколку сакате можете бесплатно  да поставите ваков оглас , пратејќи ги следните линкови.Достапни се огласи за работа /пракса  како и огласи за еднократни проекти.",
    error_screen_text: "Се извинуваме , дојде до грешка , работиме на поправка ",
    login_noAccount: "Немаш профил?",
    login_guest: "Влези како гост",
    name_surname: "Име и презиме",
    register_agree: "Се согласувам",
    terms_and_conditions: "Политика на приватност",
    must_agree_with_tac: "Морате  да се согласите со политиката на приватност ",
    already_have_acc: "Веќе имаш профил? ",
    company_name: "Име на компанијата",
    register_send_mail_again: "Повторно испрати верификационен мејл",
    send: 'Прати',
    note: "Напомена",
    note_not_required: "Напомена ( не е задолжително)",
    my_profile: "Мој профил",
    my_offers: "Мои огласи",
    my_applications: "Мои пријави",
    about_me: "За мене",
    download: "Превземи",
    messages: "Пораки",
    no_messagesText:
        "Моментално нема пораки , разговорите може да ги започнете на профилите , со кликнување на Контакт",
    my_jobs: "Мои работи",
    my_projects: "Мои проекти",
    delete: "Избриши",
    are_you_sure: "Дали сте сигурни?",
    edit: "Промени",
    show_applications: "Покажи ги пријавите",
    basic_info: "Основни информации",
    nothing_here: "Се уште нема ништо..",
    field: "Област",
    adress: "Адреса",
    city: "Град",
    country: "Земја",
    phone: "Телефон",
    about_company: "За компанијата",
    company_info: "Податоци за фирмата",
    old_password: "Сегашна лозинкаa",
    new_password: "Нова лозинка",
    save: "Зачувај",
    date_of_birth: "Датум на раѓање",
    my_informations: "Мои информации",
    add: "Додај",
    formal_education: "Формално образование",
    faculty_name: "Име на образовната установа",
    finished: "Завршено",
    in_progress: "Во тек",
    acquired_title: "Стекнато образование",
    non_formal_education: "Неформално образование ",
    knowledge: "Знаење",
    skills: "Вештини",
    competitions: "Компентенции",
    certificate: "Сертификат",
    references: "Референци",
    organization: "Организација",
    position: "Позиција",
    date_from: "Датум од:",
    date_to: "Датум до:",
    description: "Опис",
    photo: "Фотографија",
    year_study: "Година на студирање",
    bachelors_academic_degree: "Основни академски студии",
    masters_academic_degree: "Магистерски академски студии",
    doctors_academic_degree: "Докторски академски студии",
    specialist_academic_degree: 'Специјализирани академски студии',
    bachelors_vocational_degree: "Основни струковни студии",
    specialist_vocational_degree: "Специјализирани струковни студии",
    masters_vocational_degree: "Магистерски струковни студии",
    first_year: "Прва година",
    second_year: "Втора година",
    third_year: "Трета година",
    fourth_year: "Четврта година ",
    fifth_year: "Петта година",
    sixth_year: "Шеста година",
    required_field: "Задолжително поле",
    validation_name: "Името мора да биде помалку од 100 карактери",
    validation_email: "Еmail смее да има 100 карактери",
    validation_adress: 'Максимално 70 карактери',
    validation_phone: "Телофонски број мора да биде помалку од 20 карактери",
    validation_city: "Градoт мора да има помалку од 30 карактери ",
    validation_website: "Website  мора да има помалку од 60 карактери",
    validation_password: "Лозинка мора да има најмалку 8 карактери",
    add_photo: "Додај фотографија ",
    reset_password: "Ресетирај лозинка",
    change_password: "Промени лозинка ",
    back: "Назад",
    discussions: "Теми",
    discussion_name: "Име на темата ",
    discussion_desc: "Опис на темата",
    discussion_create: "Креирај тема",
    no_discussions: "Се уште нема дискусија на оваа тема",
    no_messages: "Се уште нема пораки",
    discussion_comment_message:
        "За коментирање и отварање на дискусиите морате да бидете пријавени како кандидати",
    comment: "Коментирај",
    create_offer_freelance_msg: "Сакаш еднократен оглас за  freelance  проекти?",
    create_offer_job_msg: "Сакаш оглас за работа или пракса ?",
    click_here: "Кликни овде",
    type: "Тип",
    sallary_number: "Висина на заработката",
    duration: "ТРАЕЊЕ НА АНГАЖМАНОТ",
    offer_duration: "Валидност на огласот",
    submit: "Постави оглас",
    new_offer: "Нови оглас",
    budget: "Буџет",
    already_verified: "EMAIL ВЕЌЕ ВЕРИФИКУВАН",
    email_verified: "EMAIL VERIFIKOVAN",
    wrong_url: "НЕИСПРАВЕН УРЛ",
    unauthorized_page: "Оваа страница е достапна само за регистрирани корисници ",
    free: "Бесплатно",
    itsFree: "Бесплатно е",
    notifications: "Известување",
    noNotifications: "Нема известување",
    deactivateAcc: "Деактивирај го профилот",
    errContact:
        "Дојде до грешка ,  ве молиме контактирај те не  на  office@studenter.org",
    deactivateAccText:
        "*Доколку после  деактивацијата се пријавит во рок од 30 дена , профилот ќе ви биде обновен , псле 30 дена профилот  трајно се брише *",

    question: "Прашања",
    questionForCandidates: "Прашања на кои кандидатите  одговараат при пријавување “",
    addQuestion: "Додај прашање",
    removeQuestion: "Отстрани прашање",
    answersChance:
        "Со одговарање на прашањата од компаниите ги зголемувате шансите да бидете повикани на интервју        ",
    availableAd: "Број на останати огласи",
    pricingTitle: "Пронајдете Идеални Кандидати – Истражете го StudEnter ",
    pricingModels: 'Откријте ги нашите ценовни опции',
    contactUs: 'Контактирајте не',
    needBiggerPlan: 'Ви треба поголем план?',
    visitOurSocials: 'Посетете ги нашите социјални мрежи',
    preformal: "Предсметка"
    ,
    currency: "Валута",
    downloadOurApp: 'Преземете ја нашата апликација',
    weAreAvailableOn: 'Достапни сме на Playstore и Appstore',
    becomePartOfStudenter: 'СТАНЕТЕ ДЕЛ ОД StudENTER ЗАЕДНИЦА',
    freeReg: 'Бесплатна регистрација',
    directContactWithEmployers: 'Директен контакт со работодавците',
    becomePartDescription1: 'Направете профил како CV - аплицирајте со еден клик на оглас или бидете на врвот на пребарувањето за работодавци кога им треба кандидат со вашето образование и вештини. Ве очекуваат огласи за: пракса, работа, проекти, менторски програми.',
    becomePartDescription2: 'Добијте поволности и попусти за конференции, семинари и обуки како член на заедницата StudENTER. Придружете се на преку 3000 млади образовани кандидати од регионот. Поврзете се со колеги од други факултети и со претприемачи'
    , beOnEnterFromWork: 'Бидете на ENTER до работа',
    opportunityForCompanies: 'ПРИЛИКА ЗА КОМПАНИИ',
    accessToBase: 'ПРИСТАПЕТЕ ДО БАЗА СО ПРЕКУ 3000 МЛАДИ ОБРАЗОВАНИ ПЕРСПЕКТИВНИ КАНДИДАТИ',
    searchCandidatesBy: "Пребарувајте кандидати според:",
    byScienceField: 'Научна област',
    byLevelOfEdu: 'Ниво на образование',
    bySkills: 'Вештини',
    companyGuestInfoDesc:
        "Објавете оглас за работа / стаж / проект, најавете менторски програми. Радосно ќе најавиме и вашите конференции и семинари.",
    companyGuestInfoDesc2: ' Креирајте профил на компанијата и бидете во фокусот на младите кои градат своите кариерни патишта.'
};
