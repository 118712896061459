import {
  faArrowCircleLeft,
  faClock,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useParams } from "react-router";

import JobApply from "../../components/Offers/Jobs/JobApply";
import CompanyCardInfo from "../../components/Offers/Global/CompanyCardInfo";
import useAuth from "../../hooks/useAuth";
import CompanyApply from "../../components/Offers/Global/CompanyApply";
import GuestApply from "../../components/Offers/Global/GuestApply";
import AlreadyApplied from "../../components/Offers/Global/AlreadyApllied";
import ErrorScreen from "../../components/Global/ErrorScreen";
import { Helmet } from "react-helmet-async";
import useJobFetch from "../../hooks/offers/useJobFetch";
import OfferLoader from "../../components/Offers/Global/OfferLoader";
import { useTranslation } from "react-i18next";

const JobPage = () => {
  const params = useParams();
  const { user } = useAuth();
  const { t } = useTranslation();
  const { data: job, isLoading, error } = useJobFetch(params.slug);

  function createMarkup() {
    return { __html: job?.data?.data?.description };
  }

  if (isLoading) return <OfferLoader />;
  if (job)
    return (
      <div className="offer-page">
        <Helmet>
          <title>{job?.data?.data?.title}</title>
          <meta name="description" content="Oglas za posao" />
          <link rel="canonical" href="/pocetna/oglasi/poslovi" />
        </Helmet>

        <div className="offer-page-details">
          <div className="flexRow space-between">
            <h1 className="offer-page-title">{job?.data?.data?.title}</h1>
            <img
              alt="logo"
              className="offer-page-logo"
              src={`https://studenter.org/${job?.data?.data?.company?.logo}`}
            />
          </div>

          <div className="offer-page-types">
            {job?.data?.data?.type && (
              <span className="offer-filter-btn-type">
                {job?.data?.data?.type}
              </span>
            )}
            {job?.data?.data?.expiry_date && (
              <span className="offer-filter-btn-date">
                {t("until")} {job?.data?.data.expiry_date}
              </span>
            )}
            {job?.data?.data?.salary && (
              <span className="offer-filter-btn-5">
                {job?.data?.data?.salary}
              </span>
            )}

            {job?.data?.data?.career_field?.name && (
              <span className="offer-filter-btn-career">
                {job?.data?.data?.career_field?.name}
              </span>
            )}
          </div>

          {job?.data?.data?.city?.name && (
            <span className="offer-filter-btn-location">
              <FontAwesomeIcon icon={faLocationArrow} />
              &nbsp;{job?.data?.data?.city?.name}
            </span>
          )}
          <article
            className="offer-page-description"
            dangerouslySetInnerHTML={createMarkup()}
          />
        </div>

        <div className="offer-page-right">
          {job?.data?.data?.canApply === false &&
            user.role === parseInt(process.env.REACT_APP_STUDENT) && (
              <AlreadyApplied />
            )}
          {job?.data?.data?.canApply === true &&
            user.role === parseInt(process.env.REACT_APP_STUDENT) && (
              <JobApply
                questions={job?.data?.data?.questions}
                slug={job?.data?.data?.slug}
              />
            )}
          {user.role === parseInt(process.env.REACT_APP_COMPANY) && (
            <CompanyApply />
          )}
          {user.role === parseInt(process.env.REACT_APP_ADMIN) && (
            <GuestApply />
          )}
          {user.role === parseInt(process.env.REACT_APP_SUPER_ADMIN) && (
            <GuestApply />
          )}

          {!user && <GuestApply />}
          <CompanyCardInfo
            slug={job?.data?.data?.slug}
            company={job?.data?.data}
          />
        </div>
      </div>
    );
  if (error) return <ErrorScreen />;
};

export default JobPage;
