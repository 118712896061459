import React from "react";
//components

//hooks
import { useSearchParams } from "react-router-dom";
import ErrorScreen from "../../components/Global/ErrorScreen";
import FilterUser from "../../components/Users/FilterUser";
import useDebounce from "../../hooks/useDebounce";
import { useInfiniteQuery } from "react-query";
import { useTranslation } from "react-i18next";
import useScrollPosition from "../../hooks/useScrollPosition";
import GooglePlay from "../../components/Global/GooglePlay";
import NoData from "../../components/Global/NoData";
import UserCard from "../../components/Users/UserCard";

const UsersPage = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const scrollPosition = useScrollPosition();
  const { t } = useTranslation();

  const getUsers = async ({ pageParam = 1, queryKey }) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/users?page=${pageParam}&search=${
        searchParams.get("query") ? searchParams.get("query") : ""
      }&city=${
        searchParams.get("city") ? searchParams.get("city") : ""
      }&country=${
        searchParams.get("country") ? searchParams.get("country") : ""
      }&academic_field=${
        searchParams.get("academic_field")
          ? searchParams.get("academic_field")
          : ""
      }&academic_subfield=${
        searchParams.get("academic_subfield")
          ? searchParams.get("academic_subfield")
          : ""
      }&level=${searchParams.get("level") ? searchParams.get("level") : ""}`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }
    );
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    return response.json();
  };
  const debounced = useDebounce(searchParams.get("query"), 500);
  const arr = [
    searchParams.get("academic_field"),
    searchParams.get("academic_subfield"),
    searchParams.get("level"),
    searchParams.get("city"),
    searchParams.get("country"),
  ];
  const {
    data: users,
    isLoading,
    fetchNextPage,
    hasNextPage,
    error,
  } = useInfiniteQuery(["users", [debounced, arr]], getUsers, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
        return lastPage?.meta?.current_page + 1;
      return false;
    },
  });

  if (error) return <ErrorScreen />;
  return (
    <div className="users">
      <div className="users-all">
        {users?.pages[0]?.data?.length < 1 && isLoading === false && <NoData />}

        <div className="flexRow flexWrap gap-16">
          {/* {!user && <CompanyGuestInfo />} */}
          {users?.pages?.map((page) =>
            page?.data?.map((user) => <UserCard key={user.id} user={user} />)
          )}
          <div className="width-100 flex-center">
            {hasNextPage && (
              <button className="users-load-more-btn" onClick={fetchNextPage}>
                {t("load_more")}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="users-filtering">
        <FilterUser />
      </div>
    </div>
  );
};

export default UsersPage;
