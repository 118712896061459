import { useState, useEffect } from "react";
import axios from "axios";

const useAxiosPost = () => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [controller, setController] = useState();

  const axiosPost = async (configObj) => {
    const { url, data } = configObj;
    try {
      setIsLoading(true);
      setError("");
      const ctrl = new AbortController();
      setController(ctrl);
      const res = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
        signal: ctrl.signal,
      });
      setResponse(res.data);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => controller && controller.abort();
  }, [controller]);

  return {
    response,
    error,
    isLoading,
    axiosPost,
  };
};

export default useAxiosPost;
