import React, { useEffect } from "react";
import useAxiosFetch from "../../../hooks/useAxiosFetch";
import useAuth from "../../../hooks/useAuth";
import axios from "../../../apis/axiosInstance";
import MyOffer from "./MyOffer";
import TransparentLoader from "../../Global/TransparentLoader";
import OvalLoader from "../../Offers/Global/OvalLoader";
import ErrorScreen from "../../Global/ErrorScreen";

const MyProjects = () => {
  const { user } = useAuth();
  const { response: jobs, error, isLoading, axiosFetch } = useAxiosFetch();

  const getCompanyJobs = async () => {
    await axiosFetch({
      axiosInstance: axios,
      method: "get",
      url: `/company-projects/${user?.user?.slug}`,
    });
  };

  useEffect(() => {
    getCompanyJobs();
  }, []);
  if (isLoading) return <OvalLoader />;
  if (error) return <ErrorScreen />;
  return (
    <div className="my-offers">
      {jobs.map((job) => (
        <MyOffer key={job.id} job={job} />
      ))}
    </div>
  );
};

export default MyProjects;
