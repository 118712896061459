import React from "react";
//components
import FilterCompany from "../../components/Companies/FilterCompany";
import { useSearchParams } from "react-router-dom";
import ErrorScreen from "../../components/Global/ErrorScreen";
import useAuth from "../../hooks/useAuth";
import GuestPermission from "../../components/Global/GuestPermission";
import useScrollPosition from "../../hooks/useScrollPosition";
import { useTranslation } from "react-i18next";
import useDebounce from "../../hooks/useDebounce";
import GooglePlay from "../../components/Global/GooglePlay";
import NoData from "../../components/Global/NoData";
import { useInfiniteQuery } from "react-query";
import CompanyCard from "../../components/Companies/CompanyCard";
import CompanyPermission from "../../components/Global/CompanyPermission";
import CompanyGuestInfo from "../../components/Companies/CompanyGuestInfo";

const CompaniesHome = () => {
  const [searchParams] = useSearchParams({});
  const { user } = useAuth();
  const scrollPosition = useScrollPosition();
  const { t } = useTranslation();

  const getCompanies = async ({ pageParam = 1, queryKey }) => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/companies?page=${pageParam}&search=${
        searchParams.get("query") ? searchParams.get("query") : ""
      }&city=${
        searchParams.get("city") ? searchParams.get("city") : ""
      }&country=${
        searchParams.get("country") ? searchParams.get("country") : ""
      }&career_field=${
        searchParams.get("career_field") ? searchParams.get("career_field") : ""
      }`
    );
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    return response.json();
  };
  const debounced = useDebounce(searchParams.get("query"), 500);
  const arr = [
    searchParams.get("career_field"),
    searchParams.get("city"),
    searchParams.get("country"),
  ];
  const {
    data: companies,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    error,
  } = useInfiniteQuery(["companies", [debounced, arr]], getCompanies, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
        return lastPage?.meta?.current_page + 1;
      return false;
    },
  });

  if (error) return <ErrorScreen />;
  // if (!user) return <CompanyPermission />;
  return (
    <div className="companies">
      <div className="companies-all">
        {companies?.pages[0]?.data?.length < 1 && isLoading === false && (
          <NoData />
        )}

        <div className="cards-horizontal">
          {!user && <CompanyGuestInfo />}
          {companies?.pages?.map((page) =>
            page?.data?.map((company) => (
              <CompanyCard key={company.slug} {...company} />
            ))
          )}
          {hasNextPage && (
            <button className="offers-load-more-btn" onClick={fetchNextPage}>
              {t("load_more")}
            </button>
          )}
        </div>
      </div>

      <div className="companies-filtering">
        <FilterCompany />
      </div>
    </div>
  );
};

export default CompaniesHome;
