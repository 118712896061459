import React from "react";
import { MutatingDots } from "react-loader-spinner";

const TransparentLoader = () => {
  return (
    <div className="transparent-loader">
      <MutatingDots color="#a7467b" secondaryColor="#3a37c7" />
    </div>
  );
};

export default TransparentLoader;
