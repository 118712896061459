import { useState, useEffect } from "react";

const useAxiosFetch = () => {
  const [fullResponse, setFullResponse] = useState([]);
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [controller, setController] = useState();

  const axiosFetch = async (configObj) => {
    const { axiosInstance, method, url } = configObj;
    try {
      setisLoading(true);
      let ctrl = new AbortController();
      setController(ctrl);
      const res = await axiosInstance[method.toLowerCase()](url, {
        signal: ctrl?.signal,
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      });
      setResponse(res.data.data);
      setFullResponse(res.data);
      setisLoading(false);
    } catch (err) {
      if (err.message !== "canceled") {
        setError(err.message);
        setisLoading(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      controller && controller.abort();
    };
  }, [controller]);

  return { response, error, isLoading, axiosFetch, fullResponse };
};

export default useAxiosFetch;
