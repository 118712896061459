import React, { useState, useEffect } from "react";
//components
import InputField from "../../Inputs/InputField";
import { ProjectOfferValidation } from "../../../constants/validations/ProjectOfferValidation";
import axios from "../../../apis/axiosInstance";
import TextEditor from "../../Global/TextEditor";
import ErrorScreen from "../../Global/ErrorScreen";
import TextAreaInput from "../../Inputs/TextAreaInput";
import SelectField from "../../Inputs/SelectField";
//hooks
import useListsFetch from "../../../hooks/useListsFetch";
import useAxiosPost from "../../../hooks/useAxiosPost";
import { useFormik } from "formik";
import { MutatingDots } from "react-loader-spinner";
import { Link, useParams } from "react-router-dom";
import useAxiosFetch from "../../../hooks/useAxiosFetch";
import { EditJobOfferValidation } from "../../../constants/validations/EditJobOfferValidation";

const EditProjectOffer = () => {
  const [editorText, setEditorText] = useState("");
  const { response, error, isLoading, axiosPost } = useAxiosPost();
  const { fields } = useListsFetch(["career-fields"]);
  const { slug } = useParams();
  const {
    response: project,
    error: projectError,
    isLoading: projectIsLoading,
    axiosFetch,
  } = useAxiosFetch();

  const getProject = async () => {
    await axiosFetch({
      axiosInstance: axios,
      method: "get",
      url: `/projects/${slug}`,
    });
  };

  useEffect(() => {
    getProject();
  }, []);

  const postProjectOffer = async (values) => {
    values.description = editorText;
    axiosPost({
      axiosInstance: axios,
      url: `${process.env.REACT_APP_BASE_URL}/projects/update/${slug}`,
      data: values,
    });
  };

  const formik = useFormik({
    enableReinitialize: project ? true : false,
    initialValues: {
      title: project.title || "", //nazib
      description: editorText, //opis
      salary: project.salary || "", //iznos plate
      note: project.note || "", //napomena
      project_deadline: project.project_deadline || "",
      career_field_id: project?.career_field?.id || "",
    },
    onSubmit: (values) => {
      postProjectOffer(values);
    },
  });
  if (projectError) return <ErrorScreen />;
  return (
    <div className="create-offer">
      <div className="create-offer-title-wrapper">
        <h1 className="create-offer-title">
          {formik.values.title ? formik.values.title : "Novi oglas"}
        </h1>
      </div>
      <form className="create-offer-form" onSubmit={formik.handleSubmit}>
        <div className="inline mobile-column">
          <InputField
            type="text"
            placeholder=""
            label="NAZIV PROJEKTA"
            name="title"
            onBlur={formik.handleBlur}
            touched={formik.touched.title}
            errors={formik.errors.title}
            onChange={formik.handleChange}
            value={formik.values.title}
          />
          <SelectField
            type="select"
            placeholder=""
            label="VRSTA PROJEKTA"
            name="career_field_id"
            list={fields}
            onBlur={formik.handleBlur}
            touched={formik.touched.career_field_id}
            errors={formik.errors.career_field_id}
            onChange={formik.handleChange}
            value={formik.values.career_field_id}
          />
        </div>

        <div className="inline mobile-column">
          <InputField
            type="text"
            placeholder=""
            label="ROK ZA IZRADU"
            name="project_deadline"
            onBlur={formik.handleBlur}
            touched={formik.touched.project_deadline}
            errors={formik.errors.project_deadline}
            onChange={formik.handleChange}
            value={formik.values.project_deadline}
          />
          <InputField
            type="text"
            placeholder=""
            label="BUDŽET"
            name="salary"
            onBlur={formik.handleBlur}
            touched={formik.touched.salary}
            errors={formik.errors.salary}
            onChange={formik.handleChange}
            value={formik.values.salary}
          />
        </div>

        <div>
          <label className="input-label">OPIS</label>
          <TextEditor
            value={project.description ? project?.description : ""}
            setEditorText={setEditorText}
          />
        </div>

        <TextAreaInput
          type="text"
          placeholder=""
          label="NAPOMENA"
          name="note"
          onBlur={formik.handleBlur}
          touched={formik.touched.note}
          errors={formik.errors.note}
          onChange={formik.handleChange}
          value={formik.values.note}
        />
        {isLoading && <MutatingDots color="#c9d5fd" secondaryColor="#1b587b" />}
        {error && (
          <span className="changes-message">
            {error?.response?.data?.message}
          </span>
        )}
        {response && (
          <span className="changes-message">{response?.message}</span>
        )}
        <button
          type="button"
          onClick={() => formik.handleSubmit()}
          className="form-submit"
        >
          IZMENI OGLAS
        </button>
      </form>
    </div>
  );
};

export default EditProjectOffer;
