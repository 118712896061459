import React from "react";
import resume from "../../../assets/images/resume.svg";

const EmptyApplications = () => {
  return (
    <div className="empty-applications">
      <img src={resume} alt="resume" className="empty-app-img" />
      <h3 className="empty-app-title">Trenutno nema pristiglih prijava</h3>
    </div>
  );
};

export default EmptyApplications;
