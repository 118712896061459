import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

const deleteTopic = async (id) => {
  return axios.delete(`${process.env.REACT_APP_BASE_URL}/forum/${id}`, {
    headers: {
      "Content-Type": "application/json",
      authorization: localStorage.getItem("token"),
    },
  });
};

const useTopicDelete = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (values) => {
      return deleteTopic(values);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["topics"]);
      },
    }
  );
};

export default useTopicDelete;
