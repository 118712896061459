import { useTranslation } from "react-i18next";
import "../../../languages/i18n";
import LinkItem from "../../Events/LinkItem";

import React from "react";

const GuestList = () => {
  const { t } = useTranslation();

  return (
    <ul className="nav-list  hide-for-mobile">
      <li className="nav-list-item">
        <LinkItem title={t("nav_home")} link="/pocetna-stranica" />
      </li>
      <li className="nav-list-item">
        <LinkItem title={t("nav_events")} link="/dogadjaji" />
      </li>

      <li className="nav-list-item">
        <LinkItem title={t("nav_blog")} link="/blog" />
      </li>
      <li>
        <LinkItem title={t("nav_aboutUs")} link="/o-nama" />
      </li>
      <li>
        <LinkItem title={t("nav_forum")} link="/pocetna/forum/teme" />
      </li>

      <li>
        <LinkItem title={t("nav_companies")} link="/pocetna/firme" />
      </li>
      <li>
        <LinkItem title={t("candidates")} link="/pocetna/korisnici" />
      </li>
      <li>
        <LinkItem title={t("nav_offers")} link="/pocetna/oglasi" />
      </li>
    </ul>
  );
};

export default GuestList;
