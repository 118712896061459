export const colors = [
  "marron",
  "red3",
  "pink1",
  "pink2",
  "pink3",
  "pink4",
  "orange1",
  "orange2",
  "orange3",
  "green1",
  "green2",
  "violet",
];
