import React from "react";

//sliderimg
import ImageSlider from "../Global/ImageSlider";

//motion
import { motion } from "framer-motion";
import { moveUp } from "../Functions/Animations";

function EventDescription({ description, eventImages }) {
  function createMarkup() {
    return { __html: description };
  }
  return (
    <motion.section
      variants={moveUp}
      initial="hidden"
      animate="show"
      className="eventdescription"
    >
      <aside className="eventdescription-text-wrapper">
        <p
          className="eventdescription-text"
          dangerouslySetInnerHTML={createMarkup()}
        ></p>
      </aside>
      {eventImages?.length > 1 && <ImageSlider slides={eventImages} />}
    </motion.section>
  );
}

export default EventDescription;
