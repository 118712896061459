import React, { useState } from "react";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
//components
import { motion } from "framer-motion";
import { moveLeft } from "../Functions/Animations";
import logo from "../../assets/images/logo.png";
import WhiteInput from "../Inputs/WhiteInput";
import axios from "axios";
import { MutatingDots } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useSearchParams, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isReseted, setIsReseted] = useState(false);
  //params from api
  const [searchParams, setSearchParams] = useSearchParams({});
  let token = searchParams.get("token" || "");
  let email = searchParams.get("email" || "");

  const { type } = useParams();
  const { t } = useTranslation();
  const handleReset = async (values) => {
    setIsLoading(true);
    setIsReseted(true);
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/${
        type === "fizicko-lice" ? "new-password" : "company-new-password"
      }`,
      {
        email: email,
        token: token,
        password: values.password,
        password_confirmation: values.password_confirmation,
      }
    );

    setMessage(res.data?.message);
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      password_confirmation: "",
      token: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t("required_field")),
      password_confirmation: Yup.string().required(t("required_field")),
    }),
    onSubmit: (values) => {
      handleReset(values);
    },
  });

  return (
    <motion.div
      variants={moveLeft}
      animate="show"
      initial="hidden"
      className="auth-sign"
    >
      <img className="auth-sign-logo" src={logo} alt="logo" />
      <WhiteInput
        label={t("new_password")}
        type="password"
        placeholder=""
        name="password"
        onBlur={formik.handleBlur}
        touched={formik.touched.password}
        onChange={formik.handleChange}
        errors={formik.errors.password}
        value={formik.values.password}
        isPasswordVisible={true}
      />
      <WhiteInput
        label={t("confirm_password")}
        type="password"
        placeholder=""
        name="password_confirmation"
        onBlur={formik.handleBlur}
        touched={formik.touched.password_confirmation}
        onChange={formik.handleChange}
        errors={formik.errors.password_confirmation}
        value={formik.values.password_confirmation}
        isPasswordVisible={true}
      />
      {isLoading && <MutatingDots color="#fff" secondaryColor="#1b587b" />}
      <span className="auth-message"> {message}</span>
      <button
        onClick={() => formik.handleSubmit()}
        type="submit"
        className="auth-sign-btn"
      >
        {t("change_password")}
      </button>

      {isReseted && (
        <Link to={`/auth/${type}`}>
          <FontAwesomeIcon icon={faArrowCircleLeft} color="#fff" />
        </Link>
      )}
    </motion.div>
  );
};

export default ResetPassword;
