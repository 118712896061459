import { useEffect, useState } from "react";
import useAuth from "./useAuth";

const useAdministratior = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (user?.role === parseInt(process.env.REACT_APP_SUPER_ADMIN)) {
      setIsAdmin(true);
    }
  }, [user]);
  return isAdmin;
};
export default useAdministratior;
