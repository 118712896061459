import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const WhiteInput = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <div className="white-input">
        <label className="white-input-label">{props.label}</label>
        <input
          className="white-input-field"
          autoComplete="off"
          name={props.name}
          onChange={props.onChange}
          value={props.value}
          type={isVisible ? "text" : props.type}
          onBlur={props.onBlur}
          placeholder={props.placeholder}
        />
        {props.isPasswordVisible && (
          <FontAwesomeIcon
            icon={isVisible ? faEyeSlash : faEye}
            className="input-password-icon"
            color="#94a3b8"
            onClick={() => setIsVisible(!isVisible)}
          />
        )}
      </div>
      {props.touched && props.errors ? (
        <p className="auth-input-errors">{props.errors}</p>
      ) : (
        ""
      )}
    </>
  );
};

export default WhiteInput;
