import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

const deleteDiscussion = async (id) => {
  return axios.delete(
    `${process.env.REACT_APP_BASE_URL}/forum-discussions/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    }
  );
};

const useDiscussionDelete = (id) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (values) => {
      return deleteDiscussion(values);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`discussions/${id}`]);
      },
    }
  );
};

export default useDiscussionDelete;
