import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import useTopicDelete from "../../hooks/forum/useTopicDelete";
import useAdministratior from "../../hooks/useAdministrator";
import DeletePrompt from "./DeletePrompt";

const Topic = ({ topic, color }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isAdmin = useAdministratior();
  const { mutate: deleteTopic } = useTopicDelete();
  return (
    <div className="forum-item">
      <Link
        className={`forum-color-circle ${color}`}
        to={`${topic.id}/diskusije`}
      />
      <div className="forum-item-content">
        <Link to={`${topic.id}/diskusije`}>
          <span className="forum-item-title">{topic.title}</span>
          <p className="forum-item-description">{topic.description}</p>
        </Link>

        {isAdmin && (
          <FontAwesomeIcon
            icon={faTrashAlt}
            color="black"
            className="forum-item-delete"
            onClick={() => setIsModalOpen(true)}
          />
        )}
      </div>
      <span className="forum-item-text">
        Diskusije: {topic.forumDiscussions?.length}
      </span>
      <DeletePrompt
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        deleteFunction={deleteTopic}
        id={topic.id}
      />
    </div>
  );
};

export default Topic;
