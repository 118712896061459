import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";

const Offer = (job) => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const {
    title,
    type,
    company,
    slug,
    career_field,
    city,
    mobile_description,
    expiry_date,
  } = job;
  function createMarkup() {
    return { __html: mobile_description };
  }

  const filterByCareer = (id, e) => {
    e.stopPropagation();
    setSearchParams({ career_field: id });
    window.scrollTo(0, 0);
  };
  const filterByType = (value) => {
    setSearchParams({ type: value });
    window.scrollTo(0, 0);
  };
  const { t } = useTranslation();
  if (job)
    return (
      <div className="offer gap-16">
        <div className="flexCol gap-16">
          <Link
            to={`/pocetna/oglasi/poslovi/${slug}`}
            className="flexRow flex-space-between gap-16"
          >
            <img
              className="offer-img hide-for-desktop"
              alt="logo"
              src={`https://studenter.org/${company?.logo}`}
            />
            <div className="flexCol  offer-text-wrapper gap-16">
              <div className="flexCol gap-4">
                <span className="offer-title"> {title}</span>
                <span className="offer-title-s"> {company?.name}</span>
              </div>

              {/* <div>
              <span className="offer-text-l">{created_at}</span>
            </div> */}
              <div className="flexRow gap-16 flexWrap">
                {career_field?.name && (
                  <span
                    onClick={(e) => filterByCareer(career_field?.id, e)}
                    className="offer-filter-btn-career"
                  >
                    {career_field?.name}
                  </span>
                )}
                {city?.name && (
                  <span
                    // onClick={() => filterByType(type)}
                    className="offer-filter-btn-location"
                  >
                    {city?.name}
                  </span>
                )}

                {type && (
                  <span
                    onClick={() => filterByType(type)}
                    className="offer-filter-btn-type"
                  >
                    {type}
                  </span>
                )}
                {expiry_date && (
                  <span
                    onClick={() => filterByType(type)}
                    className="offer-filter-btn-date"
                  >
                    {t("until")} {expiry_date}
                  </span>
                )}
              </div>
            </div>
          </Link>
          <div className="flexCol gap-8">
            <span
              className="offer-text-desc"
              dangerouslySetInnerHTML={createMarkup()}
            />
          </div>
        </div>
        <Link to={`/pocetna/oglasi/poslovi/${slug}`}>
          <img
            className="offer-img hide-for-mobile"
            alt="logo"
            src={`https://studenter.org/${company?.logo}`}
          />
        </Link>
      </div>
    );
};

export default Offer;
