import React, { useState, useEffect } from "react";
//icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faTrashAlt,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
//formik
import { FieldArray, Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import useListsFetch from "../../hooks/useListsFetch";

import { useTranslation } from "react-i18next";

const UserEducation = ({ educations, isLoading }) => {
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const lists = "academic-fields academic-subfields";
  const { academicFields, academicSubFields } = useListsFetch(lists);

  const schema = Yup.object({
    new_education: Yup.array().of(
      Yup.object().shape({
        academic_field_id: Yup.string()
          .required(t("required_field"))
          .nullable(),
        academic_subfield_id: Yup.string()
          .required(t("required_field"))
          .nullable(),
        level: Yup.string().required(t("required_field")),
        graduated: Yup.string().required(t("required_field")),
      })
    ),
  });

  const level = [
    t("bachelors_academic_degree"),
    t("masters_academic_degree"),
    t("doctors_academic_degree"),
    t("specialist_academic_degree"),
    t("bachelors_vocational_degree"),
    t("specialist_vocational_degree"),
    t("masters_vocational_degree"),
  ];
  const year_of_edu = [
    { id: 1, name: t("first_year") },
    { id: 2, name: t("second_year") },
    { id: 3, name: t("third_year") },
    { id: 4, name: t("fourth_year") },
    { id: 5, name: t("fifth_year") },
    { id: 6, name: t("sixth_year") },
  ];

  //EDUCATION VALUES
  const initialValues = {
    new_education:
      educations?.length > 0
        ? educations
        : [
          {
            school: "", //steceno zvanje
            title: "", //naziv ustavove
            graduated: "1", //status
            academic_field_id: "", //oblast
            academic_subfield_id: "", //oblast
            year: "", //godina studija
            level: "", //nivo obrazovanja
          },
        ],
  };

  const educationChangeHandler = async (value) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/update-educations`,
        value,
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      setMessage(data.data.message);
    } catch (error) {
      setMessage("Doslo je do greske");
    }
  };
  if (isLoading) return null;
  return (
    <div className="usersettings-item">
      <span className="usersettings-item-title">{t("formal_education")}</span>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(value) => {
          educationChangeHandler(value);
        }}
      >
        {({ values }) => (
          <Form className="usersettings-form">
            <FieldArray name="new_education">
              {({ insert, push, remove }) => (
                <div>
                  {values.new_education.map((element, index) => (
                    <div className="form-element" key={index}>
                      {values.new_education.length > 1 && (
                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className="input-trash-btn"
                        >
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            color="red"
                          ></FontAwesomeIcon>
                        </button>
                      )}

                      <div className="inline-inputs-desktop">
                        <div className="input input-padding">
                          <label
                            className="input-label"
                            htmlFor={`new_education.${index}.academic_field_id`}
                          >
                            {t("field")}
                          </label>
                          <div className="input-select-wrapper">
                            <Field
                              as="select"
                              name={`new_education.${index}.academic_field_id`}
                              type="text"
                              className="input-field input-field-select-formik"
                            >
                              <option className="option-disabled" value="">
                                {t("filter_field_placeholder")}
                              </option>
                              {academicFields?.map((field) => (
                                <option key={field.id} value={field.id}>
                                  {field.name}
                                </option>
                              ))}
                            </Field>
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className="input-select-icon"
                            />
                          </div>
                          <ErrorMessage
                            name={`new_education.${index}.academic_field_id`}
                            className="field-error"
                          >
                            {(msg) => (
                              <span className="input-errors">{msg}</span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="input input-padding">
                          <label
                            className="input-label"
                            htmlFor={`new_education.${index}.academic_subfield_id`}
                          >
                            {t("filter_scienceField")}
                          </label>
                          <div className="input-select-wrapper">
                            <Field
                              as="select"
                              name={`new_education.${index}.academic_subfield_id`}
                              type="text"
                              className="input-field input-field-select-formik"
                            >
                              <option className="option-disabled" value="">
                                {t("filter_field_placeholder")}
                              </option>

                              {academicSubFields
                                ?.filter(
                                  (field) =>
                                    field.academic_field_id ==
                                    values.new_education[index]
                                      .academic_field_id
                                )
                                .map((field) => (
                                  <option key={field.id} value={field.id}>
                                    {field.name}
                                  </option>
                                ))}
                            </Field>
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className="input-select-icon"
                            />
                          </div>
                          <ErrorMessage
                            name={`new_education.${index}.academic_subfield_id`}
                            className="field-error"
                          >
                            {(msg) => (
                              <span className="input-errors">{msg}</span>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="inline-inputs-desktop">
                        <div className="input input-padding">
                          <label
                            className="input-label"
                            htmlFor={`new_education.${index}.school`}
                          >
                            {t("faculty_name")}
                          </label>
                          <Field
                            name={`new_education.${index}.school`}
                            type="text"
                            className="input-field"
                            value={values.new_education[index].school || ""}
                          ></Field>
                          <ErrorMessage
                            name={`new_education.${index}.school`}
                            className="field-error"
                          >
                            {(msg) => (
                              <span className="input-errors">{msg}</span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="input input-padding">
                          <label className="input-label">STATUS</label>
                          <div className="input-radio-formik">
                            <label className="radio-label-formik">
                              <Field
                                type="radio"
                                name={`new_education.${index}.graduated`}
                                value="1"
                                className="radio-field-formik"
                              />
                              {t("finished")}
                            </label>
                            <label className="radio-label-formik">
                              <Field
                                type="radio"
                                name={`new_education.${index}.graduated`}
                                value="0"
                                className="radio-field-formik"
                              />
                              {t("in_progress")}
                            </label>
                            <ErrorMessage
                              name={`new_education.${index}.graduated`}
                              className="field-error"
                            >
                              {(msg) => (
                                <span className="input-errors">{msg}</span>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>
                      <div className="inline-inputs-desktop">
                        <div className="input input-padding">
                          <label
                            className="input-label"
                            htmlFor={`new_education.${index}.level`}
                          >
                            {t("filter_levelOfEducation")}
                          </label>
                          <div className="input-select-wrapper">
                            <Field
                              as="select"
                              name={`new_education.${index}.level`}
                              type="text"
                              className="input-field input-field-select-formik"
                            >
                              <option
                                className="option-disabled"
                                value="DEFAULT"
                              >
                                {t("filter_level_placeholder")}
                              </option>
                              {level.map((edu, index) => (
                                <option key={index} value={index + 1}>
                                  {edu}
                                </option>
                              ))}
                            </Field>
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className="input-select-icon"
                            />
                          </div>
                          <ErrorMessage
                            name={`new_education.${index}.level`}
                            className="field-error"
                          >
                            {(msg) => (
                              <span className="input-errors">{msg}</span>
                            )}
                          </ErrorMessage>
                        </div>

                        {element.graduated === "0" ? (
                          <div className="input input-padding">
                            <label
                              className="input-label"
                              htmlFor={`new_education.${index}.year`}
                            >
                              {t("year_study")}
                            </label>
                            <Field
                              name={`new_education.${index}.year`}
                              as="select"
                              type="text"
                              className="input-field"
                              value={values.new_education[index].year || ""}
                            >
                              <option
                                className="option-disabled"
                                value="DEFAULT"
                              >
                                {t("filter_year_placeholder")}
                              </option>
                              {year_of_edu?.map((year) => (
                                <option key={year.id} value={year.id}>
                                  {year.name}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name={`new_education.${index}.year`}
                              className="field-error"
                            >
                              {(msg) => (
                                <span className="input-errors">{msg}</span>
                              )}
                            </ErrorMessage>
                          </div>
                        ) : (
                          <div className="input input-padding">
                            <label
                              className="input-label"
                              htmlFor={`new_education.${index}.title`}
                            >
                              {t("acquired_title")}
                            </label>
                            <Field
                              name={`new_education.${index}.title`}
                              type="text"
                              className="input-field"
                              value={values.new_education[index].title || ""}
                            ></Field>
                            <ErrorMessage
                              name={`new_education.${index}.title`}
                              className="field-error"
                            >
                              {(msg) => (
                                <span className="input-errors">{msg}</span>
                              )}
                            </ErrorMessage>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="form-add-more">
                    <button
                      type="button"
                      onClick={() =>
                        values.new_education.length < 5 &&
                        push({
                          school: "", //steceno zvanje
                          title: "", //naziv ustavove
                          graduated: "0", //status
                          academic_field_id: "", //oblast
                          academic_subfield_id: "", //oblast
                          year: "", //godina studija
                          level: "", //nivo obrazovanja
                        })
                      }
                    >
                      {values.new_education.length < 5 && (
                        <FontAwesomeIcon
                          className="form-add-more-icon"
                          icon={faPlusCircle}
                          color="#64748b"
                        />
                      )}
                    </button>
                  </div>
                  <span className="changes-message input-padding">
                    {message}
                  </span>
                  <div className="form-submit-wrapper input-padding">
                    <button type="submit" className="form-submit">
                      {t("save")}
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default UserEducation;
