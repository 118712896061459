import React from "react";
import { Link } from "react-router-dom";
import { setScrollPostition } from "../../functions/setScrollPosition";
import { fadeInModern, popup } from "../Functions/Animations";
import { motion } from "framer-motion";
const BlogArticle = ({ article }) => {
  const createHtml = () => {
    return { __html: article?.text };
  };
  return (
    <Link
      to={`${article.slug}`}
      onClick={() => setScrollPostition()}
      className="blog-article-wrapper"
    >
      <motion.div
        variants={fadeInModern}
        initial="hidden"
        animate="show"
        className="blog-article"
      >
        <div className="blog-article-text-wrapper">
          <h2 className="blog-article-title">{article.title}</h2>
          <p
            className="blog-article-text"
            dangerouslySetInnerHTML={createHtml()}
          />
        </div>

        <div className="blog-article-img-wrapper">
          <img
            src={`https://www.studenter.org/${article?.image}`}
            alt="IMG"
            className="blog-article-img"
          />
        </div>
      </motion.div>
    </Link>
  );
};

export default BlogArticle;
