import React from "react";

const NotificationsCount = ({
  notificationCount,
  notificationError,
  countLoading,
}) => {
  if (notificationError) return null;
  if (notificationCount < 1) return null;
  if (countLoading) return null;

  return (
    <span className="notification-circle">
      {notificationCount < 10 ? notificationCount : "9+"}
    </span>
  );
};

export default NotificationsCount;
