import React, { useEffect } from "react";
import NavigationLinkStyled from "../../Global/NavigationLinkStyled";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const MyOffers = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    if (location.pathname === "/pocetna/moji-oglasi") {
      navigation("poslovi");
    }
  }, []);
  return (
    <div className="my-offers-wrapper">
      <nav className="offers-nav">
        <NavigationLinkStyled
          title={t("my_jobs")}
          link="/pocetna/moji-oglasi/poslovi"
        />
        <NavigationLinkStyled
          title={t("my_projects")}
          link="/pocetna/moji-oglasi/projekti"
        />
      </nav>
      <Outlet />
    </div>
  );
};

export default MyOffers;
