export const authVariants = {
  hidden: {
    x: "-100vw",
    opacity: 0,
  },
  visible: {
    x: "0",
    opacity: 1,
    transition: { type: "tween", stiffness: 40, duration: 1 },
  },
  exit: {
    x: "100vw",
    transition: { ease: "easeInOut" },
  },
};

export const sliderContainer = {
  hidden: { opacity: 1 },
  show: { opacity: 1, transition: { staggerChildren: 0.1, ease: "easeOut" } },
};

export const slider = {
  hidden: { x: "-130%", skew: "45deg" },
  show: {
    x: "100%",
    skew: "0deg",
    transition: { ease: "easeOut", duration: 1 },
  },
};
export const fadeIn = {
  hidden: { opacity: 0.8 },
  show: {
    opacity: 1,
    transition: { duration: 1 },
  },
  exit: { opacity: 0, transition: { duration: 1 } },
};
export const fadeInModern = {
  hidden: { opacity: 0.2 },
  show: {
    opacity: 1,
    transition: { duration: 0.25 },
  },
  exit: { opacity: 0, transition: { duration: 1 } },
};
export const fadeIn2 = {
  hidden: { opacity: 0.3 },
  show: {
    opacity: 1,
    transition: { duration: 0.3 },
  },
  exit: { opacity: 0, transition: { duration: 1 } },
};
export const moveUp = {
  hidden: { opacity: 0.2, y: 150 },
  show: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  exit: { opacity: 0, y: 150, transition: { duration: 0.5 } },
};
export const movePrompt = {
  hidden: { opacity: 0.2, y: 50 },
  show: { opacity: 1, y: 0, transition: { duration: 0.1 } },
};
export const moveLeft = {
  hidden: { opacity: 0.5, x: "20vh" },
  show: { opacity: 1, x: 0, transition: { duration: 0.7 } },
  exit: { opacity: 0.5, x: "-20vh", transition: { duration: 0.3 } },
};
export const moveRight = {
  hidden: { opacity: 0.5, x: "-20vh" },
  show: { opacity: 1, x: 0, transition: { duration: 0.7 } },
};

export const moveHamburger = {
  hidden: { opacity: 0.1, x: "50vh" },
  show: { opacity: 1, x: 0, transition: { duration: 0.8, delayChildren: 0.4 } },
  exit: { opacity: 0, x: "100vh", transition: { duration: 0.5 } },
};
export const popup = {
  hidden: {
    opacity: 0,
    scale: 0.5,
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.85,
    },
  },
};
