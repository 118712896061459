import axios from "axios";
import { useQuery } from "react-query";

const fetchFunction = async (slug) => {
  const res = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/job-applications/${slug}`,
    {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    }
  );
  return res?.data?.data;
};

const useJobApplications = (slug) => {
  return useQuery([`applications/${slug}`], () => fetchFunction(slug));
};

export default useJobApplications;
