import { faPaperclip, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useParams } from "react-router";
import useNewMessage from "../../hooks/forum/useNewMessage";
import useAuth from "../../hooks/useAuth";

const NewMessage = ({ setMyMessage, textAreaRef, myMessage }) => {
  const { dId } = useParams();
  const { user } = useAuth();
  const { isLoading, isError, mutate, data, error, isSuccess } = useNewMessage({
    dId,
    setMyMessage,
  });
  return (
    <div className="forum-newmessage" ref={textAreaRef}>
      <textarea
        className=" forum-newmessage-text"
        placeholder="Vaš komentar ovde"
        value={myMessage}
        onChange={(e) => setMyMessage(e.target.value)}
      />
      <div className=" forum-newmessage-btn-wrapper">
        <button
          className=" forum-newmessage-btn"
          onClick={() =>
            mutate({
              message: myMessage,
              user_id: user?.id,
              forum_discussion_id: dId,
            })
          }
        >
          <FontAwesomeIcon icon={faPaperPlane} color="white" size="2x" />
        </button>
      </div>
    </div>
  );
};

export default NewMessage;
