import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { MutatingDots } from "react-loader-spinner";
import { useEffect } from "react";
import { useOutletContext } from "react-router";
import useAxiosPost from "../../hooks/useAxiosPost";
import { useTranslation } from "react-i18next";

const ConversationAddMessage = ({ id, getConversation }) => {
  const [message, setMessage] = useState();
  const { response, error, isLoading, axiosPost } = useAxiosPost();

  const getLatestMessages = useOutletContext();

  const sendMessageHandler = () => {
    if (message) {
      axiosPost({
        url: `${process.env.REACT_APP_BASE_URL}/messages/create`,
        data: {
          id: id,
          text: message,
        },
      }).then(() => {
        getConversation();
        getLatestMessages();
      });
    }
  };
  const { t } = useTranslation()
  if (error) return <span>Molimo pokusajte kasnije</span>;
  if (isLoading) return <MutatingDots />;
  return (
    <div className="coversation-add-wrapper box-drop-shadow">
      <div className="conversation-add">
        <textarea
          className="conversation-add-textarea"
          onChange={(e) => setMessage(e.target.value)}
        />

        <button
          className="conversation-add-btn"
          type="button"
          onClick={() => sendMessageHandler()}
        >
          {t('send')}
        </button>
      </div>
    </div>
  );
};

export default ConversationAddMessage;
