import React, { useState } from "react";

//anim
import { motion } from "framer-motion";
import { authVariants } from "../../Functions/Animations";
//components
import { pumaTypeOfParticipants } from "../../../constants/LocalData";
import { Link } from "react-router-dom";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
//hooks
import useAuth from "../../../hooks/useAuth";
import useAxiosPost from "../../../hooks/useAxiosPost";
import { MutatingDots } from "react-loader-spinner";
import SelectFieldNameValue from "../../Inputs/SelectFieldNameValue";
import InputField from "../../Inputs/InputField";

const type = [
  { id: 1, name: "Da" },
  { id: 2, name: "Ne" },
];
const numbers = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
  { id: 6, name: 6 },
  { id: 7, name: 7 },
  { id: 8, name: 8 },
  { id: 9, name: 9 },
  { id: 10, name: 10 },
];

const Unapred2Form = ({ id, slug }) => {
  const { user } = useAuth();
  const { response, error, isLoading, axiosPost } = useAxiosPost();

  const formik = useFormik({
    initialValues: {
      event_id: id,
      phone: "",
      guests: "Ne",
      year: "Ne",
      transcript_number: 1,
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      axiosPost({
        url: `${process.env.REACT_APP_BASE_URL}/submission`,
        data: values,
      });
    },
  });

  if (user)
    return (
      <>
        <div className="apply">
          <h2 className="apply-title">Prijava</h2>
          <span className="apply-text">
            Ukoliko ste zainteresovani da prisustvujete događaju popunite formu
            za prijavu
          </span>

          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={authVariants}
          >
            <form onSubmit={formik.handleSubmit} className="apply-form">
              {parseInt(process.env.REACT_APP_COMPANY) === user.role ? (
                <>
                  <SelectFieldNameValue
                    name="year"
                    placeholder=""
                    onChange={formik.handleChange}
                    value={formik.values.year}
                    list={type}
                    inputLabelClassName="white-input-label"
                    label="ŠTAND"
                    onBlur={formik.handleBlur}
                    touched={formik.touched.year}
                    errors={formik.errors.year}
                    errorsClassName="white-input-label"
                    inputClassName="select-field-namevalue-width"
                  />
                  <SelectFieldNameValue
                    name="guests"
                    onChange={formik.handleChange}
                    value={formik.values.guests}
                    list={type}
                    inputLabelClassName="white-input-label"
                    label="SPONZORSTVO"
                    onBlur={formik.handleBlur}
                    touched={formik.touched.guests}
                    errors={formik.errors.guests}
                    errorsClassName="white-input-label"
                  />
                  <SelectFieldNameValue
                    name="transcript_number"
                    onChange={formik.handleChange}
                    value={formik.values.transcript_number}
                    list={numbers}
                    inputLabelClassName="white-input-label"
                    label="BROJ UČESNIKA"
                    onBlur={formik.handleBlur}
                    touched={formik.touched.transcript_number}
                    errors={formik.errors.transcript_number}
                    errorsClassName="white-input-label"
                  />
                </>
              ) : (
                <InputField
                  labelClassName="white-input-label"
                  type="text"
                  placeholder=""
                  label="TELEFON (opciono)"
                  name="phone"
                  onBlur={formik.handleBlur}
                  touched={formik.touched.phone}
                  onChange={formik.handleChange}
                  errors={formik.errors.phone}
                  value={formik.values.phone}
                />
              )}

              <span className="auth-message">
                {isLoading && (
                  <MutatingDots secondaryColor="#1e2a3b" color="#c9d5fd" />
                )}
                {error?.message && error.message}
              </span>
              <span className="auth-message">
                {response?.message && response.message}
              </span>
              <motion.button
                whileHover={{ scale: 1.02 }}
                type="submit"
                className="apply-submit-btn"
              >
                PRIJAVI SE
              </motion.button>
              {(parseInt(process.env.REACT_APP_SUPER_ADMIN) === user.role ||
                parseInt(process.env.REACT_APP_ADMIN) === user.role) && (
                <a
                  className="apply-download-btn"
                  target="_blank"
                  download="filename"
                  href={`${process.env.REACT_APP_BASE_URL}/event-export/${slug}`}
                >
                  PREUZMI PRISTIGLE PRIJAVE
                </a>
              )}
            </form>
          </motion.div>
        </div>
      </>
    );
  return (
    <div className="apply">
      <h3 className="apply-text">
        Samo se registrovani korisnici mogu prijaviti za dogadjaj
      </h3>
      <Link to="/auth/fizicko-lice" className="apply-download-btn">
        Prijavi se
      </Link>
    </div>
  );
};

export default Unapred2Form;
