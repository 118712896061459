import React from "react";
//components
import { Outlet } from "react-router";
import { Helmet } from "react-helmet-async";

const Forum = () => {
  return (
    <div className="forum-wrapper">
      <Helmet>
        <title> Forum</title>
        <meta
          name="description"
          content="Forum je dostupan svim korisnicima koji žele da saznaju više o univerziteskim dešavanjima, svako je dobrodošao da otvori novu temu."
        />
        <link rel="canonical" href="/pocetna/forum" />
      </Helmet>
      <Outlet />
    </div>
  );
};
export default Forum;
