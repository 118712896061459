import * as yup from "yup";
export const OfferValidation = yup.object({
  title: yup
    .string()
    .required("***")
    .min(6, "Naziv mora imati najmanje 6 karaktera"),
  type: yup.string().required("***"),
  deadline: yup.string().required("***"),
  duration: yup.string().required("***"),
  career_field_id: yup.string().required("***"),
});
