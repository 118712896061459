
import React from "react";

const ClassicInput = ({ value, onChange, name, label }) => {
    return (
        <div className="input">
            <label className="input-label">{label}</label>
            <input
                onChange={onChange}
                value={value}
                type="text"
                className="input-field"
                autoComplete="off"
                name={name}
            />

        </div>
    );
};

export default ClassicInput;
