import React, { useState, useEffect } from "react";
import InputField from "../Inputs/InputField";
import ImagePreviewModal from "../Global/ImagePreviewModal";
//formik
import { useFormik, Formik, Form } from "formik";

import * as Yup from "yup";
import TextAreaInput from "../Inputs/TextAreaInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import SelectField from "../Inputs/SelectField";
//hooks
import useAxiosFormData from "../../hooks/useAxiosFormData";
import useAuth from "../../hooks/useAuth";
import useListsFetch from "../../hooks/useListsFetch";
//axios
import axios from "../../apis/axiosInstance";
import { MutatingDots } from "react-loader-spinner";
//editor
import TextEditor from "../Global/TextEditor";
import { useTranslation } from "react-i18next";

const CompanyInfo = ({}) => {
  const [message, setMessage] = useState("");
  const [logo, setLogo] = useState();
  const [isImageOpened, setIsImageOpened] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [editorText, setEditorText] = useState("");

  const modalImageHandler = (img) => {
    setIsImageOpened(true);
    setModalImage(img);
  };
  const lists = "cities countries career-fields";

  const { user } = useAuth();
  const { t } = useTranslation();
  const { countries, cities, fields } = useListsFetch(lists);
  const [response, error, loading, axiosFetch] = useAxiosFormData();

  const editCompanyInfo = async (values) => {
    const formData = new FormData();
    logo && formData.append("logo", logo);
    formData.append("email", values.email);
    formData.append("name", values.name);
    formData.append("phone", values.phone);
    formData.append("address", values.address);
    formData.append(
      "description",
      editorText
        ? editorText
        : user?.user?.description
        ? user.user.description
        : "..."
    );
    formData.append("website", values.website);
    formData.append("pib", values.pib);
    formData.append("career_field", values.careerField);
    formData.append("city", values.city);
    formData.append("country", values.country);
    axiosFetch({
      axiosInstance: axios,
      method: "post",
      url: `/company-profile-update`,
      formData,
    });
  };

  useEffect(() => {
    setMessage(response);
  }, [response]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user.user.name || "",
      email: user.user.email || "",
      phone: user.user.phone || "",
      address: user.user.address || "",
      website: user.user.website || "",
      pib: user.user.pib || "",
      careerField: user.user.careerField?.id || "",
      city: user.user.city?.id || "",
      country: user.user.country?.id || "",
      logo: user.user.logo || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(100, t("validation_name"))
        .required(t("required_field")),
      email: Yup.string()
        .email()
        .max(100, t("validation_email"))
        .required(t("required_field")),
      careerField: Yup.string().max(50).required(t("required_field")),
      address: Yup.string().max(170),
      phone: Yup.string().max(20, t("validation_phone")),
      city: Yup.string().max(30, t("validation_city")),
      website: Yup.string().max(60, t("validation_website")),
    }),
    onSubmit: (values) => {
      editCompanyInfo(values);
    },
  });

  return (
    <div className="usersettings-item">
      <span className="usersettings-item-title">{t("basic_info")}</span>
      <Formik initialValues={formik.initialValues}>
        <Form encType="multipart/form-data" className="usersettings-form">
          <div className="usersettings-profile-pic-wrapper">
            <img
              className="usersettings-profile-pic"
              alt="avatar"
              src={logo ? URL.createObjectURL(logo) : user.user.logo}
              onClick={() =>
                modalImageHandler(
                  logo ? URL.createObjectURL(logo) : user.user.logo
                )
              }
            />
            {isImageOpened !== false && (
              <ImagePreviewModal
                setIsImageOpened={setIsImageOpened}
                isImageOpened={isImageOpened}
                modalImage={modalImage}
              />
            )}
            <div className="user-settings-image-menu">
              <label htmlFor="files">
                <FontAwesomeIcon className="pointer" icon={faEdit} />
              </label>
              <input
                type="file"
                id="files"
                accept="image/png, image/jpeg"
                className="display-none"
                onChange={(event) => {
                  setLogo(event.target.files[0]);
                }}
              />
            </div>
          </div>
          <div className="inline-inputs-desktop">
            <InputField
              className="input-padding"
              type="text"
              placeholder=""
              name="name"
              onBlur={formik.handleBlur}
              touched={formik.touched.name}
              onChange={formik.handleChange}
              errors={formik.errors.name}
              value={formik.values.name}
              label={`${t("company_name")} *`}
            />
            <SelectField
              type="select"
              placeholder=""
              label={`${t("field")} *`}
              name="careerField"
              className="input-padding"
              list={fields}
              onBlur={formik.handleBlur}
              touched={formik.touched.careerField}
              errors={formik.errors.careerField}
              onChange={formik.handleChange}
              value={formik.values.careerField}
            />
          </div>
          <div className="inline-inputs-desktop">
            <InputField
              className="input-padding"
              type="text"
              placeholder=""
              label={`${t("adress")} *`}
              name="address"
              onBlur={formik.handleBlur}
              touched={formik.touched.address}
              errors={formik.errors.address}
              onChange={formik.handleChange}
              value={formik.values.address}
            />
            <SelectField
              label={`${t("country")} *`}
              name="country"
              onBlur={formik.handleBlur}
              touched={formik.touched.country}
              errors={formik.errors.country}
              onChange={formik.handleChange}
              value={formik.values.country}
              list={countries}
              className="input-padding"
            />
            <SelectField
              label={`${t("city")} *`}
              name="city"
              onBlur={formik.handleBlur}
              touched={formik.touched.city}
              errors={formik.errors.city}
              onChange={formik.handleChange}
              value={formik.values.city}
              list={cities?.filter(
                (filteredCity) =>
                  filteredCity.country_id == formik.values.country
              )}
              className="input-padding"
            />
          </div>
          <InputField
            className="input-padding"
            type="email"
            placeholder=""
            label="EMAIL *"
            name="email"
            onBlur={formik.handleBlur}
            touched={formik.touched.email}
            errors={formik.errors.email}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          <div className="inline-inputs-desktop">
            <InputField
              className="input-padding"
              type="text"
              placeholder=""
              label="PIB"
              name="pib"
              onBlur={formik.handleBlur}
              touched={formik.touched.pib}
              errors={formik.errors.pib}
              onChange={formik.handleChange}
              value={formik.values.pib}
            />
            <InputField
              className="input-padding"
              type="phone"
              placeholder=""
              label="WEBSITE "
              name="website"
              onBlur={formik.handleBlur}
              touched={formik.touched.website}
              errors={formik.errors.website}
              onChange={formik.handleChange}
              value={formik.values.website}
            />
            <InputField
              className="input-padding"
              type="phone"
              placeholder=""
              label={t("phone")}
              name="phone"
              onBlur={formik.handleBlur}
              touched={formik.touched.phone}
              errors={formik.errors.phone}
              onChange={formik.handleChange}
              value={formik.values.phone}
            />
          </div>

          <div className="input-padding">
            <span className="input-label">{`${t("about_company")} *`}</span>
            <TextEditor
              value={user?.user?.description}
              setEditorText={setEditorText}
            />
          </div>

          {loading && <MutatingDots color="#1b587b" secondaryColor="#c9d5fd" />}
          <div className="usersettings-messages">
            {error && (
              <span className="usersettings-message ">
                {error?.response?.data?.message}
              </span>
            )}
            <span className="usersettings-message ">{message}</span>
          </div>
          <div className="form-submit-wrapper input-padding">
            <button
              type="button"
              onClick={() => formik.handleSubmit()}
              className="form-submit"
            >
              {t("save")}
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
export default CompanyInfo;
