// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import React from "react";
// import {
//   faArrowRight,
//   faBook,
//   faLocationArrow,
// } from "@fortawesome/free-solid-svg-icons";
// import { motion } from "framer-motion";
// import { fadeInModern, popup } from "../Functions/Animations";
// import { Link } from "react-router-dom";
// import { setScrollPostition } from "../../functions/setScrollPosition";
// const CompanyCard = ({ user }) => {
//   return (
//     <motion.div
//       animate="show"
//       initial="hidden"
//       variants={fadeInModern}
//       className="user-card"
//     >
//       <Link to={`${user?.id}`} onClick={() => setScrollPostition()}>
//         <img className="user-card-img" alt="user.img" src={user?.image} />
//       </Link>

//       <Link to={`${user?.id}`} onClick={() => setScrollPostition()}>
//         <span className="user-card-title">{user?.name} </span>
//       </Link>
//       {user?.city?.name && (
//         <span className="user-card-smallText">
//           {user?.city?.name} <FontAwesomeIcon icon={faLocationArrow} />
//         </span>
//       )}

//       {user?.educations?.academic_subfield?.name && (
//         <span className="user-card-smallText">
//           {user?.educations?.academic_subfield?.name}
//         </span>
//       )}

//       <span className="user-card-smallText">{user?.educations?.school}</span>
//       {user?.educations?.level && (
//         <span className="user-card-smallText">
//           {user?.educations?.level} <FontAwesomeIcon icon={faBook} />
//         </span>
//       )}

//       <Link
//         to={`${user?.id}`}
//         className="user-card-link-btn"
//         onClick={() => setScrollPostition()}
//       >
//         <span className="user-card-link-text">Prikaži više</span>
//       </Link>
//     </motion.div>
//   );
// };

// export default CompanyCard;
import React from "react";
import { motion } from "framer-motion";
import { fadeInModern } from "../Functions/Animations";
import { Link } from "react-router-dom";
import { setScrollPostition } from "../../functions/setScrollPosition";

const UserCard = ({ user }) => {
  return (
    <motion.div
      animate="show"
      initial="hidden"
      variants={fadeInModern}
      className="user-card"
    >
      <Link to={`${user?.id}`} onClick={() => setScrollPostition()}>
        <img className="user-card-img" alt="user.img" src={user?.image} />
      </Link>
      <Link
        to={`${user?.id}`}
        onClick={() => setScrollPostition()}
        className="flexCol"
      >
        <span className="user-card-title">{user?.name} </span>
        {user?.city?.name && (
          <span className="user-card-smallText">{user?.city?.name}</span>
        )}

        {user?.educations?.academic_subfield?.name && (
          <span className="user-card-smallText">
            {user?.educations?.academic_subfield?.name}
          </span>
        )}

        <span className="user-card-smallText">{user?.educations?.school}</span>
        {/* {user?.educations?.level && (
          <span className="user-card-smallText">{user?.educations?.level}</span>
        )} */}
      </Link>
    </motion.div>
  );
};

export default UserCard;
