import {
  Box,
  Button,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import AppStore from "../../assets/socials/appstore.png";
import GPlay from "../../assets/socials/google-play-badge.png";
import { useTranslation } from "react-i18next";

const DownloadApp = () => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        background: "#f2f2f2",
        borderRadius: 5,
        padding: 25,
        paddingBottom: 15,
        display: "flex",
        flexDirection: "column",
        gap: 24,
        position: "relative",
        overflow: "hidden",
        maxHeight: "fit-content",
        flex: 1,
        // maxWidth: "fit-content",
      }}
    >
      <Typography style={{ fontWeight: 600, fontSize: 24, zIndex: 2 }}>
        {t('downloadOurApp')}
      </Typography>
      <Typography style={{ fontWeight: 300, fontSize: 14, zIndex: 2 }}>
        {t('weAreAvailableOn')}
      </Typography>
      {/* <img
        src={Line}
        style={{
          position: "absolute",
          left: -20,
          top: -10,
          zIndex: 0,
          height: "125%",
        }}
      /> */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: ["column", "column", "column", "row"],
          gap: 2,
          zIndex: 2,
        }}
      >
        <a
          href="https://apps.apple.com/hr/app/studenter/id1639547864"
          target="_blank"
          rel="noreferrer"
          style={{ display: "flex" }}
        >
          <img
            style={{
              maxWidth: 220,
              aspectRatio: 1,
              objectFit: "contain",
              zIndex: 2,
            }}
            src={AppStore}
          />
        </a>

        <a
          href="https://play.google.com/store/apps/details?id=com.studenter.app&hl=en&gl=US"
          target="_blank"
          rel="noreferrer"
        >
          <img
            style={{
              maxWidth: 220,
              aspectRatio: 1,
              objectFit: "contain",
            }}
            className="google-mobile"
            src={GPlay}
          />
        </a>
      </Box>
    </div>
  );
};

export default DownloadApp;
