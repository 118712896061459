import React from "react";
import Modal from "react-modal";
import googlePlay from "../../assets/socials/google-play-badge.png";
import x from "../../assets/icons/x.png";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    background: "#edf1f3",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    maxWidth: "335px",
    padding: "123px 20px",
    alignItems: "center",
  },
  overlay: { zIndex: 1000 },
};

function AdModal(props) {
  function closeModal() {
    props.setAdIsOpen(false);
  }

  return (
    <Modal
      isOpen={props.adIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="AD"
      ariaHideApp={false}
    >
      <button
        className="modal-exit-btn"
        onClick={() => props.setAdIsOpen(false)}
      >
        <img className="modal-exit-icon" alt="X" src={x} />
      </button>
      <h1 className="adModal-title" onClick={closeModal}>
        Aplikacija je sada dostupna na Google Play platformi
      </h1>
      <a
        href="https://play.google.com/store/apps/details?id=com.studenter.app&hl=en&gl=US"
        target="_blank"
        className="google-play-icon-link"
        rel="noreferrer"
      >
        <img src={googlePlay} alt="Googleplay" className="google-play-icon" />
      </a>
    </Modal>
  );
}

export default AdModal;
