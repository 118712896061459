import React, { useEffect } from "react";
import useAxiosFetch from "../../hooks/useAxiosFetch";
import axios from "../../apis/axiosInstance";
import ErrorScreen from "../Global/ErrorScreen";
import TransparentLoader from "../Global/TransparentLoader";
import OfferCardHorizontal from "../Offers/Global/OfferCardHorizontal";
import NoData from "../Global/NoData";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import OvalLoader from "../Offers/Global/OvalLoader";
import useMyJobApplications from "../../hooks/candidates/useMyJobApplications";

const MyApplications = () => {
  const {
    data: jobApplications,
    isLoading: jobAppisLoading,
    isError: jobAppErr,
  } = useMyJobApplications();
  const { t } = useTranslation();
  if (jobAppisLoading) return <OvalLoader />;
  if (jobAppErr) return <ErrorScreen />;
  return (
    <div className="my-applications">
      <Helmet>
        <title>{t("my_applications")}</title>
        <meta name="description" content={t("my_applications")} />
      </Helmet>
      <h1 className="my-applications-title">{t("my_applications")}</h1>
      <h2 className="my-applications-subtitle">{t("jobs")}</h2>
      {jobApplications?.length < 1 && <NoData />}
      {jobApplications?.map((app, index) => (
        <OfferCardHorizontal key={app.job.id} {...app.job} />
      ))}
      {/* <h2 className="my-applications-subtitle">{t("projects")}</h2>
      {projects?.length < 1 && <NoData />}
      {projects?.map((app, index) => (
        <OfferCardHorizontal
          showCompanyButton={true}
          studentLink={`/pocetna/oglasi/projekti/${app?.project?.slug}`}
          key={index}
          offer={app?.project}
          type="projekti"
        />
      ))} */}
    </div>
  );
};

export default MyApplications;
