import axios from "axios";
import { useQuery } from "react-query";

const fetchFunction = (slug) => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}/projects/${slug}`, {
    headers: {
      "Content-Type": "application/json",
      authorization: localStorage.getItem("token"),
    },
  });
};

const useProjectFetch = (slug) => {
  return useQuery([`projects/${slug}`], () => fetchFunction(slug));
};

export default useProjectFetch;
