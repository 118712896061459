import React from "react";
//components
import StudentNavBar from "./StudentNavBar";
import GuestNavBar from "./GuestNavBar";
import CompanyNavBar from "./CompanyNavBar";
//hooks
import useAuth from "../../hooks/useAuth";
//ff
function NavigationBar({ navigationStyleBar }) {
  const { user } = useAuth();
  if (parseInt(process.env.REACT_APP_STUDENT) === user.role) {
    return <StudentNavBar navigationStyleBar={navigationStyleBar} />;
  }
  if (parseInt(process.env.REACT_APP_ADMIN) === user.role) {
    return <StudentNavBar navigationStyleBar={navigationStyleBar} />;
  }
  if (parseInt(process.env.REACT_APP_SUPER_ADMIN) === user.role) {
    return <StudentNavBar navigationStyleBar={navigationStyleBar} />;
  }
  if (parseInt(process.env.REACT_APP_COMPANY) === user.role) {
    return <CompanyNavBar navigationStyleBar={navigationStyleBar} />;
  }
  if (!user) return <GuestNavBar navigationStyleBar={navigationStyleBar} />;
}

export default NavigationBar;
