import React from "react";
import { useParams } from "react-router";
import EmptyApplications from "./EmptyApplications";
import JobApplication from "./JobApplication";
import ErrorScreen from "../../Global/ErrorScreen";
import OvalLoader from "../../Offers/Global/OvalLoader";
import useJobApplications from "../../../hooks/offers/useJobApplications";
const JobApplications = () => {
  const { slug } = useParams();

  const { data: applications, isLoading, error } = useJobApplications(slug);

  if (isLoading) return <OvalLoader />;
  if (applications?.length < 1) return <EmptyApplications />;
  if (error) return <ErrorScreen />;
  return (
    <div className="applications">
      {applications?.map((app) => (
        <JobApplication key={app.id} app={app} />
      ))}
    </div>
  );
};

export default JobApplications;
