import { useState } from "react";

const useAxiosFetch = () => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const axiosFetch = async (configObj) => {
    const { axiosInstance, method, url, formData } = configObj;

    try {
      setIsLoading(true);
      const res = await axiosInstance[method.toLowerCase()](url, formData, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      setResponse(res.data.message);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return [response, error, isLoading, axiosFetch];
};

export default useAxiosFetch;
