import axios from "axios";
import { useQuery } from "react-query";

const fetchFunction = async (slug) => {
  const data = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/job-preview`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return data?.data?.data;
};

const useJobPreview = (slug) => {
  return useQuery([`job-preview/${slug}`], () => fetchFunction(slug));
};

export default useJobPreview;
