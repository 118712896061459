import React from "react";
import { useTranslation } from "react-i18next";
import error from "../../assets/images/error.svg";
const ErrorScreen = () => {
  const { t } = useTranslation();
  return (
    <div className="error-screen">
      <img src={error} alt="error" className="error-screen-img" />
      <h3 className="error-screen-title">{t("error_screen_text")}</h3>
    </div>
  );
};

export default ErrorScreen;
