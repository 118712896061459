import { useInfiniteQuery } from "react-query";

const fetchTopics = async ({ pageParam = 1, queryKey }) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/forum?page=${pageParam}`
  );
  if (!response.ok) {
    throw new Error("Something went wrong!");
  }
  return response.json();
};

const useTopics = () => {
  return useInfiniteQuery(["topics"], fetchTopics, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
        return lastPage?.meta?.current_page + 1;
      return false;
    },
  });
};
export default useTopics;

//   const {
//     data: articles,
//     isLoading,
//     fetchNextPage,
//     hasNextPage,
//     error,
//   } =
