import React from "react";
import LinkItem from "../Events/LinkItem";
//motion
import { fadeIn, moveHamburger } from "../Functions/Animations";
import { motion } from "framer-motion";
//hooks
import { SwitchLanguage } from "../../languages/components/SwitchLanguage";
import { useTranslation } from "react-i18next";

const HamburgerMenu = () => {
  const { t } = useTranslation();
  return (
    <motion.div
      animate="show"
      initial="hidden"
      exit="exit"
      variants={moveHamburger}
      className="hamburger"
    >

      <ul className="hamburger-list">

        <SwitchLanguage styleClass="hamburger-lang-switch" />


        <li>
          <LinkItem title={t("nav_home")} link="/pocetna-stranica" />
        </li>

        <li>
          <LinkItem title={t("login")} link="/auth/fizicko-lice" />
        </li>
        <li>
          <LinkItem title={t("nav_offers")} link="/pocetna/oglasi" />
        </li>

        <li >
          <LinkItem title={t("nav_companies")} link="/pocetna/firme" />
        </li>
        <li >
          <LinkItem title={t("nav_forum")} link="/pocetna/forum/teme" />
        </li>
        <li >
          <LinkItem title={t("nav_blog")} link="/blog" />
        </li>
        <li >
          <LinkItem title={t("nav_events")} link="/dogadjaji" />
        </li>
        <li >
          <LinkItem title={t("nav_forum")} link="/pocetna/forum/teme" />
        </li>
        <li >
          <LinkItem title={t("candidates")} link="/pocetna/korisnici" />
        </li>
        <li >
          <LinkItem title={t("nav_aboutUs")} link="/o-nama" />
        </li>

      </ul>
    </motion.div>
  );
};
export default HamburgerMenu;
