import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import usePackages from '../../../hooks/offers/usePackages';
import CountAndPricingItem from './CountAndPricingItem';
import useAdsCount from '../../../hooks/offers/useAdsCount';
import { Link } from 'react-router-dom';
import GenerateProformaInvoice from '../../Global/GenerateProformaInvoice';

const AdsCountAndPricing = () => {
  const { t } = useTranslation();
  //packages
  const { data: packages } = usePackages()
  const { data: adsCount } = useAdsCount()
  const [showModal, setShowModal] = useState(false)
  return (
    <div className='ads-container'>
      <GenerateProformaInvoice showModal={showModal} setShowModal={setShowModal} />
      <div className='ads-count-left'>
        <span className='ads-container-title'>{t('availableAd')}</span>
        <span className='ads-container-count'>{adsCount}</span>
      </div>
      <div className='ads-count-packages'>
        {packages?.map((pcg) => <CountAndPricingItem key={pcg?.id} {...pcg} setShowModal={setShowModal} />)}
      </div>

      <Link to="/pocetna/kreiraj-oglas" className='offer-page-apply-btn'>
        {t('create_job_offer')}
      </Link>
    </div>
  )
}

export default AdsCountAndPricing