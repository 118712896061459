import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import voidLogo from "../../assets/images/void.svg";

function NotFound404() {
  return (
    <div className="notfound">
      <img className="notfound-img" src={voidLogo} alt="404" />
      <span className="notfound-title"> 404 | STRANICA NE POSTOJI</span>

      <Link to="/dogadjaji">
        <button className="notfound-btn">
          <FontAwesomeIcon icon={faArrowCircleLeft} color="white" />
          Nazad
        </button>
      </Link>
    </div>
  );
}

export default NotFound404;
