export const TRANSLATIONS_SRB = {
  about_studenter:
    "StudENTER je platforma za povezivanje mladih ljudi i privrede. <br> Platforma, sa jedne strane nudi mladim ljudima priliku da brzo i lako plasiraju i valorizuju svoja stečena teorijska i praktična znanja i da se uključe u realne privredne tokove. Sa druge strane, privrednim subjektima nudi jedinstvene prilike da pristupe bazi školovanih i perspektivnih mladih ljudi i zadovolje svoje potrebe za realizacijom projekata/istraživanja/ispitivanja, ili jednostavno pronađu kandidata svog novog kolegu. <br> Po ugledu na druge evropske Univerzitete, koji imaju razvijenu praksu saradnje studenata i privrede, a na inicijativu studenata Univerziteta u Beogradu, razvijena je platforma StudENTER. Inicijativu su prihvatili i drugi univerziteti u regionu. Tehničku podršku u razvoju platforme pružila je IT kompanija Projectland. <br> Registruj se, registracija je besplatna.",
  events_title: "Događaji",
  events_subtitle: "Pogledaj sve predstojeće događaje",
  events_apply: "PRIJAVA",
  events_apply_text: "Morate imati nalog kako biste mogli da pošaljete prijavu",
  nav_home: "Početna stranica",
  nav_events: "Događaji",
  nav_blog: "Poslovne vesti",
  nav_aboutUs: "O Nama",
  nav_forum: "Forum",
  nav_companies: "Kompanije",
  nav_offers: "Oglasi",
  nav_messages: "Poruke",
  nav_students: "Studenti",
  nav_logIn: "Prijavi se",
  nav_logOut: "Odjavi se",
  blog_title: "Aktuelnosti",
  aboutus_title: "Na ENTER do posla",
  candidates: "Kandidati",
  candidate: "Kandidat",
  load_more: "Učitaj još",
  show_more: "Saznaj više",
  filter_companies_title: "Filtriraj kompanije",
  filter_students_title: "Filtriraj kandidate",
  filter_projects_title: "Filtriraj projekte",
  filter_jobs_title: "Filtriraj poslove",
  filter_search: "Pretraga",
  filter_country: "Zemlja",
  filter_city: "Grad",
  filter_field: "Oblast delatnosti",
  filter_reset: "Resetuj filtere",
  filter_country_placeholder: "--odaberi zemlju--",
  filter_city_placeholder: "--odaberi grad--",
  filter_field_placeholder: "--odaberi polje--",
  filter_level_placeholder: "--odaberi nivo--",
  filter_type: "TIP",
  my_comment: "Moj Komentar",
  filter_type_placeholder: "--odaberi tip--",
  filter_sort: "sortiraj",
  filter_sort_placeholder: "--sortiraj po--",
  filter_withPrice: "SA CENOM",
  filter_withDeadline: "SA ROKOM ZA REALIZACIJU",
  filter_withSalary: "SA PLATOM",
  filter_scienceField: "Naučna oblast",
  filter_scienceSubField: "Naučna podoblast",
  filter_levelOfEducation: "Nivo obrazovanja",
  filter_year_placeholder: "--odaberi godinu--",
  select: "--odaberi--",
  yes: "DA",
  no: "NE",
  name: "Ime",
  name_offer: "Naziv oglasa",
  login: "Prijavi se",
  register: "Registruj se",
  registerCompany: "Registruj se kao kompanija",
  until: "Važi do:",
  email: 'Email',
  password: "Lozinka",
  confirm_password: "Ponovi Lozinku",
  forgot_password: "Zaboravio/la sam lozinku?",
  create_job_offer: "Kreiraj oglas za posao",
  create_project_offer: "Kreiraj oglas za projekat",
  create_offer: "Kreiraj oglas",
  projects: "Projekti",
  jobs: "Poslovi",
  student: "Student",
  company: "Kompanija",
  error: "Došlo je do greške",
  no_offers: "Trenutno nema aktivnih oglasa",
  apply: "Apliciraj",
  price: "Cena",
  deadline: "Rok",
  addFiles: "Dodaj datoteke",
  show_details: "Prikaži detalje",
  company_page: "Stranica kompanije",
  contact: "Kontaktiraj",
  offer_jobs: "POSLOVI",
  offer_projects: "PROJEKTI",
  offer_apply: "Konkuriši",
  offer_applied: "Već ste konkurisali",
  offer_companyDetails: "Prikaži detalje o kompaniji",
  offer_apply_description:
    "Ukoliko biste želeli da aplicirate za projekat/posao, savetujemo Vam da popunite Vaš StudENTER profil, na taj način će platforma StudENTER generisati CV i proslediti ga poslodavcu, ukoliko imate svoj CV takođe ga možete dodati na stranici Moj Profil.",
  offer_create_offer: "Kreiraj oglas",
  offer_ads_description:
    " Ukoliko biste želeli da besplatno postavite ovakav oglas, pratite sledeće linkove. Dostupni su oglasi za posao/praksu, kao i oglasi za jednokratne projekte.",
  error_screen_text: "Izvinjavamo se, došlo je do greške, radimo na popravci",
  login_noAccount: "Nemaš nalog? ",
  login_guest: "Uđi kao gost",
  name_surname: "Ime i prezime",
  register_agree: "Saglasan sam sa ",
  terms_and_conditions: "Politikom privatnosti",
  must_agree_with_tac: "Morate se saglasiti sa politikom privanosti",
  already_have_acc: "Već imaš nalog? ",
  company_name: "Naziv kompanije",
  register_send_mail_again: "PONOVO POSALJI VERIFIKACIONI MAIL",
  send: 'Pošalji',
  note: "Napomena",
  note_not_required: "napomena (Nije obavezno)",
  my_profile: "Moj profil",
  my_offers: "Moji oglasi",
  my_applications: "Moje prijave",
  about_me: "O MENI",
  download: "Preuzmi ",
  messages: "Poruke",
  no_messagesText:
    " Trenutno nema poruka, konverzaciju možete započeti na profilima, klikom na dugme Kontakt",
  my_jobs: "MOJI POSLOVI",
  my_projects: "MOJI PROJEKTI",
  delete: "Obriši",
  are_you_sure: "Da li ste sigurni?",
  edit: "Izmeni",
  show_applications: "Prikaži prijave",
  basic_info: "Osnovne informacije",
  nothing_here: "Još uvek nema ničega..",
  field: "Oblast",
  adress: "Adresa",
  city: "Grad",
  country: "Zemlja",
  phone: "Telefon",
  about_company: "O kompaniji",
  company_info: "Podaci o firmi",
  old_password: "Trenutna lozinka",
  new_password: "Nova lozinka",
  save: "Sačuvaj",
  date_of_birth: "Datum rođenja",
  my_informations: "Moje informacije",
  add: "dodaj",
  formal_education: "Formalno obrazovanje",
  faculty_name: "Naziv obraznovne ustanove",
  finished: "Završeno",
  in_progress: "U toku",
  acquired_title: "STEČENO ZVANJE",
  non_formal_education: "Neformalno obrazovanje",
  knowledge: "Znanje",
  skills: "Veštine",
  competitions: "Kompeticije",
  certificate: "Sertifikat",
  references: "Reference",
  organization: "Organizacija",
  position: "pozicija",
  date_from: "Datum od:",
  date_to: "Datum do:",
  description: "Opis",
  photo: "fotografija",
  year_study: "Godina studija",
  bachelors_academic_degree: "Osnovne akademske studije",
  masters_academic_degree: "Master akademske studije",
  doctors_academic_degree: "Doktorske akademske studije",
  specialist_academic_degree: 'Specijalističke akademske studije',
  bachelors_vocational_degree: "Osnovne strukovne studije",
  specialist_vocational_degree: "Specijalističke strukovne studije",
  masters_vocational_degree: "Master strukovne studije",
  first_year: "Prva godina",
  second_year: "Druga godina",
  third_year: "Treća godina",
  fourth_year: "Četvrta godina",
  fifth_year: "Peta godina",
  sixth_year: "Šesta godina",
  required_field: "Obavezno polje",
  validation_name: "Ime mora biti manje od 100 karaktera",
  validation_email: "Email sme imati 100 karaktera",
  validation_adress: 'Maksimalno 70 karaktera"',
  validation_phone: "Tel mora biti manji od 20 karaktera",
  validation_city: "Grad mora imati manje od 30 karaktera",
  validation_website: "Website mora imati manje od 60 karaktera",
  validation_password: "Lozinka mora imati najmanje 8 karaktera",
  add_photo: "Dodaj fotografiju",
  reset_password: "Resetuj lozinku",
  change_password: "Promeni lozinku",
  back: "Nazad",
  discussions: "Teme",
  discussion_name: "Naziv teme",
  discussion_desc: "opis teme",
  discussion_create: "Kreiraj temu",
  no_discussions: "Još uvek nema diskusija na ovu temu",
  no_messages: "Još uvek nema poruka",
  discussion_comment_message:
    "Za komentarisanje i otvaranje diskusija morate biti prijavljeni kao kandidat",
  comment: "Komentariši",
  create_offer_freelance_msg: "Želiš jednokratni oglas za freelance projekat?",
  create_offer_job_msg: "Želiš oglas za stalni posao ili praksu?",
  click_here: "Klikni ovde",
  type: "Tip",
  sallary_number: "Visina zarade",
  duration: "TRAJANJE ANGAŽMANA",
  offer_duration: "Validnost oglasa",
  submit: "Postavi oglas",
  new_offer: "Novi oglas",
  budget: "BUDŽET",
  already_verified: "EMAIL VEĆ VERIFIKOVAN",
  email_verified: "EMAIL VERIFIKOVAN",
  wrong_url: "NEISPRAVAN URL",
  unauthorized_page: "Ova stranica je dostupna samo registrovanim korisnicima",
  free: "besplatno",
  itsFree: "besplatno je",
  notifications: "Obaveštenja",
  noNotifications: "Nema obaveštenja",
  deactivateAcc: "Deaktiviraj nalog",
  errContact:
    "Došlo je do greške, molimo Vas kontaktirajte office@studenter.org",
  deactivateAccText:
    "*Ukoliko se posle dektivacije prijavite u roku od 30 dana, nalog će biti obnovljen, posle 30 dana nalog se trajno briše*",

  question: "Pitanje",
  questionForCandidates: "Pitanja na koja kandidati odgovaraju pri prijavi",
  addQuestion: "Dodaj pitanje",
  removeQuestion: "Ukloni pitanje",
  answersChance:
    "Odgovaranjem na pitanja kompanije povećavate šansu da budete pozvani na intervju",
  availableAd: "Broj preostalih oglasa",
  pricingTitle: "Pronađite Idealne Kandidate - Istražite Studenter",
  pricingModels: 'Istražite naše pakete',
  contactUs: 'Kontaktirajte nas',
  needBiggerPlan: 'Potreban vam je veći paket?',
  visitOurSocials: 'Ili posetite naše društvene mreže',
  preformal: "Predračun"
  ,
  currency: "Valuta",
  downloadOurApp: 'Preuzmite našu aplikaciju',
  weAreAvailableOn: 'Možete nas potražiti na AppStore-u, kao i na PlayStore-u',
  becomePartOfStudenter: 'POSTANI DEO StudENTER ZAJEDNICE',
  freeReg: 'BESPLATNA REGISTRACIJA',
  directContactWithEmployers: 'DIREKTAN KONTAKT S POSLODAVCIMA',
  becomePartDescription1: "Kreiraj profil kao CV – konkuriši jednim klikom na oglas ili budi u vrhu pretrage poslodavcima kada im treba kandidat baš kao što si ti – s tvojim obrazovanjem i veštinama. Očekuju te oglasi za: praksu, posao, projekte, mentorske programe."
  , becomePartDescription2: 'Ostvari benefite i popuste na konferencijama, seminarima, obukama kao član StudENTER zajednice. Budi među preko 3000 mladih obrazovanih kandidata iz regiona. Konektuj se sa kolegama sa drugih fakulteta i sa privrednicima.',
  beOnEnterFromWork: 'Budi na ENTER od posla',
  opportunityForCompanies: 'PRILIKA ZA KOMPANIJE',
  accessToBase: 'PRISTUPITE BAZI PREKO 3000 MLADIH OBRAZOVANIH PERSPEKTIVNIH KANDIDATA',
  searchCandidatesBy: "Pretražujte kandidate po:",
  byScienceField: 'Naučnom polju',
  byLevelOfEdu: 'Stepenu obrazovanja',
  bySkills: 'Veštinama',
  companyGuestInfoDesc:
    "     Postavite oglas za posao / praksu / projekat, najavite mentorske programe. Rado ćemo najaviti i vaše konferencije i seminare."
  ,
  companyGuestInfoDesc2: '   Kreirajte profil firme i budite u fokusu mladima koji grade svoj karijerni put.'
};
