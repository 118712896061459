import { useInfiniteQuery } from "react-query";

const fetchDiscussions = async ({ pageParam = 1, queryKey, id }) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/topic-discussions/${id}?page=${pageParam}`
  );
  if (!response.ok) {
    throw new Error("Something went wrong!");
  }
  return response.json();
};

const useDiscussions = (id) => {
  return useInfiniteQuery(
    [`discussions/${id}`],
    () => fetchDiscussions({ id }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
          return lastPage?.meta?.current_page + 1;
        return false;
      },
    }
  );
};
export default useDiscussions;

//   const {
//     data: articles,
//     isLoading,
//     fetchNextPage,
//     hasNextPage,
//     error,
//   } =
