import React from 'react'
import useAuth from '../../../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const CountAndPricingItem = (props) => {
    const { setShowModal, ads_count } = props;
    const { user } = useAuth();

    return (
        <button className='package-preinvoice' onClick={() => setShowModal({ name: props?.name, id: props?.id })}>


            <span className='ads-item-text'> {props?.name} PLAN </span>



            <ul className='pricing-item-list'>
                <li className='pricing-item-p' >
                    <FontAwesomeIcon
                        icon={faCheck}
                        color="#334155"
                    />  {ads_count} {ads_count === 1 ? 'oglas' : 'oglasa'} za posao ili praksu </li>
                <li className='pricing-item-p'> <FontAwesomeIcon
                    icon={faCheck}
                    color="#334155"
                /> Share na društvene mreže</li>
                <li className='pricing-item-p'> <FontAwesomeIcon
                    icon={faCheck}
                    color="#334155"
                /> Oglas sa slikom</li>

            </ul>
            {user?.user?.country?.id === 1 && <span className='ads-item-text ads-item-text--s'> {props?.price_rsd} RSD</span>}
            {user?.user?.country?.id === 2 && <span className='ads-item-text  ads-item-text--s'> {props?.price_eur} EUR</span>}
            {user?.user?.country?.id === 3 && <span className='ads-item-text  ads-item-text--s'> {props?.price_bam} BAM</span>}
            {user?.user?.country?.id === 4 && <span className='ads-item-text  ads-item-text--s'>{props?.price_bam} EUR</span>}
            {user?.user?.country?.id === 5 && <span className='ads-item-text  ads-item-text--s'>{props?.price_mkd} MKD</span>}
            {!user?.user?.country?.id && <span className='ads-item-text  ads-item-text--s'>{props?.price_rsd} RSD</span>}
        </button >
    )
}

export default CountAndPricingItem