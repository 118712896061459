import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useSearchParams } from "react-router-dom";

const CandidatesPermission = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <div className="guest-permission">
      {pathname == "/pocetna/firme" ? (
        <Helmet>
          <title> Kompanije</title>
          <meta
            name="description"
            content="Pogledajte sve kompanije koje se nalaze na našoj platformi"
          />
          <link rel="canonical" href="/pocetna/firme" />
        </Helmet>
      ) : (
        <Helmet>
          <title> Korisnici</title>
          <meta
            name="description"
            content="Potreban Vam je radnik? Ovde možete pronaći potencijalnog radnika iz svih polja edukacije"
          />
          <link rel="canonical" href="/pocetna/korisnici" />
        </Helmet>
      )}


      <span className="guest-permission-text">
        <h2 style={{ fontSize: 22, fontWeight: 500 }}>
          {t('becomePartOfStudenter')}
        </h2>
        <ul style={{ listStyleType: "disc", textAlign: "left", padding: 10 }}>
          <li> {t('freeReg')}</li>
          <li>{t('directContactWithEmployers')}</li>
        </ul>
        {t('becomePartDescription1')}<br /> <br />     {t('becomePartDescription2')}
        <br /> <br />
        <strong>
          {t('beOnEnterFromWork')}
        </strong>
      </span>
      <Link to="/auth/fizicko-lice/register" className="guest-permission-btn">
        {t("register")} {t("free")}
      </Link>
      <span className="guest-permission-text">{t("already_have_acc")}</span>
      <Link to="/auth/fizicko-lice" className="guest-permission-btn">
        {t("login")}
      </Link>
    </div>
  );
};

export default CandidatesPermission;
