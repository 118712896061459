import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

const deleteMessage = async (msgId) => {
  return axios.delete(
    `${process.env.REACT_APP_BASE_URL}/forum-posts/${msgId}`,
    {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    }
  );
};

const useMessageDelete = (dId) => {
  //dId == discussion id
  const queryClient = useQueryClient();
  return useMutation(
    async (values) => {
      return deleteMessage(values);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`messages/${dId}`]);
      },
    }
  );
};

export default useMessageDelete;
