import React, { useEffect } from "react";

import { Outlet } from "react-router";
//components
import useAuth from "../../hooks/useAuth";
import AllMessages from "../Chat/AllMessages";
import NoMessages from "../Chat/NoMessages";
import { useTranslation } from "react-i18next";
import useLatestMessages from "../../hooks/messages/useLatestMessages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEnvelopeOpen, faEnvelopeOpenText, faEnvelopeSquare, faNotesMedical } from "@fortawesome/free-solid-svg-icons";

const Chat = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { data: messages, isLoading, error, refetch } = useLatestMessages()

  return (
    <div className="chat-wrapper">
      <div className="home-item-heading">
        <span className="chat-title">{t("messages")}   </span>

      </div>
      <div className="home-item-chat">
        {messages?.length < 1 && <NoMessages />}
        <AllMessages
          user={user}
          messages={messages}
          isLoading={isLoading}
          getLatestMessages={refetch}
          error={error}
        />

        <Outlet context={refetch} />
      </div>
    </div>
  );
};
export default Chat;
