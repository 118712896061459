import React from "react";
import useAuth from "../../hooks/useAuth";

const ConversationMessage = ({ message }) => {
  const { user } = useAuth();

  function createMarkup() {
    return { __html: message.text };
  }

  return (
    <div className="conversation-msg">
      <img
        className="conversation-msg-img"
        alt="profile"
        src={`https://studenter.org/${
          user.role === parseInt(process.env.REACT_APP_COMPANY)
            ? message.user?.image
            : message.company?.logo
        }`}
      />
      <div className="conversation-msg-baloon box-drop-shadow">
        {message.direction == 0 && (
          <span
            className="conversation-msg-text"
            dangerouslySetInnerHTML={createMarkup()}
          />
        )}
        {message.direction == 1 && (
          <span className="conversation-msg-text">{message.text}</span>
        )}
        <span className="conversation-msg-time">{message.created_at}</span>
      </div>
    </div>
  );
};

export default ConversationMessage;
