import React from "react";

const RadioField = (props) => {
  return (
    <div className={`input-radio ${props.className}`}>
      <label className={`input-label ${props.labelClassname}`}>
        {props.title}
      </label>
      {props.touched && props.errors ? (
        <p className="input-errors">{props.errors}</p>
      ) : (
        ""
      )}
      <div className="radio-buttons-wrapper">
        <div className="radio-wrapper">
          <input
            type="radio"
            value={props.value1}
            onChange={props.onChange}
            name={props.name}
            onBlur={props.onBlur}
          />
          <label className={`radio-label ${props.labelClassname}`}>
            {props.value1name}
          </label>
        </div>
        <div className="radio-wrapper">
          <input
            onChange={props.onChange}
            type="radio"
            name={props.name}
            value={props.value2}
            onBlur={props.onBlur}
          />
          <label className={`radio-label ${props.labelClassname}`}>
            {props.value2name}
          </label>
        </div>
      </div>
    </div>
  );
};

export default RadioField;
