import React, { useEffect } from "react";
import Event from "../../components/Events/Event";
import Banner from "../../components/Global/Banner";
import Loader from "../../components/Global/Loader";

//language
import { useTranslation } from "react-i18next";
import "../../languages/i18n";
import { useInfiniteQuery } from "react-query";

const EventsPage = () => {
  const { t } = useTranslation();

  const fetchInfiniteEvents = async ({ queryKey }) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/events`);
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    return response.json();
  };
  const {
    data: events,
    isLoading,
    fetchNextPage,
    hasNextPage,
    error,
  } = useInfiniteQuery(["events"], fetchInfiniteEvents, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
        return lastPage?.meta?.current_page + 1;
      return false;
    },
  });

  if (isLoading) return <Loader />;
  if (error) return <h1>Molimo pokusajte kasnije</h1>;
  return (
    <>
      <Banner
        h1={t("events_title")}
        h3={t("events_subtitle")}
        img="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1267&amp;q=80"
      />

      {events && (
        <div className="eventlist">
          {events?.pages?.map((page) =>
            page?.data?.map((event) => <Event event={event} key={event.id} />)
          )}
        </div>
      )}

      {/* {hasNextPage && (
        <button className="offers-load-more-btn" onClick={fetchNextPage}>
          {t("load_more")}
        </button>
      )} */}
    </>
  );
};
export default EventsPage;
