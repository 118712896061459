import axios from "axios";
import { useQuery } from "react-query";


const fetchFunction = async () => {
    const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/messages/latest-message`, {
        headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
        },
    }
    );
    return response?.data
};

const useLatestMessages = () => {
    return useQuery(["latest-messages"], fetchFunction);
};

export default useLatestMessages;
