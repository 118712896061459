import React from "react";

export default function CheckboxInput(props) {
  return (
    <div className={`input ${props.className}`}>
      <label className="input-label">{props.title}</label>
      {props.touched && props.errors ? (
        <p className="input-errors">{props.errors}</p>
      ) : (
        ""
      )}
      <div>
        <input
          onChange={props.onChange}
          value={props.value}
          type="checkbox"
          className="input-checkbox"
          onBlur={props.onBlur}
          name={props.name}
          checked={props.checked}
        />
        <span className="checkbox-text">{props.checkboxText}</span>
      </div>
    </div>
  );
}
