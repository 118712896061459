import React from "react";
import linkedinSmall from "../../assets/socials/linkedinSmall.png";
import facebookSmall from "../../assets/socials/facebookSmall.png";
import instagramSmall from "../../assets/socials/instagramSmall.png";

const Footer = () => {
  return (
    <div className="landing-footer">
      <span className="landing-footer-text">office@studenter.org</span>
      <div className="landing-footer-socials">
        <a
          href="https://www.facebook.com/Studenter-108603125100537"
          target="_blank"
          className="landing-footer-social"
          rel="noreferrer"
        >
          <img src={facebookSmall} alt="FB" />
        </a>
        <a
          href="https://www.instagram.com/_studenter_"
          target="_blank"
          className="landing-footer-social"
          rel="noreferrer"
        >
          <img src={instagramSmall} alt="INSTAGRAM" />
        </a>
        <a
          href="https://www.linkedin.com/company/studenter-serbia/"
          target="_blank"
          className="landing-footer-social"
          rel="noreferrer"
        >
          <img src={linkedinSmall} alt="LINKEDIN" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
