import React, { useEffect, useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import useJobPreview from "../../hooks/ladning/useJobPreview";
import JobCard from "./JobCard";
import MainSliderLoader from "./MainSliderLoader";

export default () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(2);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(2);
      }
    }

    handleResize();

    let timeoutId;
    function debouncedHandleResize() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleResize, 500);
    }

    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slides: {
      perView: slidesToShow,
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  const { data: jobs, isLoading, isError } = useJobPreview();

  if (jobs?.length < 2) return null;
  if (isError) return null;
  return (
    <>
      <div className="navigation-wrapper">
        {jobs ? (
          <div ref={sliderRef} className="keen-slider">
            {jobs?.map((job) => (
              <JobCard {...job} key={job.id} />
            ))}
          </div>
        ) : (
          <MainSliderLoader />
        )}

        {loaded && instanceRef?.current && (
          <>
            <Arrow
              left
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
              disabled={currentSlide === 0}
            />

            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length - 1
              }
            />
          </>
        )}
      </div>
      {loaded && instanceRef.current && (
        <div className="dots">
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              ></button>
            );
          })}
        </div>
      )}
    </>
  );
};

function Arrow(props) {
  const disabeld = props.disabled ? " arrow--disabled" : "";
  return (
    <svg
      onClick={props.onClick}
      className={`arrow ${
        props.left ? "arrow--left" : "arrow--right"
      } ${disabeld}`}
      viewBox="0 0 24 24"
      width="12"
      height="12"
    >
      {props.left && (
        <g transform="scale(0.7)">
          <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        </g>
      )}
      {!props.left && (
        <g transform="scale(0.7)">
          <path
            d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"
            strokeWidth="1"
          />
        </g>
      )}
    </svg>
  );
}
