import React from "react";
import logoBlue from "../../assets/images/logo-blue.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailBulk, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import linkedinSmall from "../../assets/socials/linkedinSmall.png";
import facebookSmall from "../../assets/socials/facebookSmall.png";
import instagramSmall from "../../assets/socials/instagramSmall.png";
import googlePlay from "../../assets/socials/playstore.png";
import appStore from "../../assets/socials/appstore.png";
import { Helmet } from "react-helmet-async";
import NavigationBar from "../NavBar/NavigationBar";
import { useTranslation } from "react-i18next";
const AboutUs = () => {
  const { t } = useTranslation();
  function createMarkup() {
    return { __html: t("about_studenter") };
  }
  return (
    <div className="about-us">
      <NavigationBar className="nav-absolute" />
      <Helmet>
        <title>{t("aboutus_title")}</title>
        <meta
          name="description"
          content="Po ugledu na druge evropske Univerzitete, a na inicijativu Saveza studenata Mašinskog fakulteta, razvijena je platforma StudENTER."
        />
        <link rel="canonical" href="/o-nama" />
      </Helmet>
      <h1 className="about-us-header-title">{t("aboutus_title")}</h1>
      <div className="about-us-content-wrapper">
        <span
          className="about-us-text"
          dangerouslySetInnerHTML={createMarkup()}
        />

        <div className="about-us-contact">
          <img className="about-us-logo" src={logoBlue} alt="logo" />
          <div className="about-us-contact-item">
            <FontAwesomeIcon icon={faPhoneAlt} color="grey" />
            <span className="about-us-contact-text"> +381-62-247-796</span>
          </div>
          <div className="about-us-contact-item">
            <FontAwesomeIcon icon={faMailBulk} color="grey" />
            <span className="about-us-contact-text">office@studenter.org</span>
          </div>
          <div className="about-us-social-links">
            <a
              href="https://www.facebook.com/Studenter-108603125100537"
              target="_blank"
              className="footer-social-link"
              rel="noreferrer"
            >
              <img src={facebookSmall} alt="FB" />
            </a>
            <a
              href="https://www.instagram.com/_studenter_"
              target="_blank"
              className="footer-social-link"
              rel="noreferrer"
            >
              <img src={instagramSmall} alt="INSTAGRAM" />
            </a>
            <a
              href="https://www.linkedin.com/company/studenter-serbia/"
              target="_blank"
              className="footer-social-link"
              rel="noreferrer"
            >
              <img src={linkedinSmall} alt="LINKEDIN" />
            </a>
          </div>
          <div className="flexRow gap-16">
            <a
              href="https://play.google.com/store/apps/details?id=com.studenter.app&hl=en&gl=US"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={googlePlay}
                alt="Googleplay"
                className="about-us-google"
              />
            </a>
            <a
              href="https://apps.apple.com/hr/app/studenter/id1639547864"
              target="_blank"
              rel="noreferrer"
            >
              <img className="about-us-google" src={appStore} alt="PlayStore" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
