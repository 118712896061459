import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
//local data
import { typeStudies, typeStudiesEng } from "../../constants/LocalData";
//translations
import { useTranslation } from "react-i18next";
import i18n from "../../languages/i18n";
//components
import FilterLoader from "../Companies/FilterLoader";
import SearchInput from "../Inputs/SearchInput";
import SelectField from "../Inputs/SelectField";
//hooks data
import useCities from "../../hooks/lists/useCities";
import useCountries from "../../hooks/lists/useCountries";
import useAcademicFields from "../../hooks/lists/useAcademicFields";
import useAcademicSubFields from "../../hooks/lists/useAcademicSubFields";
import GooglePlay from "../Global/GooglePlay";

const FilterUser = () => {
  const { data: academicFields } = useAcademicFields();
  const { data: academicSubFields } = useAcademicSubFields();
  const { data: cities } = useCities();
  const { data: countries } = useCountries();

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      search: searchParams.get("query") || "",
      city: searchParams.get("city") || "",
      academic_field: searchParams.get("academic_field") || "",
      academic_subfield: searchParams.get("academic_subfield") || "",
      country: searchParams.get("country") || "",
      level: searchParams.get("level") || "",
    },
  });

  let searchObj = {};
  useEffect(() => {
    if (formik.values.search) {
      searchObj.query = formik.values.search;
    }

    if (formik.values.city) {
      searchObj.city = formik.values.city;
    }
    if (formik.values.country) {
      searchObj.country = formik.values.country;
    }
    if (formik.values.academic_field) {
      searchObj.academic_field = formik.values.academic_field;
    }
    if (formik.values.academic_subfield) {
      searchObj.academic_subfield = formik.values.academic_subfield;
    }
    if (formik.values.level) {
      searchObj.level = formik.values.level;
    }

    searchObj && setSearchParams(searchObj);
    // eslint-disable-next-line
  }, [formik.values]);

  const handleReset = () => {
    setSearchParams();
    formik.resetForm();
  };

  return (
    <form className="filter-component">
      <h2 className="filter-component-title">{t("filter_students_title")}</h2>
      <FilterLoader />
      <SearchInput
        label={t("filter_search")}
        name="search"
        onChange={formik.handleChange}
        value={
          searchParams.get("query")
            ? searchParams.get("query")
            : formik.values.search
        }
      />
      <SelectField
        label={t("filter_scienceField")}
        name="academic_field"
        placeholder={t("filter_field_placeholder")}
        onChange={formik.handleChange}
        value={formik.values.academic_field}
        list={academicFields?.data?.data}
      />
      <SelectField
        label={t("filter_scienceSubField")}
        name="academic_subfield"
        placeholder={t("filter_type_placeholder")}
        onChange={formik.handleChange}
        value={formik.values.academic_subfield}
        list={academicSubFields?.data?.data?.filter(
          (field) =>
            parseInt(field.academic_field_id) ===
            parseInt(formik.values.academic_field)
        )}
      />
      <SelectField
        label={t("filter_levelOfEducation")}
        name="level"
        placeholder={t("filter_level_placeholder")}
        onChange={formik.handleChange}
        value={formik.values.level}
        list={i18n.language === "en" ? typeStudiesEng : typeStudies}
      />
      <SelectField
        label={t("filter_country")}
        name="country"
        placeholder={t("filter_country_placeholder")}
        onChange={formik.handleChange}
        value={formik.values.country}
        list={countries?.data?.data}
      />
      <SelectField
        label={t("filter_city")}
        name="city"
        placeholder={t("filter_city_placeholder")}
        onChange={formik.handleChange}
        value={formik.values.city}
        list={cities?.data?.data?.filter(
          (filteredCity) =>
            parseInt(filteredCity.country_id) ===
            parseInt(formik.values.country)
        )}
      />

      {(formik.values.search ||
        formik.values.city ||
        formik.values.country ||
        formik.values.academic_field ||
        formik.values.academic_subfield ||
        formik.values.level) && (
        <button
          type="button"
          onClick={() => handleReset()}
          className="input-reset-filter"
        >
          {t("filter_reset")}
        </button>
      )}
      <GooglePlay />
    </form>
  );
};

export default FilterUser;
