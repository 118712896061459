import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router";
import NavigationLinkStyled from "../../Global/NavigationLinkStyled";

const OffersHome = () => {
  const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/pocetna/oglasi") {
      navigation("poslovi");
    }
  }, []);
  const { t } = useTranslation();
  return (
    <div className="offers-home">
      <nav className="offers-nav">
        <Helmet>
          <title>Oglasi</title>
          <meta
            name="description"
            content="Potreban Vam je posao? Ovde možete pronaći potencijalnog zaposlenje, praksu ili freelance projekat."
          />
          <link rel="canonical" href="/pocetna/oglasi" />
        </Helmet>
        <NavigationLinkStyled
          title={t("offer_jobs")}
          link="/pocetna/oglasi/poslovi"
        />
        <NavigationLinkStyled
          title={t("offer_projects")}
          link="/pocetna/oglasi/projekti"
        />
      </nav>
      <Outlet />
    </div>
  );
};

export default OffersHome;
