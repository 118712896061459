import { faReply, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import useMessageDelete from "../../hooks/forum/useMessageDelete";
import useAdministratior from "../../hooks/useAdministrator";
import DeletePrompt from "./DeletePrompt";

const Message = ({ message, color, textAreaRef, setMyMessage }) => {
  const replyBtnHandler = () => {
    textAreaRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    setMyMessage(`Odgovor na: "${message.message}":`);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const isAdmin = useAdministratior();
  const { dId } = useParams();
  const { mutate: deleteMessage } = useMessageDelete(dId);

  return (
    <div className="forum-message">
      <div className="forum-message-userinfo">
        <img src={message?.user?.image} alt="" className="forum-message-img" />
        <span className="forum-message-name">{message?.user?.name}</span>
        <span className="forum-message-email">{message?.user?.email}</span>
        <span className="forum-message-userinfodate">
          {message?.created_at}
        </span>
      </div>
      <div className="forum-message-text-wrapper">
        <span className="forum-message-text">{message.message}</span>
      </div>
      <div className="forum-message-menu">
        <span className="forum-message-date">{message.created_at}</span>
        <button
          className="forum-message-replyBtn"
          onClick={() => replyBtnHandler()}
        >
          <span className="hide-for-mobile">Odgovori</span>
          <FontAwesomeIcon icon={faReply} color="black" />
        </button>
        {isAdmin && (
          <FontAwesomeIcon
            icon={faTrashAlt}
            color="black"
            className="forum-message-delete"
            onClick={() => setIsModalOpen(true)}
          />
        )}
        <DeletePrompt
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          deleteFunction={deleteMessage}
          id={message?.id}
        />
      </div>
    </div>
  );
};

export default Message;
