import React from "react";
import useDiscussions from "../../hooks/forum/useDiscussions";
import { colors } from "./Colors";
import ErrorScreen from "../Global/ErrorScreen";
import Discussion from "./Discussion";
import { useParams } from "react-router-dom";
import NoData from "../Global/NoData";
import CreateDiscussion from "./CreateDiscussion";
import Unauthorized from "./Unauthorized";
import useAuth from "../../hooks/useAuth";
import { motion } from "framer-motion";
import { fadeIn2 } from "../Functions/Animations";

const Discussions = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const { data: discussions, isLoading, error } = useDiscussions(id);

  if (isLoading) return null;
  if (error) return <ErrorScreen />;

  return (
    <div className="forum-wrapper">
      <h1 className="forum-title">Diskusije</h1>
      <motion.div
        className="forum-content"
        variants={fadeIn2}
        animate="show"
        initial="hidden"
      >
        {discussions?.pages?.map((page) => page?.data.length) < 1 && <NoData />}
        {discussions?.pages?.map((page) =>
          page?.data?.map((d) => (
            <Discussion
              discussion={d}
              key={d.id}
              color={colors[Math.floor(Math.random() * colors.length)]}
            />
          ))
        )}
        {user ? <CreateDiscussion id={id} /> : <Unauthorized />}
      </motion.div>
    </div>
  );
};

export default Discussions;
