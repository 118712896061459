import React from "react";
import Lottie from "lottie-react";
import animationData from "../../assets/animations/rocket.json";
import { useTranslation } from "react-i18next";

const Rocket = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: "100%",
        height: 150,
        display: "flex",
        height: "62vh",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Lottie
        animationData={animationData}
        style={{ background: "transparent", height: 350 }}
      />
      <span style={{ fontSize: 16, fontWeight: 600, color: "#64748b" }}>
        {t("nothing_here")}
      </span>
    </div>
  );
};

export default Rocket;
