import React, { useState } from "react";

//anim
import { motion } from "framer-motion";
import { authVariants } from "../../Functions/Animations";
//components
import {
  pumaTypeOfParticipants,
  yoursTypeOfParticipants,
} from "../../../constants/LocalData";
import { Link } from "react-router-dom";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
//hooks
import useAuth from "../../../hooks/useAuth";
import useAxiosPost from "../../../hooks/useAxiosPost";
import { MutatingDots } from "react-loader-spinner";
import SelectFieldNameValue from "../../Inputs/SelectFieldNameValue";
import WhiteInput from "../../Inputs/WhiteInput";
import SelectFieldValue from "../../Inputs/SelectFieldValue";

const facultyType = [
  { id: 0, name: "Rudarsko-geološki", value: "Rudarsko-geološki" },
  { id: 1, name: "Drugo", value: "Drugo" },
];
const transportType = [
  { id: 0, name: "Organizovano", value: "organized" },
  { id: 1, name: "Lični prevoz", value: "own" },
];
const depOfEdu = [
  { id: 0, name: "Rudarsko inženjerstvo", value: "Rudarsko inženjerstvo" },
  {
    id: 1,
    name: "Inženjerstvo zaštite životne sredine",
    value: "Inženjerstvo zaštite životne sredine",
  },
  {
    id: 2,
    name: "Inženjerstvo nafte i gasa",
    value: "Inženjerstvo nafte i gasa",
  },
  { id: 3, name: "Geologija", value: "Geologija" },
  { id: 4, name: "Geotehnika", value: "Geotehnika" },
  { id: 5, name: "Geofizika", value: "Geofizika" },
  { id: 6, name: "Regionalna geologija", value: "Regionalna geologija" },
  {
    id: 7,
    name: "Istraživanje ležišta mineralnih sirovina",
    value: "Istraživanje ležišta mineralnih sirovina",
  },
];
const faculty = [
  { id: 0, name: "Organizovano", value: "organized" },
  { id: 1, name: "Lični prevoz", value: "own" },
];
const alreadyBeen = [
  { id: 0, name: "Da", value: 1 },
  { id: 1, name: "Ne", value: 0 },
];
const studentStatus = [
  { id: 0, name: "Samofinansirajući", value: "self_finance" },
  { id: 1, name: "Budžet", value: "budget" },
];
const yearOfEdu = [
  {
    id: 0,
    name: "I",
  },
  {
    id: 1,
    name: "II",
  },
  {
    id: 2,
    name: "III",
  },
  {
    id: 3,
    name: "IV",
  },
  {
    id: 4,
    name: "Produžena",
  },
  {
    id: 5,
    name: "Master",
  },
  {
    id: 6,
    name: "Doktorske",
  },
];

const PalicForm = ({ id, slug }) => {
  const { user } = useAuth();
  const { response, error, isLoading, axiosPost } = useAxiosPost();

  const formik = useFormik({
    initialValues: {
      event_id: id, //
      transcript_number: "", //
      phone: "", //
      transport: "", //
      faculty_location: "",
      year: "", //
      student_status: "", //
      suggestions: "",
      guests: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string().required("Obavezno polje"),
      transcript_number: Yup.string().required("Obavezno polje"),
      transport: Yup.string().required("Obavezno polje"),
      faculty_location: Yup.string().required("Obavezno polje"),
      year: Yup.string().required("Obavezno polje"),
      student_status: Yup.string().required("Obavezno polje"),
    }),
    onSubmit: (values) => {
      axiosPost({
        url: `${process.env.REACT_APP_BASE_URL}/submission`,
        data: values,
      });
    },
  });

  if (user)
    return (
      <>
        <div className="apply">
          <h2 className="apply-title">Prijava</h2>
          <span className="apply-text">
            Ukoliko ste zainteresovani da prisustvujete događaju kliknite na
            dugme ispod kako biste se prijavili.
          </span>

          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={authVariants}
          >
            <form onSubmit={formik.handleSubmit} className="apply-form">
              <WhiteInput
                className="input-padding"
                type="text"
                placeholder=""
                label="BROJ INDEKSA"
                name="transcript_number"
                onBlur={formik.handleBlur}
                touched={formik.touched.transcript_number}
                onChange={formik.handleChange}
                errors={formik.errors.transcript_number}
                value={formik.values.transcript_number}
              />
              <WhiteInput
                className="input-padding"
                type="text"
                placeholder=""
                label="TELEFON"
                name="phone"
                onBlur={formik.handleBlur}
                touched={formik.touched.phone}
                onChange={formik.handleChange}
                errors={formik.errors.phone}
                value={formik.values.phone}
              />

              <SelectFieldValue
                name="faculty_location"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.faculty_location}
                list={facultyType}
                inputLabelClassName="white-input-label"
                label="Fakultet"
                onBlur={formik.handleBlur}
                touched={formik.touched.faculty_location}
                errors={formik.errors.faculty_location}
                errorsClassName="white-input-label"
              />
              {formik.values.faculty_location === "Rudarsko-geološki" && (
                <SelectFieldValue
                  name="dep_of_education"
                  placeholder=""
                  onChange={formik.handleChange}
                  value={formik.values.dep_of_education}
                  list={depOfEdu}
                  inputLabelClassName="white-input-label"
                  label="Smer"
                  onBlur={formik.handleBlur}
                  touched={formik.touched.dep_of_education}
                  errors={formik.errors.dep_of_education}
                  errorsClassName="white-input-label"
                />
              )}
              {!(
                formik.values.faculty_location === "Rudarsko-geološki" ||
                formik.values.faculty_location === ""
              ) && (
                <WhiteInput
                  className="input-padding"
                  type="text"
                  placeholder=""
                  label="NAZIV FAKULTETA"
                  name="faculty_location"
                  onBlur={formik.handleBlur}
                  touched={formik.touched.faculty_location}
                  onChange={formik.handleChange}
                  errors={formik.errors.faculty_location}
                  //   value={formik.values.faculty_location}
                />
              )}
              <SelectFieldValue
                name="transport"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.transport}
                list={transportType}
                inputLabelClassName="white-input-label"
                label="VRSTA TRANSPORTA"
                onBlur={formik.handleBlur}
                touched={formik.touched.transport}
                errors={formik.errors.transport}
                errorsClassName="white-input-label"
              />
              <SelectFieldValue
                name="year"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.year}
                list={yearOfEdu}
                inputLabelClassName="white-input-label"
                label="Godina studiranja"
                onBlur={formik.handleBlur}
                touched={formik.touched.year}
                errors={formik.errors.year}
                errorsClassName="white-input-label"
              />
              <SelectFieldValue
                name="student_status"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.student_status}
                list={studentStatus}
                inputLabelClassName="white-input-label"
                label="samofinansirajući ili budžet"
                onBlur={formik.handleBlur}
                touched={formik.touched.student_status}
                errors={formik.errors.student_status}
                errorsClassName="white-input-label"
              />
              <WhiteInput
                className="input-padding"
                type="text"
                placeholder=""
                label="SA KIM ŽELITE DA BUDETE U SOBI?"
                name="guests"
                onBlur={formik.handleBlur}
                touched={formik.touched.guests}
                onChange={formik.handleChange}
                errors={formik.errors.guests}
                value={formik.values.guests}
              />
              <WhiteInput
                className="input-padding"
                type="text"
                placeholder=""
                label="DODATNA PITANJA?"
                name="suggestions"
                onBlur={formik.handleBlur}
                touched={formik.touched.suggestions}
                onChange={formik.handleChange}
                errors={formik.errors.suggestions}
                value={formik.values.suggestions}
              />
              <span className="auth-message">
                {isLoading && (
                  <MutatingDots secondaryColor="#1e2a3b" color="#c9d5fd" />
                )}
                {error?.message && error.message}
              </span>
              <span className="auth-message">
                {response?.message && response.message}
              </span>
              <motion.button
                whileHover={{ scale: 1.02 }}
                type="submit"
                className="apply-submit-btn"
              >
                PRIJAVI SE
              </motion.button>
              {(parseInt(process.env.REACT_APP_SUPER_ADMIN) === user.role ||
                parseInt(process.env.REACT_APP_ADMIN) === user.role) && (
                <a
                  className="apply-download-btn"
                  target="_blank"
                  download="filename"
                  href={`${process.env.REACT_APP_BASE_URL}/event-export/${slug}`}
                >
                  PREUZMI PRISTIGLE PRIJAVE
                </a>
              )}
            </form>
          </motion.div>
        </div>
      </>
    );
  return (
    <div className="apply">
      <h3 className="apply-text">
        Samo se registrovani studenti mogu prijaviti za dogadjaj
      </h3>
      <Link to="/auth/fizicko-lice" className="apply-download-btn">
        Prijavi se
      </Link>
    </div>
  );
};

export default PalicForm;
