import React, { useEffect, useState } from "react";
//Components
import UserEducation from "./UserEducation";
import UserInfo from "./UserInfo";
import UserCourses from "./UserCourses";
import PasswordChange from "./PasswordChange";
import UserReferences from "./UserReferences";
import UserCV from "./UserCV";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import ErrorScreen from "../Global/ErrorScreen";
import UserDelete from "./UserDelete";
const UserSettings = () => {
  const [profileInfo, setProfileInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const { user } = useAuth();
  const { t } = useTranslation();

  const profileInfoHandler = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user-profile`,
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );

      setProfileInfo(response.data.data);
    } catch (error) {
      setErrors(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    profileInfoHandler();
  }, []);

  if (errors) return <ErrorScreen />;

  return (
    <div className="usersettings box-drop-shadow">
      <div className="usersettings-heading">
        <span className="usersettings-title">{t("my_informations")}</span>
      </div>
      <UserInfo profile={profileInfo} refresh={profileInfoHandler} />
      <UserCV id={user.id} />
      {/* <UserLanguages userLanguages={profileInfo.languages} /> */}
      <PasswordChange />
      <UserEducation
        isLoading={isLoading}
        educations={profileInfo?.educations}
      />
      <UserCourses courses={profileInfo?.courses} />
      <UserReferences references={profileInfo?.references} />
      <UserDelete />
    </div>
  );
};
export default UserSettings;
