import React from "react";
import Modal from "react-modal";
const DeletePrompt = ({ isModalOpen, setIsModalOpen, id, deleteFunction }) => {
  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }
  const customStyles = {
    overlay: { zIndex: 10, backgroundColor: "rgba(70, 70, 70, 0.5)" },
  };
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={toggleModal}
      contentLabel="My dialog"
      className="forum-modal"
      style={customStyles}
      overlayClassName="forum-modal-overlay"
      appElement={document.getElementsByClassName("forum-wrapper")}
    >
      <h1 className="forum-modal-title">Da li ste sigurni?</h1>
      <div className="forum-modal-btns">
        <button className="forum-modal-btn-no" onClick={() => toggleModal()}>
          Ne
        </button>
        <button
          className="forum-modal-btn-yes"
          onClick={() => deleteFunction(id)}
        >
          Da
        </button>
      </div>
    </Modal>
  );
};

export default DeletePrompt;
