import React from "react";
import linkedinSmall from "../../assets/socials/linkedinSmall.png";
import facebookSmall from "../../assets/socials/facebookSmall.png";
import instagramSmall from "../../assets/socials/instagramSmall.png";
export default function Footer() {
  return (
    <div className="footer-wrapper">
      <div className="footer-background"> </div>
      <div className="footer">
        <a className="footer-link" href="https://projectland.rs">
          Projectland © 2022
        </a>
        <div className="footer-social-links">
          <a
            href="https://www.facebook.com/Studenter-108603125100537"
            target="_blank"
            className="footer-social-link"
          >
            <img src={facebookSmall} alt="FB" />
          </a>
          <a
            href="https://www.instagram.com/_studenter_"
            target="_blank"
            className="footer-social-link"
          >
            <img src={instagramSmall} alt="INSTAGRAM" />
          </a>
          <a
            href=" https://www.linkedin.com/in/studenter-478b73233"
            target="_blank"
            className="footer-social-link"
          >
            <img src={linkedinSmall} alt="LINKEDIN" />
          </a>
        </div>
      </div>
    </div>
  );
}
