import React, { useState } from "react";

//anim
import { motion } from "framer-motion";
import { authVariants } from "../../Functions/Animations";
//components
import { pumaTypeOfParticipants } from "../../../constants/LocalData";
import { Link } from "react-router-dom";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
//hooks
import useAuth from "../../../hooks/useAuth";
import useAxiosPost from "../../../hooks/useAxiosPost";
import { MutatingDots } from "react-loader-spinner";
import SelectFieldNameValue from "../../Inputs/SelectFieldNameValue";
import InputField from "../../Inputs/InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const PumaForm = ({ id, slug }) => {
  const { user } = useAuth();
  const { response, error, isLoading, axiosPost } = useAxiosPost();
  const [participants, setParticipants] = useState([""]);

  const formik = useFormik({
    initialValues: {
      as_a: "",
      event_id: id,
      phone: "",
    },
    validationSchema: Yup.object({
      as_a: Yup.string().required("Obavezno polje"),
    }),
    onSubmit: (values) => {
      values.guests = participants.toString();

      axiosPost({
        url: `${process.env.REACT_APP_BASE_URL}/submission`,
        data: values,
      });
    },
  });
  const participantsHandler = (e, index) => {
    let newArr = [...participants];
    newArr[index] = e.target.value;
    setParticipants(newArr);
  };

  if (user)
    return (
      <>
        <div className="apply">
          <h2 className="apply-title">Prijava</h2>
          <span className="apply-text">
            Ukoliko ste zainteresovani da prisustvujete događaju popunite formu
            za prijavu
          </span>

          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={authVariants}
          >
            <form onSubmit={formik.handleSubmit} className="apply-form">
              <SelectFieldNameValue
                name="as_a"
                placeholder="--Prijavi se kao--"
                onChange={formik.handleChange}
                value={formik.values.as_a}
                list={pumaTypeOfParticipants}
                inputLabelClassName="white-input-label"
                label="UČESNIK"
                onBlur={formik.handleBlur}
                touched={formik.touched.as_a}
                errors={formik.errors.as_a}
                errorsClassName="white-input-label"
              />
              <InputField
                labelClassName="white-input-label"
                type="text"
                placeholder=""
                label="TELEFON"
                name="phone"
                onBlur={formik.handleBlur}
                touched={formik.touched.phone}
                onChange={formik.handleChange}
                errors={formik.errors.phone}
                value={formik.values.phone}
              />
              {participants.map((item, index) => (
                <InputField
                  key={index}
                  value={participants[index]}
                  onChange={(e) => participantsHandler(e, index)}
                  name={`guests[${index}]`}
                  label="IME I PREZIME"
                  labelClassName="white-input-label"
                />
              ))}
              {participants.length < 5 && (
                <button
                  onClick={() => setParticipants([...participants, ""])}
                  className="apply-add-more-btn"
                  type="button"
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                </button>
              )}

              <span className="auth-message">
                {isLoading && (
                  <MutatingDots secondaryColor="#1e2a3b" color="#c9d5fd" />
                )}
                {error?.message && error.message}
              </span>
              <span className="auth-message">
                {response?.message && response.message}
              </span>
              <motion.button
                whileHover={{ scale: 1.02 }}
                type="submit"
                className="apply-submit-btn"
              >
                PRIJAVI SE
              </motion.button>
              {(parseInt(process.env.REACT_APP_SUPER_ADMIN) === user.role ||
                parseInt(process.env.REACT_APP_ADMIN) === user.role) && (
                <a
                  className="apply-download-btn"
                  target="_blank"
                  download="filename"
                  href={`${process.env.REACT_APP_BASE_URL}/event-export/${slug}`}
                >
                  PREUZMI PRISTIGLE PRIJAVE
                </a>
              )}
            </form>
          </motion.div>
        </div>
      </>
    );
  return (
    <div className="apply">
      <h3 className="apply-text">
        Samo se registrovani korisnici mogu prijaviti za dogadjaj
      </h3>
      <Link to="/auth/fizicko-lice" className="apply-download-btn">
        Prijavi se
      </Link>
    </div>
  );
};

export default PumaForm;
