import React from "react";
import { NavLink } from "react-router-dom";

function NavigationLinkStyled(props) {
  return (
    <>
      <NavLink
        onClick={props.onClick ? props.onClick : ""}
        className={({ isActive }) =>
          isActive ? "nav-link-styled-active" : "nav-link-styled-inactive"
        }
        to={props.link}
      >
        {props.title}
      </NavLink>
    </>
  );
}

export default NavigationLinkStyled;
