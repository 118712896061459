import axios from "axios";
import { useQuery } from "react-query";

const useCompanyFetch = (slug) => {
  return useQuery([`companies/${slug}`], async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/companies/${slug}`
    );
    return res?.data?.data;
  });
};

export default useCompanyFetch;
