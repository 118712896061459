import React from "react";
import { useTranslation } from "react-i18next";
import LinkItem from "../Events/LinkItem";

const AuthNav = () => {
  const { t } = useTranslation();
  return (
    <nav className="auth-nav">
      <LinkItem
        className="nav-link"
        link="/auth/fizicko-lice"
        title={t("candidate")}
      />
      <LinkItem
        className="nav-link"
        link="/auth/pravno-lice"
        title={t("company")}
      />
    </nav>
  );
};

export default AuthNav;
