import React, { useEffect, useState } from "react";

//anim
import { motion } from "framer-motion";
import { authVariants } from "../../Functions/Animations";
//components
import {
  pumaTypeOfParticipants,
  yoursTypeOfParticipants,
} from "../../../constants/LocalData";
import { Link } from "react-router-dom";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
//hooks
import useAuth from "../../../hooks/useAuth";
import useAxiosPost from "../../../hooks/useAxiosPost";
import { MutatingDots } from "react-loader-spinner";
import SelectFieldNameValue from "../../Inputs/SelectFieldNameValue";
import ApplyOnlyButton from "./ApplyOnlyButton";

const type = [
  { id: 1, name: "Učesnik" },
  { id: 2, name: "Sponzor" },
];
const packageType = [
  {
    id: 1,
    name: "SPONZORSKI PAKET 1 (2 kotizacije, podela promo-materijala učesnicima Foruma UNAPRED)",
  },
  {
    id: 2,
    name: "SPONZORSKI PAKET 2 (panel učesnik/govornik, 3 kotizacije, podela promo-materijala učesnicima Foruma UNAPRED, roll+up u sali tokom celog Foruma UNAPRED)",
  },
  {
    id: 3,
    name: "SPONZORSKI PAKET 3 (štand, panel učesnik/govornik, 5 kotizacija, podela promo-materijala učesnicima Foruma UNAPRED, roll+up u sali tokom celog Foruma UNAPRED)",
  },
];
const UnapredForm = ({ id, slug, canDownload }) => {
  const [participantsArr, setParticipantsArr] = useState([]);
  useEffect(() => {
    let participantsArr = new Array(30).fill().map((e, i) => {
      return { id: i + 1, name: i + 1 };
    });
    setParticipantsArr(participantsArr);
  }, []);
  console.log(participantsArr);
  const { user } = useAuth();
  const { response, error, isLoading, axiosPost } = useAxiosPost();

  const formik = useFormik({
    initialValues: {
      as_a: "",
      guests: "",
      suggestions: "",
    },
    validationSchema: Yup.object({
      as_a: Yup.string().required("Obavezno polje"),
    }),

    onSubmit: (values) => {
      console.log(values);
      if (formik.as_a === "Učesnik") {
        let newValues = {
          event_id: id,
          as_a: values.as_a,
          guests: values.guests,
        };
        axiosPost({
          url: `${process.env.REACT_APP_BASE_URL}/submission`,
          data: newValues,
        });
      } else {
        let newValues = {
          event_id: id,
          as_a: values.as_a,
          suggestions: values.suggestions,
        };
        axiosPost({
          url: `${process.env.REACT_APP_BASE_URL}/submission`,
          data: newValues,
        });
      }
    },
  });

  if (user.role.toString() === process.env.REACT_APP_COMPANY)
    return (
      <>
        <div className="apply">
          <h2 className="apply-title">Prijava</h2>
          <span className="apply-text">
            Ukoliko ste zainteresovani da prisustvujete događaju popunite formu
            za prijavu
          </span>

          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={authVariants}
          >
            <form onSubmit={formik.handleSubmit} className="apply-form">
              <SelectFieldNameValue
                name="as_a"
                placeholder=""
                onChange={formik.handleChange}
                value={formik.values.as_a}
                list={type}
                inputLabelClassName="white-input-label"
                label="ODABERITE TIP"
                onBlur={formik.handleBlur}
                touched={formik.touched.as_a}
                errors={formik.errors.as_a}
                errorsClassName="white-input-label"
              />
              {formik.values.as_a === "Sponzor" ? (
                <SelectFieldNameValue
                  name="suggestions"
                  placeholder=""
                  onChange={formik.handleChange}
                  value={formik.values.suggestions}
                  list={packageType}
                  inputLabelClassName="white-input-label"
                  label="SPONZORSKI PAKET"
                  onBlur={formik.handleBlur}
                  touched={formik.touched.suggestions}
                  errors={formik.errors.suggestions}
                  errorsClassName="white-input-label"
                  inputClassName="select-field-namevalue-width"
                />
              ) : (
                <SelectFieldNameValue
                  name="guests"
                  placeholder=""
                  onChange={formik.handleChange}
                  value={formik.values.guests}
                  list={participantsArr}
                  inputLabelClassName="white-input-label"
                  label="BROJ UČESNIKA"
                  onBlur={formik.handleBlur}
                  touched={formik.touched.guests}
                  errors={formik.errors.guests}
                  errorsClassName="white-input-label"
                />
              )}

              <span className="auth-message">
                {isLoading && (
                  <MutatingDots secondaryColor="#1e2a3b" color="#c9d5fd" />
                )}
                {error?.message && error.message}
              </span>
              <span className="auth-message">
                {response?.message && response.message}
              </span>
              <motion.button
                whileHover={{ scale: 1.02 }}
                type="submit"
                className="apply-submit-btn"
              >
                PRIJAVI SE
              </motion.button>
              {canDownload ? (
                <a
                  className="apply-download-btn"
                  target="_blank"
                  download="filename"
                  href={`${process.env.REACT_APP_BASE_URL}/event-export/${slug}`}
                >
                  PREUZMI PRISTIGLE PRIJAVE
                </a>
              ) : (
                ""
              )}
            </form>
          </motion.div>
        </div>
      </>
    );
  if (user) return <ApplyOnlyButton id={id} slug={slug} />;
  return (
    <div className="apply">
      <h3 className="apply-text">
        Samo se registrovani korisnici mogu prijaviti za dogadjaj
      </h3>
      <Link to="/auth/fizicko-lice" className="apply-download-btn">
        Prijavi se
      </Link>
    </div>
  );
};

export default UnapredForm;
