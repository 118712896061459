import React from "react";
import handshake from "../../assets/videos/handshake.mp4";

const AuthVideo = () => {
  return (
    <video
      className="auth-video hide-for-mobile"
      autoPlay={window.innerWidth > 450 ? true : false}
      muted
      loop
    >
      <source src={handshake} type="video/mp4"></source>
    </video>
  );
};

export default AuthVideo;
