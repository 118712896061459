import React from "react";
//imgs
import git from "../../assets/socials/git.png";
import facebook from "../../assets/socials/facebook.png";
import instagram from "../../assets/socials/instagram.png";
import linkedin from "../../assets/socials/linkedin.png";
//hooks
import useAuth from "../../hooks/useAuth";
//components
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Contact = ({ id }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  return (
    <div className="user-profile-contact">
      {/* <div className="user-profile-links">
        <img src={git} alt="Github" className="user-profile-contact-icon" />
        <img
          src={facebook}
          alt="Facebook"
          className="user-profile-contact-icon"
        />
        <img
          src={linkedin}
          alt="Linkedin"
          className="user-profile-contact-icon"
        />
        <img
          src={instagram}
          alt="Instagram"
          className="user-profile-contact-icon"
        />
      </div> */}
      {parseInt(process.env.REACT_APP_STUDENT) !== user.role && (
        <Link
          to={
            parseInt(process.env.REACT_APP_COMPANY) === user.role
              ? `/pocetna/poruke/${id}`
              : "/auth/fizicko-lice"
          }
        >
          <button className="company-profile-contact-btn">
            {t("contact")}
          </button>
        </Link>
      )}
    </div>
  );
};

export default Contact;
