import axios from "axios";
import { useQuery } from "react-query";

const fetchFunction = async (slug) => {
    const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/ads-count`, {
        headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
        },
    });
    return data?.data
};

const useAdsCount = (slug) => {
    return useQuery([`adscount`], () => fetchFunction(slug));
};

export default useAdsCount;
