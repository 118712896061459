import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UserInfoBox = (props) => {
  return (
    <div className={`user-profile-box ${props.className}`}>
      {props.fontClass === "user-profile-box-icon-l" ? (
        <span>
          {props.data}
          <FontAwesomeIcon className={props.fontclass} icon={props.icon} />
        </span>
      ) : (
        <span>
          <FontAwesomeIcon className={props.fontclass} icon={props.icon} />
          {props.data}
        </span>
      )}
    </div>
  );
};

export default UserInfoBox;
