import React, { useEffect } from "react";
import Footer from "../Global/Footer";
import NavigationBar from "../NavBar/NavigationBar";
//animations
import { fadeIn } from "../../components/Functions/Animations";
import { motion } from "framer-motion";
import { Outlet } from "react-router";
import { Helmet } from "react-helmet-async";

const Events = () => {
  return (
    <motion.div
      animate="show"
      initial="hidden"
      variants={fadeIn}
      className="events"
    >
      <Helmet>
        <title>Studenter | Događaji</title>
        <meta
          name="description"
          content="Pogledajte sve predstojeće studentske događaje u Beogradu i regionu"
        />
        <link rel="canonical" href="/blog" />
      </Helmet>

      <NavigationBar navigationStyleBar="nav-absolute" />
      <Outlet />

      <Footer />
    </motion.div>
  );
};

export default Events;
