import axios from "axios";
import { useQuery } from "react-query";

const fetchFunction = async () => {
  const data = await axios.get(`${process.env.REACT_APP_BASE_URL}/packages`, {
    headers: {
      "Content-Type": "application/json",
      authorization: localStorage.getItem("token"),
    },
  });
  return data?.data
};

const usePackages = (slug) => {
  return useQuery([`packages`], () => fetchFunction());
};

export default usePackages;
