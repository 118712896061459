import React, { useState, useEffect } from "react";
//Components
import { SwitchLanguage } from "../../languages/components/SwitchLanguage";
import logoText from "../../assets/images/logo-text.png";
import { Link, useLocation } from "react-router-dom";
import HamburgerMenu from "./HamburgerMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import GuestList from "./ListItems/GuestList";
import { useTranslation } from "react-i18next";

function GuestNavBar({ navigationStyleBar }) {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setIsHamburgerOpen(false);
  }, [location]);

  return (
    <nav>
      <div className={navigationStyleBar}>
        <div className="nav-top-menu">
          <Link to="/pocetna-stranica">
            <img src={logoText} alt="STUDENTER" className="nav-logo" />
          </Link>
          <SwitchLanguage styleClass="hide-for-mobile" />
        </div>
        <div className="nav-bottom-menu  hide-for-mobile">
          <GuestList />

          <Link
            className="nav-login-btn hide-for-mobile"
            to="/auth/fizicko-lice/"
          >
            {t("nav_logIn")}
          </Link>
        </div>
      </div>

      <FontAwesomeIcon
        icon={isHamburgerOpen ? faTimes : faBars}
        size="2x"
        color="white"
        className="hamburger-btn hide-for-desktop"
        onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}
      />

      {isHamburgerOpen && <HamburgerMenu />}
    </nav>
  );
}

export default GuestNavBar;
