import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createContext } from "react";
import axios from "axios";
import { useLocation } from "react-router";
import i18n from "../languages/i18n";

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [loginMessage, setLoginMessage] = useState();
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();

  const auth = async () => {
    setIsLoading(true);
    let bearer = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/auth`,
        {
          headers: {
            authorization: bearer,
          },
        }
      );
      setUser(response.data);
      i18n.changeLanguage(response.data.user.lang);
      setIsLoading(false);
      return response;
    } catch (error) {
      setUser(false);
      setIsLoading(false);
      return false;
    }
  };

  useEffect(() => {
    auth();
  }, []);

  const logout = async (value) => {
    setIsLoading(true);
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/logout`,
        value,
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );

      localStorage.removeItem("token");
      setUser(false);
      auth()
        .then(() => {
          navigation("/auth/fizicko-lice");
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          localStorage.removeItem("token");
        });
    } catch (error) {
      console.log("Doslo je do greske", error.response);
      localStorage.removeItem("token");
    }
  };
  const location = useLocation();

  const login = async (values) => {
    setIsLoading(true);

    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/${
          location.pathname.includes("fizicko") ? "login" : "company-login"
        }`,
        values,
        headers
      );
      localStorage.setItem("token", `Bearer ${response.data.access_token}`);
      auth()
        .then(() => {
          setIsLoading(false);
          navigation("/o-nama");
        })
        .catch(() => {
          setIsLoading(false);
        });
    } catch (error) {
      setLoginMessage(error.response.data.email || error.response.data.message);
      setIsLoading(false);
    }
  };

  return (
    <UserContext.Provider
      value={{
        login,
        logout,
        user,
        setUser,
        loginMessage,
        auth,
        isLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
