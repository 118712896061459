import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CompanyGuestInfo = () => {
  const { t } = useTranslation();

  return (
    <div className="company-guest">
      <span className="guest-permission-text">
        <h2 style={{ fontSize: 22, fontWeight: 500 }}>{t('opportunityForCompanies')}</h2>
        <ul style={{ listStyleType: "disc", textAlign: "left", padding: 10 }}>
          <li> {t('freeReg')}</li>
          <li>
            {t('accessToBase')}
          </li>
        </ul>

        {t('searchCandidatesBy')}
        <ul style={{ listStyleType: "disc", textAlign: "left", padding: 10 }}>
          <li>      {t('byScienceField')}</li>
          <li>     {t('byLevelOfEdu')}</li>
          <li>     {t('bySkills')}</li>
        </ul>
        {t('companyGuestInfoDesc')}
        <br />
        {t('companyGuestInfoDesc2')}
        <br />

      </span>
      <Link className="company-guest-cta" to="/auth/pravno-lice/register">
        {t("registerCompany")}
      </Link>
    </div>
  );
};

export default CompanyGuestInfo;
