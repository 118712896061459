import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const PricingModelItem = (props) => {
    const { name, ads_count, setShowModal, id, price_rsd } = props

    const selectModelHandler = () => {
        setShowModal({ name: name, ads_count: ads_count, id: id })
    }

    return (
        <li className='pricing-item'>
            <span className='pricing-item-title' > {name} </span>
            <span className='pricing-item-subtitle' > {ads_count}  <span className='pricing-item-p-upper' > {ads_count === 1 ? 'Oglas' : 'Oglasa'} </span></span>
            <span className='pricing-item-title' > {price_rsd} RSD </span>
            <div className='separator-gray' />
            <ul>
                <li className='pricing-item-p' >
                    <FontAwesomeIcon
                        icon={faCheck}
                        color="#334155"
                    />  {ads_count} {ads_count === 1 ? 'oglas' : 'oglasa'} za posao ili praksu </li>
                <li className='pricing-item-p'> <FontAwesomeIcon
                    icon={faCheck}
                    color="#334155"
                /> Share na društvene mreže</li>
                <li className='pricing-item-p'> <FontAwesomeIcon
                    icon={faCheck}
                    color="#334155"
                /> Oglas sa slikom</li>

            </ul>
            <button onClick={() => selectModelHandler()} className='pricing-cta' >Odaberi paket</button>
        </li>
    )
}

export default PricingModelItem