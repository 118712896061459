import React, { useEffect } from "react";
import voidLogo from "../../assets/images/confirmedEmail.svg";
import { Link, useSearchParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ConfirmedEmail = () => {
  //params from api
  const { auth, user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams({});
  let type = searchParams.get("type" || "");
  let status = searchParams.get("status" || "");
  let token = searchParams.get("token" || "");
  const { t } = useTranslation();
  const navigation = useNavigate();

  useEffect(() => {
    localStorage.setItem("token", `Bearer ${token}`);
    if (token) {
      auth();
    }
  }, [token]);

  useEffect(async () => {
    user && navigation("/pocetna/profil");
  }, [user]);

  return (
    <div className="confirmed-email">
      <img className="confirmed-img" src={voidLogo} alt="404" />
      {status === "already_verified" && (
        <span className="confirmed-title">{t("already_verified")}</span>
      )}
      {status === "verified" && (
        <span className="confirmed-title">{t("email_verified")}</span>
      )}
      {status === "invalid_url" && (
        <span className="confirmed-title">{t("wrong_url")}</span>
      )}

      <Link
        to={type === "company" ? "/auth/pravno-lice" : "/auth/fizicko-lice"}
      >
        <button className="confirmed-btn">{t("nav_logIn")}</button>
      </Link>
    </div>
  );
};

export default ConfirmedEmail;
