import React, { useEffect } from "react";
import { useParams } from "react-router";
import axios from "../../../apis/axiosInstance";
import useAxiosFetch from "../../../hooks/useAxiosFetch";
import EmptyApplications from "./EmptyApplications";
import TransparentLoader from "../../Global/TransparentLoader";
import ErrorScreen from "../../Global/ErrorScreen";
import ProjectApplication from "./ProjectApplication";

const ProjectApplications = () => {
  const {
    response: applications,
    error,
    isLoading,
    axiosFetch,
  } = useAxiosFetch();
  const { slug } = useParams();

  const getApplications = async () => {
    await axiosFetch({
      axiosInstance: axios,
      method: "get",
      url: `/project-applications/${slug}`,
    });
  };

  useEffect(() => {
    getApplications();
  }, []);
  if (isLoading) return <TransparentLoader />;
  if (applications.length < 1) return <EmptyApplications />;
  if (error) return <ErrorScreen />;
  return (
    <div className="applications">
      {applications?.map((app) => (
        <ProjectApplication key={app.id} app={app} />
      ))}
    </div>
  );
};

export default ProjectApplications;
