import axios from "axios";
import { useQuery } from "react-query";

const fetchFunction = (slug) => {
  return axios.get(`${process.env.REACT_APP_BASE_URL}/events/${slug}`);
};

const useEventFetch = (slug) => {
  return useQuery([`events/${slug}`], () => fetchFunction(slug));
};

export default useEventFetch;
