import React, { useEffect, useState } from "react";
//components
import WhiteInput from "../Inputs/WhiteInput";
//router
import { Link, useParams } from "react-router-dom";
//logo
import logo from "../../assets/images/logo.png";
import { motion } from "framer-motion";
import { moveLeft } from "../Functions/Animations";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { MutatingDots } from "react-loader-spinner";
import AuthNav from "./AuthNav";
import useAxiosPost from "../../hooks/useAxiosPost";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import i18n from "../../languages/i18n";
import ReactGA from "react-ga4";

const Register = () => {
  const [checked, setChecked] = useState(false);
  const [privacyCheckedErr, setPrivacyCheckedErr] = useState(false);
  const { t } = useTranslation();
  const { type } = useParams();
  const { response, error, isLoading, axiosPost } = useAxiosPost();

  const registrationHandler = (values) => {
    values.lang = i18n.language;
    axiosPost({
      axiosInstance: axios,
      url: `${process.env.REACT_APP_BASE_URL}/${
        type === "fizicko-lice" ? `register` : `company-register`
      }`,
      data: values,
    });
  };

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING);
    if (response?.message && window) {
      window.fbq("track", "CompleteRegistration");
      window.fbq("track", "Lead");
      // window.gtag("event", "register", {
      //   method: "Google",
      // });
      // ReactGA.event("register");
      ReactGA.event({
        category: "register",
        action: "register",
        label: "register", // optional
      });
    }
  }, [response]);
  const resendMail = (values) => {
    axiosPost({
      axiosInstance: axios,
      url: `${process.env.REACT_APP_BASE_URL}/${
        type === "fizicko-lice" ? "email/resend" : "company-email/resend"
      }`,
      data: values,
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("required_field")),
      email: Yup.string().email().required(t("required_field")),
      password_confirmation: Yup.string()
        .required(t("required_field"))
        .min(8, t("validation_password")),
      password: Yup.string()
        .required(t("required_field"))
        .min(8, t("validation_password")),
    }),
    onSubmit: (values) => {
      if (checked) {
        setPrivacyCheckedErr(false);
        registrationHandler(values);
      } else {
        setPrivacyCheckedErr(true);
      }
    },
  });

  return (
    <motion.div
      variants={moveLeft}
      animate="show"
      initial="hidden"
      className="auth-sign"
    >
      <Helmet>
        <title>{t("register")}</title>
        <meta
          name="description"
          content="Registrujte se na našiu platformu kako bi pregledali celu bazu dostupnih poslova i studenata"
        />
        <link
          rel="canonical"
          href={"/auth/fizicko-lice/register" || "/auth/fizicko-lice/register"}
        />
      </Helmet>
      <img className="auth-sign-logo" src={logo} alt="logo" />
      <AuthNav />
      <form className="auth-sign-form">
        <WhiteInput
          className="input-padding"
          type="text"
          placeholder=""
          label={
            type === "fizicko-lice" ? t("name_surname") : t("company_name")
          }
          name="name"
          onBlur={formik.handleBlur}
          touched={formik.touched.name}
          onChange={formik.handleChange}
          errors={formik.errors.name}
          value={formik.values.name}
        />
        <WhiteInput
          className="input-padding"
          type="email"
          placeholder=""
          label="Email"
          name="email"
          onBlur={formik.handleBlur}
          touched={formik.touched.email}
          onChange={formik.handleChange}
          errors={formik.errors.email}
          value={formik.values.email}
        />

        <WhiteInput
          className="input-padding"
          type="password"
          placeholder=""
          label={t("password")}
          name="password"
          onBlur={formik.handleBlur}
          touched={formik.touched.password}
          onChange={formik.handleChange}
          errors={formik.errors.password}
          value={formik.values.password}
          isPasswordVisible={true}
        />
        <WhiteInput
          className="input-padding"
          type="password"
          placeholder=""
          label={t("confirm_password")}
          name="password_confirmation"
          onBlur={formik.handleBlur}
          touched={formik.touched.password_confirmation}
          onChange={formik.handleChange}
          errors={formik.errors.password_confirmation}
          value={formik.values.password_confirmation}
          isPasswordVisible={true}
        />
      </form>
      <div className="auth-terms">
        <input
          type="checkbox"
          onChange={() => setChecked(!checked)}
          value={checked}
        />
        <label className="auth-sign-text">
          {t("register_agree")}
          <a
            className="auth-sign-link"
            href="/politika_privatnosti_studenter.pdf"
            target="_blank"
          >
            {t("terms_and_conditions")}
          </a>
          {privacyCheckedErr && (
            <p className="auth-sign-text">{t("must_agree_with_tac")}</p>
          )}
        </label>
      </div>

      <span className="auth-sign-text">
        {t("already_have_acc")}
        <Link to={`/auth/${type}`} className="auth-sign-link">
          {t("login")}
        </Link>
      </span>
      {error && (
        <span className="auth-message">{error?.response?.data?.message}</span>
      )}
      {response?.message && (
        <span className="auth-message">{response.message}</span>
      )}

      {isLoading && <MutatingDots color="#c9d5fd" secondaryColor="#1e2a3b" />}
      {response?.message && (
        <button
          onClick={() => resendMail(formik.values)}
          type="button"
          className="auth-sign-btn"
        >
          {t("register_send_mail_again")}
        </button>
      )}
      {!response?.message && (
        <button
          onClick={() => formik.handleSubmit()}
          type="button"
          className="auth-sign-btn"
        >
          {t("register")}
        </button>
      )}
    </motion.div>
  );
};

export default Register;
