import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";

const axiosPost = async (values) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}/projects/create`,
    values,
    {
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    }
  );
};

const useNewProjectOffer = () => {
  const navigation = useNavigate();
  const queryClient = useQueryClient();
  return useMutation(
    async (values) => {
      return axiosPost(values);
    },
    {
      onSuccess: () => {
        navigation("/pocetna/moji-oglasi/projekti");
        queryClient.invalidateQueries([`projects`]);
      },
    }
  );
};

export default useNewProjectOffer;
