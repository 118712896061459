import React from "react";
import { motion } from "framer-motion";
import { moveLeft, moveRight } from "../Functions/Animations";

function Banner(props) {
  let subtitle = props.h3;
  function createMarkup() {
    return { __html: subtitle };
  }
  return (
    <div className="banner">
      <div id="overlay"></div>
      <img src={props.img} alt="events" className="banner-img" />
      <div className="banner-titles absolute-center">
        <motion.h1
          animate="show"
          initial="hidden"
          variants={moveLeft}
          className="banner-h1 "
        >
          {props.h1}
        </motion.h1>
        <motion.h3
          animate="show"
          initial="hidden"
          variants={moveRight}
          className="banner-h3"
          dangerouslySetInnerHTML={createMarkup()}
        ></motion.h3>
        <motion.span
          animate="show"
          initial="hidden"
          variants={moveLeft}
          className="banner-span"
        >
          {props.span}
        </motion.span>
      </div>
    </div>
  );
}

export default Banner;
