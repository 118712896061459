import React from "react";
//components
import NavigationBar from "../NavBar/NavigationBar";
//router
import { Outlet } from "react-router-dom";

const Home = () => {
  return (
    <>
      <div className="home">
        <NavigationBar navigationStyleBar="nav" />
        <div className="home-element">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Home;
