export const TRANSLATIONS_EN = {
  about_studenter:
    "Following the model of other European universities, that have developed the practice of cooperation between young people and the economy, and thanks to the initiative of the Student Association of Faculty of Mechanical Engineering, the StudENTER platform has been developed.<br> Projectland, the company for software and engineering design, gave the technical support in platform development.<br> StudENTER is the platform for networking between young people and economy. <br>  The platform, for the one part offers young people the chance for fast and easy placement and valorization of their gained theoretical and practical knowledge and to get involved in real economic flows. On the other hand, it offers companies the unique chance to access the base of educated and perspective young people and to satisfy their needs for project/research/examination participation, or simply find a new colleague.<br> Register today, it's free.",
  events_title: "Events",
  events_subtitle: "All upcoming events",
  events_apply: "APPLY",
  events_apply_text: "You need to be logged in to apply",
  nav_home: "Home",
  nav_events: "Events",
  nav_blog: "Business News",
  nav_aboutUs: "About Us",
  nav_forum: "Forum",
  nav_companies: "Companies",
  nav_offers: "Offers",
  nav_messages: "Messages",
  nav_students: "Students",
  candidate: "Candidate",
  candidates: "Candidates",
  nav_logIn: "Login",
  nav_logOut: "Logout",
  blog_title: "News",
  aboutus_title: "Looking for job? Enter Studenter",
  filter_projects_title: "Filter projects",
  filter_students_title: "Filter candidates",
  filter_jobs_title: "Filter jobs",
  filter_companies_title: "Filter Companies",
  filter_search: "Search",
  filter_country: "Country",
  filter_city: "City",
  filter_field: "Field",
  until: "Available until:",
  filter_reset: "Reset filters",
  filter_country_placeholder: "--select country--",
  filter_city_placeholder: "--select city--",
  filter_field_placeholder: "--select field--",
  filter_level_placeholder: "--select level--",
  filter_type: "type",
  filter_type_placeholder: "--select type--",
  filter_sort: "sort",
  select: "--select--",
  filter_sort_placeholder: "--sort by--",
  filter_scienceField: "Science field",
  filter_scienceSubField: "Science subfield",
  filter_levelOfEducation: "Level of education",
  filter_year_placeholder: "--select year--",
  yes: "YES",
  no: "NO",
  login: "Login",
  register: "Register",
  registerCompany: "Create company profile",
  email: 'Email',
  password: "Password",
  confirm_password: "Confirm Password",
  forgot_password: "Forgot Password?",
  filter_withPrice: "WITH PRICE",
  filter_withSalary: "WITH SALARY",
  filter_withDeadline: "WITH DEADLINE",
  offer_jobs: "JOBS",
  offer_projects: "PROJECTS",
  projects: "Projects",
  jobs: "Jobs",
  student: "Student",
  company: "Company",
  name: "Name",
  name_offer: "Job name",
  type: "type",
  sallary_number: "Salary",
  price: "Price",
  deadline: "Deadline",
  addFiles: "Add files",
  no_offers: "No offers available",
  error: "Sorry, an error occurred",
  apply: "Apply",
  show_details: "Show details",
  company_page: "Company page",
  contact: "Contact",
  offer_apply: "Apply",
  offer_applied: "Already applied",
  offer_companyDetails: "Show company details",
  offer_apply_description:
    "If you would like to apply for a project / job, we advise you to fill in your StudENTER profile, this way the StudENTER platform will generate a CV and forward it to the employer, if you have your CV you can also add it on the My Profile page.",
  offer_ads_description:
    "If you would like to place an offer like this for free, follow the following links. Job / internship ads are available, as well as job ads for freelance projects.",
  error_screen_text: "An error occurred, we are fixing it right now",
  login_noAccount: "Don't have an account yet? ",
  login_guest: "Enter as guest",
  name_surname: "Name and Surname",
  register_agree: "I agree with ",
  terms_and_conditions: "Terms & conditions",
  must_agree_with_tac: "You need to agree with terms & conditions",
  already_have_acc: "Already have account? ",
  company_name: "Company name",
  register_send_mail_again: "Resend verification mail",
  note: "note",
  send: 'Send',
  note_not_required: "Note (not required)",
  my_profile: "My profile",
  my_offers: "My ads",
  my_applications: "My applications",
  create_job_offer: "Create job offer",
  create_project_offer: "Create project offer",
  create_offer: "Create offer",
  about_me: "ABOUT ME",
  download: "Download ",
  messages: "Messages",
  nothing_here: "There is nothing here..",
  no_messagesText:
    "Your inbox is empty, you can start conversation on User/Company profiles, by clicking on contact button",
  my_jobs: "MY JOBS",
  my_projects: "MY PROJECTS",
  delete: "Delete",
  are_you_sure: "Are you sure?",
  show_applications: "Show all applications",
  edit: "Edit",
  basic_info: "Basic info",
  field: "Field",
  adress: "Adress",
  city: "City",
  country: "Country",
  phone: "Phone",
  about_company: "About company",
  company_info: "Company info",
  old_password: "Old PAssword",
  new_password: "new password",
  save: "Save",
  date_of_birth: "Date of birth",
  my_informations: "My info",
  add: "add",
  formal_education: "Formal education",
  faculty_name: "Faculty name",
  finished: "Finished",
  in_progress: "In progress",
  acquired_title: "Acquired title",
  non_formal_education: "non formal education",
  knowledge: "Knowledge",
  skills: "Skills",
  competitions: "Competitions",
  certificate: "Certificate",
  references: "References",
  organization: "Organization",
  position: "position",
  date_from: "Date from:",
  date_to: "Date to:",
  description: "Description",
  photo: "Photo",
  year_study: "Year of study",
  bachelors_academic_degree: "Bachelor's academic degreee",
  masters_academic_degree: "Master's academic studies",
  doctors_academic_degree: "Doctoral academic studies",
  specialist_academic_degree: 'Specialist academic studies',
  bachelors_vocational_degree: "Bachelor's vocational studies",
  specialist_vocational_degree: "Specialist vocational studies",
  masters_vocational_degree: "Master's vocational studies",



  first_year: "First year",
  second_year: "Second year",
  third_year: "Third year",
  fourth_year: "Fourth year",
  fifth_year: "Fifth year",
  sixth_year: "Sixth year",
  show_more: "Show more",
  required_field: "Required field",
  validation_name: "Name can be up to 100 characters long",
  validation_email: "Email can be up to 100 characters long",
  validation_adress: 'Adress can be up to 70 characters long"',
  validation_phone: "Phone can be up to 20 characters long",
  validation_city: "City can be up to 30 characters long",
  validation_website: "Name can be up to 60 characters long",
  validation_password: "Password must be at least 8 characters long.",
  add_photo: "Add Photo",
  reset_password: "Reset password",
  change_password: "Change password",
  back: "Back",
  discussions: "Discussions",
  discussion_name: "Discussion name",
  discussion_desc: "Discussion description",
  discussion_create: "Create discussion",
  no_discussions: "No discussions..",
  no_messages: "No messages..",
  discussion_comment_message: "You need to be logged in as candidate",
  my_comment: "My comment",
  comment: "Submit Comment",
  create_offer_freelance_msg: "If you want freelance project ad",
  create_offer_job_msg: "If You want to create job/internship ad",
  click_here: "Click here",
  duration: "Duration",
  offer_duration: "Offer duration",
  submit: "Submit",
  budget: "budget",
  new_offer: "New Offer",
  already_verified: "EMAIL ALREADY VERIFIED",
  email_verified: "EMAIL VERIFIED",
  wrong_url: "WRONG URL",
  unauthorized_page: "You need to be logged in to see this page",
  free: "Free",
  itsFree: "It's Free",
  load_more: "Load More",
  notifications: "Notifications",
  noNotifications: "No notifications",
  deactivateAcc: "Deactivate Account",
  errContact: "There was an issue, please contact office@studenter.org",
  deactivateAccText:
    "*If you login in 30 next days account will be renewed, if not account will be permanently deleted*",
  question: "Question",
  questionForCandidates: "Questions for candidates:",
  addQuestion: "Add question",
  removeQuestion: "Remove question",
  answersChance:
    "By answering the company's questions, you increase the chance of being invited to an interview",
  availableAd: "Available ad placements",
  pricingTitle: 'Elevate Your Hiring Experience - Explore Our Packages!',
  pricingModels: 'Discover Our Pricing Options',
  downloadOurApp: 'Download our app!',
  weAreAvailableOn: 'We are available on Playstore and Appstore',
  becomePartOfStudenter: 'BECOME PART OF THE StudENTER COMMUNITY',
  freeReg: 'Free registration',
  directContactWithEmployers: 'Direct contact with employers',
  becomePartDescription1: "Create a profile like a CV - apply with one click on an ad or be at the top of the search for employers when they need a candidate just like you - with your education and skills. You can find ads for: practice, job, projects, mentoring programs.",
  becomePartDescription2: 'Get benefits and discounts on conferences, seminars, and trainings as a member of the StudENTER community. Join over 3000 young educated candidates from the region. Connect with colleagues from other faculties and with entrepreneurs'
  , beOnEnterFromWork: 'Be on ENTER from work',
  opportunityForCompanies: 'OPPORTUNITY FOR COMPANIES',
  accessToBase: 'ACCESS A DATABASE OF OVER 3000 YOUNG EDUCATED PROMISING CANDIDATES',
  searchCandidatesBy: "Search candidates by:",
  byScienceField: 'Scientific field:',
  byLevelOfEdu: 'Level of education',
  bySkills: 'Skills'
  ,
  companyGuestInfoDesc:
    "Post your job / internship / project listing, announce mentorship programs. We're happy to promote your conferences and seminars too"
  ,
  companyGuestInfoDesc2: 'Create a company profile and be in focus for the youth building their career paths.'
};
