import React from "react";
import useAxiosPost from "../../../hooks/useAxiosPost";
import axios from "../../../apis/axiosInstance";
import { motion } from "framer-motion";
import { movePrompt } from "../../Functions/Animations";
import { useTranslation } from "react-i18next";

const DeletePrompt = (props) => {
  const { response, error, isLoading, axiosPost: deleteOffer } = useAxiosPost();
  const { t } = useTranslation();
  const deleteHandler = async (values) => {
    await deleteOffer({
      axiosInstance: axios,
      url: `${process.env.REACT_APP_BASE_URL}/${props.route}/delete/${props.slug}`,
    });
    if (response) {
      window.location.reload(false);
    }
  };
  if (error) return <span>Greska</span>;
  return (
    <motion.div
      initial="hidden"
      animate="show"
      variants={movePrompt}
      className="collapsible"
    >
      <span>{t("are_you_sure")}</span>
      <div className="space-between">
        <button onClick={() => deleteHandler()} className="prompt-btn">
          {t("yes")}
        </button>
        <button
          className="prompt-btn"
          onClick={() => props.setDeleteHandler(false)}
        >
          {t("no")}
        </button>
      </div>
    </motion.div>
  );
};

export default DeletePrompt;
