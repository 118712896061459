import React, { useEffect } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useParams } from "react-router";
import { Helmet } from "react-helmet-async";
import useCompanyFetch from "../../hooks/companies/useCompanyFetch";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import CompanyJobOffers from "../../components/Companies/CompanyProfile/CompanyJobOffers";
import { motion } from "framer-motion";
import {
  fadeInModern,
  moveRight,
  popup,
} from "../../components/Functions/Animations";

const CompanyPage = () => {
  const companySlug = useParams();
  const { data: company, error, isLoading } = useCompanyFetch(companySlug?.id);
  const { user } = useAuth();
  const { t } = useTranslation();
  function createMarkup() {
    return { __html: company?.description };
  }
  if (isLoading) return null;
  return (
    <motion.div
      variants={fadeInModern}
      initial="hidden"
      animate="show"
      className="company-profile"
    >
      <Helmet>
        <title>{company?.data?.data?.name}</title>
        <link rel="canonical" href="/pocetna/firme" />
      </Helmet>
      <div className="company-info">
        <div className="company-info-left">
          <div className="company-info-header align-items-center">
            <img className="company-info-logo" src={company?.image} />
            <div className="flexCol flex-space-between gap-8">
              <span className="company-info-title">{company?.name}</span>
              <VerifiedIcon style={{ color: "#1976D2" }} />
              <div className="flexRow gap-8 flexWrap">
                <span className="offer-filter-btn-location">
                  {company?.city?.name}
                </span>
                <span className="offer-filter-btn-career">
                  {company?.careerField?.name}
                </span>
              </div>
            </div>
          </div>
          <Divider />
          <div className="flexCol gap-16 pt-20">
            <span className="company-info-sub"> {t("nav_aboutUs")}</span>
            <span
              className="company-info-desc"
              dangerouslySetInnerHTML={createMarkup()}
            />
          </div>
        </div>
        <Divider />
        <div className="company-info-right">
          {company?.address && (
            <div className="flexCol gap-8">
              <span className="company-info-sub"> {t("adress")}</span>
              <span className="company-info-data-text">{company?.address}</span>
            </div>
          )}

          {company?.phone && (
            <div className="flexCol gap-8">
              <span className="company-info-sub"> {t("phone")}</span>
              <span className="company-info-data-text">{company?.phone}</span>
            </div>
          )}

          <div className="flexCol gap-8">
            <span className="company-info-sub">Email</span>
            <span className="company-info-data-text">{company?.email}</span>
          </div>
          {company?.website && (
            <div className="flexCol gap-8">
              <span className="company-info-sub">Website</span>
              <span className="company-info-data-text">
                <a
                  href={
                    company?.website?.includes("http")
                      ? company?.website
                      : `https://${company?.website}`
                  }
                  target="_blank"
                >
                  {" "}
                  {company?.website}
                </a>
              </span>
            </div>
          )}
          <Divider />
          {parseInt(process.env.REACT_APP_COMPANY) !== user.role && (
            <Link
              to={
                parseInt(process.env.REACT_APP_SUPER_ADMIN) === user.role ||
                parseInt(process.env.REACT_APP_STUDENT) === user.role ||
                parseInt(process.env.REACT_APP_ADMIN) === user.role
                  ? `/pocetna/poruke/${company?.id}`
                  : "/auth/fizicko-lice"
              }
            >
              <button className="company-info-contact">{t("contact")}</button>
            </Link>
          )}
        </div>
      </div>

      <CompanyJobOffers slug={company?.slug} />
    </motion.div>
  );
};

export default CompanyPage;
