import React, { useState, useEffect } from "react";
//components
import InputField from "../../Inputs/InputField";
import { ProjectOfferValidation } from "../../../constants/validations/ProjectOfferValidation";
import axios from "../../../apis/axiosInstance";
import TextEditor from "../../Global/TextEditor";
import { useNavigate } from "react-router-dom";
import TextAreaInput from "../../Inputs/TextAreaInput";
import {
  type,
  deadline,
  deadlineEng,
  duration,
  durationInternship,
  typePaying,
} from "../../../constants/LocalData";
import SelectField from "../../Inputs/SelectField";
//hooks
import useListsFetch from "../../../hooks/useListsFetch";
import useAxiosPost from "../../../hooks/useAxiosPost";
import { useFormik } from "formik";
import { MutatingDots } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../../languages/i18n";
import useNewProjectOffer from "../../../hooks/offers/useNewProjectOffer";

const CreateProjectOffer = () => {
  const [editorText, setEditorText] = useState("");

  const { t } = useTranslation();
  const { fields } = useListsFetch(["career-fields"]);

  const {
    mutate: createProject,
    isLoading,
    error,
    data: response,
  } = useNewProjectOffer();

  const formik = useFormik({
    initialValues: {
      title: "", //nazib
      description: editorText, //opis
      salary: "", //iznos plate
      note: "", //napomena
      deadline: "", //koliko je validan oglas
      project_deadline: "",
      career_field_id: "",
    },
    validationSchema: ProjectOfferValidation,
    onSubmit: (values) => {
      values.description = editorText;
      createProject(values);
    },
  });
  const navigation = useNavigate();
  useEffect(() => {
    if (response?.message === "Oglas kreiran") {
      setTimeout(() => {
        navigation("/pocetna/moji-oglasi/projekti");
      }, 1000);
    }
  }, [response]);

  return (
    <div className="create-offer">
      <div className="create-offer-title-wrapper">
        <h1 className="create-offer-title">
          {formik.values.title ? formik.values.title : t("new_offer")}
        </h1>
        <span className="create-offer-text">
          {t("create_offer_job_msg")} &nbsp;
          <Link to="/pocetna/kreiraj-oglas" className="link">
            {t("click_here")}
          </Link>
        </span>
      </div>
      <form className="create-offer-form" onSubmit={formik.handleSubmit}>
        <div className="inline mobile-column">
          <InputField
            type="text"
            placeholder=""
            label={`${t("name_offer")} *`}
            name="title"
            onBlur={formik.handleBlur}
            touched={formik.touched.title}
            errors={formik.errors.title}
            onChange={formik.handleChange}
            value={formik.values.title}
          />
          <SelectField
            type="select"
            placeholder={t("select")}
            label={`${t("type")} *`}
            name="career_field_id"
            list={fields}
            onBlur={formik.handleBlur}
            touched={formik.touched.career_field_id}
            errors={formik.errors.career_field_id}
            onChange={formik.handleChange}
            value={formik.values.career_field_id}
          />
        </div>

        <div className="inline mobile-column">
          <InputField
            type="text"
            placeholder=""
            label={t("deadline")}
            name="project_deadline"
            onBlur={formik.handleBlur}
            touched={formik.touched.project_deadline}
            errors={formik.errors.project_deadline}
            onChange={formik.handleChange}
            value={formik.values.project_deadline}
          />
          <InputField
            type="text"
            label={t("budget")}
            name="salary"
            onBlur={formik.handleBlur}
            touched={formik.touched.salary}
            errors={formik.errors.salary}
            onChange={formik.handleChange}
            value={formik.values.salary}
          />
        </div>
        <div className="inline mobile-column">
          <SelectField
            type="select"
            placeholder={t("select")}
            label={`${t("offer_duration")} *`}
            name="deadline"
            list={i18n.language === "en" ? deadlineEng : deadline}
            onBlur={formik.handleBlur}
            touched={formik.touched.deadline}
            errors={formik.errors.deadline}
            onChange={formik.handleChange}
            value={formik.values.deadline}
          />
        </div>
        <div>
          <label className="input-label">{t("description")} *</label>
          <TextEditor setEditorText={setEditorText} />
        </div>

        <TextAreaInput
          type="text"
          placeholder=""
          label={t("note")}
          name="note"
          onBlur={formik.handleBlur}
          touched={formik.touched.note}
          errors={formik.errors.note}
          onChange={formik.handleChange}
          value={formik.values.note}
        />
        {isLoading && <MutatingDots color="#c9d5fd" secondaryColor="#1b587b" />}
        {error && (
          <span className="changes-message">
            {error?.response?.data?.message}
          </span>
        )}
        {response && (
          <span className="changes-message">{response?.message}</span>
        )}
        <button
          type="button"
          onClick={() => formik.handleSubmit()}
          className="form-submit"
        >
          {t("submit")}
        </button>
      </form>
    </div>
  );
};

export default CreateProjectOffer;
