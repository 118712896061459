import React from "react";
import { Link } from "react-router-dom";

const JobApplication = ({ app }) => {
  return (
    <Link className="application" to={`/pocetna/korisnici/${app?.user?.id}`}>
      <div className="flexRow flex-space-between width-100 align-items-center mobile-column">
        <img src={app?.user?.image} alt="IMG" className="application-img" />
        <span className="application-title">{app.user?.name}</span>
        <span className="application-text-light">{app?.user?.email}</span>
        <span className="application-text-light">{app?.created_at}</span>
      </div>
      {app?.question_answer?.map((i) => (
        <div className="flexCol gap-8">
          <span className="application-question">{i?.question}</span>
          <span className="application-answer">{i?.answer}</span>
        </div>
      ))}
    </Link>
  );
};

export default JobApplication;
