import React from "react";
import { Link } from "react-router-dom";

const Unauthorized = () => {
  return (
    <div className="forum-create">
      <span className="forum-create-title">Započni konverzaciju</span>
      <span className="forum-create-title">Morate biti registrovani</span>
      <Link className="forum-login-btn" to="/auth/fizicko-lice/register">
        <button>Registruj se</button>
      </Link>
      <Link className="forum-login-btn" to="/auth/fizicko-lice/">
        <button>Prijavi se</button>
      </Link>
    </div>
  );
};

export default Unauthorized;
