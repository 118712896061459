import React, { useState } from "react";
//components
import InputField from "../Inputs/InputField";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../apis/axiosInstance";
//hooks
import useAxiosPost from "../../hooks/useAxiosPost";
import { useTranslation } from "react-i18next";

const CompanyPassword = () => {
  const { response, error, isLoading, axiosPost } = useAxiosPost();
  const { t } = useTranslation();
  const passwordChangeHandler = async (values) => {
    axiosPost({
      axiosInstance: axios,
      url: `${process.env.REACT_APP_BASE_URL}/company-update-password`,
      data: values,
    });
  };

  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required(t("required_field")),
      password: Yup.string().required(t("required_field")),
      password_confirmation: Yup.string().required(t("required_field")),
    }),
    onSubmit: (values) => {
      passwordChangeHandler(values);
    },
  });
  return (
    <div className="usersettings-item">
      <span className="usersettings-item-title">{t("password")}</span>
      <form onSubmit={formik.handleSubmit} className="usersettings-form">
        <div className="inline-inputs-desktop">
          <InputField
            className="input-padding"
            type="password"
            placeholder=""
            label={t("old_password")}
            name="old_password"
            onBlur={formik.handleBlur}
            touched={formik.touched.old_password}
            onChange={formik.handleChange}
            errors={formik.errors.old_password}
            value={formik.values.old_password}
            isPasswordVisible={true}
          ></InputField>

          <InputField
            className="input-padding"
            type="password"
            placeholder=""
            label={t("new_password")}
            name="password"
            onBlur={formik.handleBlur}
            touched={formik.touched.password}
            onChange={formik.handleChange}
            errors={formik.errors.password}
            value={formik.values.password}
            isPasswordVisible={true}
          />

          <InputField
            className="input-padding"
            type="password"
            isPasswordVisible={true}
            placeholder=""
            label={t("confirm_password")}
            name="password_confirmation"
            onBlur={formik.handleBlur}
            touched={formik.touched.password_confirmation}
            onChange={formik.handleChange}
            errors={formik.errors.password_confirmation}
            value={formik.values.password_confirmation}
          />
        </div>
        <div className="usersettings-messages">
          <span className="usersettings-message ">
            {error && "Lozinke se ne podudaraju"}
          </span>
          <span className="usersettings-message ">
            {response && response.message}
          </span>
        </div>
        <div className="form-submit-wrapper input-padding">
          <button type="submit" className="form-submit">
            {t("save")}
          </button>
        </div>
      </form>
    </div>
  );
};
export default CompanyPassword;
