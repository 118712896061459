import React from "react";
//components
import Modal from "react-modal";
//functions
import useNoScroll from "../../hooks/useNoScroll";
//motion
import { motion } from "framer-motion";
import { moveLeft } from "../Functions/Animations";
//assets
import x from "../../assets/icons/x.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    maxWidth: "655px",
    overflow: "hidden",
  },
};

function ImagePreviewModal(props) {
  function closeModal() {
    props.setIsImageOpened(false);
  }

  let blockScroll = useNoScroll();
  blockScroll();
  return (
    <div>
      <Modal
        isOpen={props.isImageOpened}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <motion.div
          className="image-wrapper"
          animate="show"
          initial="hidden"
          exit="exit"
          variants={moveLeft}
        >
          <button
            className="modal-exit-btn"
            onClick={() => props.setIsImageOpened(false)}
          >
            <img className="modal-exit-icon" alt="X" src={x} />
          </button>
          <img
            src={props.modalImage}
            alt="Foto"
            className="modal-imagepreview-img"
          />
        </motion.div>
      </Modal>
    </div>
  );
}

export default ImagePreviewModal;
