import {
  faArrowCircleLeft,
  faClock,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import ProjectApply from "../../components/Offers/Projects/ProjectApply";
import ErrorScreen from "../../components/Global/ErrorScreen";
import CompanyCardInfo from "../../components/Offers/Global/CompanyCardInfo";
import useAuth from "../../hooks/useAuth";
import CompanyApply from "../../components/Offers/Global/CompanyApply";
import GuestApply from "../../components/Offers/Global/GuestApply";
import AlreadyApplied from "../../components/Offers/Global/AlreadyApllied";
import { Helmet } from "react-helmet-async";
import useProjectFetch from "../../hooks/offers/useProjectFetch";
import OfferLoader from "../../components/Offers/Global/OfferLoader";

const ProjectPage = () => {
  const params = useParams();
  const { user } = useAuth();
  const { data: project, isLoading, error } = useProjectFetch(params.slug);

  function createMarkup() {
    return { __html: project?.data?.data?.description };
  }
  const navigate = useNavigate();
  if (isLoading) return <OfferLoader />;
  if (error) return <ErrorScreen />;
  if (project)
    return (
      <div className="offer-page">
        <Helmet>
          <title>{project?.data?.data?.title}</title>
          <meta name="description" content="Freelance projekat" />
          <link rel="canonical" href="/pocetna/oglasi/projekti" />
        </Helmet>
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          className="offer-page-back-btn"
          onClick={() => navigate(-1)}
        />
        <div className="offer-page-details">
          <div className="inline space-between">
            <img
              alt="logo"
              className="offer-page-logo"
              src={`https://studenter.org/${project?.data?.data?.company?.logo}`}
            />
            <span className="offer-page-light-text">
              <FontAwesomeIcon icon={faClock} />
              &nbsp;{project?.data?.data?.expiry_date}
            </span>
          </div>
          <h2 className="offer-page-title">{project?.data?.data?.title}</h2>
          <div className="inline">
            {project?.data?.data?.career_field?.name && (
              <span className="offer-page-type violet-background">
                {project?.data?.data?.career_field?.name}
              </span>
            )}
            {project?.data?.data?.duration && (
              <span className="offer-page-type purple-background">
                {project?.data?.data?.duration}
              </span>
            )}
            {project?.data?.data?.salary && (
              <span className="offer-page-type brown-background">
                {project?.data?.data?.salary}
              </span>
            )}
          </div>
          {project?.data?.data?.city && (
            <span className="offer-page-light-text">
              <FontAwesomeIcon icon={faLocationArrow} />
              &nbsp;{project?.data?.data?.city?.name}
            </span>
          )}
          <article
            className="offer-page-description"
            dangerouslySetInnerHTML={createMarkup()}
          />
        </div>
        <div className="offer-page-right">
          {project?.data?.data?.canApply === false &&
            user?.role === parseInt(process.env.REACT_APP_STUDENT) && (
              <AlreadyApplied />
            )}
          {project?.data?.data?.canApply === true &&
            user?.role === parseInt(process.env.REACT_APP_STUDENT) && (
              <ProjectApply slug={project?.data?.data?.slug} />
            )}

          {user?.role === parseInt(process.env.REACT_APP_COMPANY) && (
            <CompanyApply />
          )}
          {user?.role === parseInt(process.env.REACT_APP_ADMIN) && (
            <GuestApply />
          )}
          {user?.role === parseInt(process.env.REACT_APP_SUPER_ADMIN) && (
            <GuestApply />
          )}
          {!user && <GuestApply />}

          <CompanyCardInfo slug={project?.slug} company={project?.data?.data} />
        </div>
      </div>
    );
};

export default ProjectPage;
