import React from "react";

import NavigationBar from "../NavBar/NavigationBar";
//animations
import { fadeIn, moveLeft } from "../../components/Functions/Animations";
import { motion } from "framer-motion";
import { Outlet } from "react-router";
import Footer from "../Global/Footer";

const BlogPage = () => {
  return (
    <motion.div
      animate="show"
      initial="hidden"
      variants={fadeIn}
      className="blog-wrapper"
    >
      <NavigationBar navigationStyleBar="nav-absolute" />

      <Outlet />
      <Footer />
    </motion.div>
  );
};

export default BlogPage;
