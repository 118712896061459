import axios from "axios";
import { useQuery } from "react-query";
import i18n from "../../languages/i18n";

const fetchFunction = () => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/career-fields?lang=${i18n.language}`
  );
};

const useCareerFields = () =>
  useQuery(["career-fields", i18n.language], fetchFunction);
export default useCareerFields;
