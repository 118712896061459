import React from "react";
//logo
import logo from "../../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
//components
import WhiteInput from "../Inputs/WhiteInput";
import AuthNav from "./AuthNav";
// motion
import { motion } from "framer-motion";
import { moveLeft } from "../Functions/Animations";
//form
import { useFormik } from "formik";
import * as Yup from "yup";
//router
import { Link, useParams } from "react-router-dom";
//seo
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import googlePlay from "../../assets/socials/playstore.png";
import useAuth from "../../hooks/useAuth";
import appStore from "../../assets/socials/appstore.png";

const Login = () => {
  const { login, loginMessage } = useAuth();
  const { t } = useTranslation();
  const { type } = useParams();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(t("required_field")),
      password: Yup.string().required(t("required_field")),
    }),
    onSubmit: (values) => {
      login(values);
    },
  });

  return (
    <motion.div
      variants={moveLeft}
      animate="show"
      initial="hidden"
      className="auth-sign"
    >
      <Helmet>
        <title>{t("login")}</title>
        <meta
          name="description"
          content="Prijavite se na našiu platformu kako bi pregledali celu bazu dostupnih poslova i studenata"
        />
        <link
          rel="canonical"
          href={"/auth/fizicko-lice" || "/auth/pravno lice"}
        />
      </Helmet>
      <img className="auth-sign-logo" src={logo} alt="logo" />
      <AuthNav />
      <WhiteInput
        label={t("email")}
        type="email"
        placeholder=""
        name="email"
        onBlur={formik.handleBlur}
        touched={formik.touched.email}
        onChange={formik.handleChange}
        errors={formik.errors.email}
        value={formik.values.email}
      />
      <WhiteInput
        label={t("password")}
        type="password"
        placeholder=""
        name="password"
        onBlur={formik.handleBlur}
        touched={formik.touched.password}
        onChange={formik.handleChange}
        errors={formik.errors.password}
        value={formik.values.password}
        isPasswordVisible={true}
      />
      <Link to={`/auth/${type}/reset-password-confirmation`}>
        <span className="auth-sign-forget">{t("forgot_password")}</span>
      </Link>
      <span className="auth-sign-text">
        {t("login_noAccount")}           <Link to={`/auth/${type}/register`} className="auth-sign-link">
          {t("register")}, {t("itsFree")}
        </Link>
      </span>
      <span className="auth-err-msg">{loginMessage}</span>

      <button
        onClick={() => formik.handleSubmit()}
        type="button"
        className="auth-sign-btn"
      >
        {t("login")}
      </button>

      <Link to="/pocetna-stranica" className="auth-nav-btn">
        <span>{t("login_guest")} </span>
        <FontAwesomeIcon icon={faArrowCircleRight} />
      </Link>
      <div className="auth-stores">
        <a
          href="https://play.google.com/store/apps/details?id=com.studenter.app&hl=en&gl=US"
          target="_blank"
          className="auth-google-play-icon-link"
          rel="noreferrer"
        >
          <img
            src={googlePlay}
            alt="Googleplay"
            className="auth-google-play-icon"
          />
        </a>
        <a
          href="https://apps.apple.com/hr/app/studenter/id1639547864"
          target="_blank"
          className="auth-google-play-icon-link"
          rel="noreferrer"
        >
          <img
            src={appStore}
            alt="AppStore"
            className="auth-google-play-icon"
          />
        </a>
      </div>
    </motion.div>
  );
};

export default Login;
