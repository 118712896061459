import { useEffect } from "react";
import useAxiosFetch from "./useAxiosFetch";
import axios from "../apis/axiosInstance";

const useListsFetch = (lists) => {
  const { response: countries, axiosFetch: fetchCountries } = useAxiosFetch();
  const { response: cities, axiosFetch: fetchCities } = useAxiosFetch();
  const { response: fields, axiosFetch: fetchFields } = useAxiosFetch();
  const { response: academicFields, axiosFetch: fetchAcademicFields } =
    useAxiosFetch();
  const { response: academicSubFields, axiosFetch: fetchAcademicSubFields } =
    useAxiosFetch();

  const getAllLists = async () => {
    if (lists.includes("cities")) {
      await fetchCities({
        axiosInstance: axios,
        method: "get",
        url: `/cities`,
      });
    }
    if (lists.includes("countries")) {
      await fetchCountries({
        axiosInstance: axios,
        method: "get",
        url: `/countries`,
      });
    }
    if (lists.includes("career-fields")) {
      await fetchFields({
        axiosInstance: axios,
        method: "get",
        url: `/career-fields`,
      });
    }
    if (lists.includes("academic-fields")) {
      await fetchAcademicFields({
        axiosInstance: axios,
        method: "get",
        url: `/academic-fields`,
      });
    }
    if (lists.includes("academic-subfields")) {
      await fetchAcademicSubFields({
        axiosInstance: axios,
        method: "get",
        url: `/academic-subfields`,
      });
    }
  };
  useEffect(() => {
    let mounted = true;
    mounted && getAllLists();
    return () => {
      mounted = false;
    };
  }, []);

  return {
    countries,
    cities,
    fields,
    academicFields,
    academicSubFields,
    getAllLists,
  };
};

export default useListsFetch;
