import React, { useState, useEffect } from "react";
//components
import InputField from "../../Inputs/InputField";

import TextEditor from "../../Global/TextEditor";
import SelectFieldNameValue from "../../Inputs/SelectFieldNameValue";
import TextAreaInput from "../../Inputs/TextAreaInput";
import { MutatingDots } from "react-loader-spinner";
import {
  type,
  deadline,
  duration,
  durationInternship,
  typePaying,
} from "../../../constants/LocalData";
import SelectField from "../../Inputs/SelectField";
import { EditJobOfferValidation } from "../../../constants/validations/EditJobOfferValidation";
//hooks
import useListsFetch from "../../../hooks/useListsFetch";
import useAxiosPost from "../../../hooks/useAxiosPost";
import useAxiosFetch from "../../../hooks/useAxiosFetch";
import axios from "../../../apis/axiosInstance";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";

const EditJobOffer = () => {
  const [editorText, setEditorText] = useState("");
  const { response, error, isLoading, axiosPost } = useAxiosPost();

  const { cities, countries, fields } = useListsFetch(
    "cities , countries , career-fields"
  );

  const { slug } = useParams();
  const {
    response: job,
    error: jobError,
    isLoading: jobIsLoading,
    axiosFetch,
  } = useAxiosFetch();

  const getJob = async () => {
    await axiosFetch({
      axiosInstance: axios,
      method: "get",
      url: `/jobs/${slug}`,
    });
  };

  useEffect(() => {
    getJob();
  }, []);

  useEffect(() => {
    setEditorText(job?.description ? job?.description : "");
  }, [job]);

  const postJobOffer = async (values) => {
    let data = {
      city_id: values.city_id,
      description: editorText,
      duration: values.duration,
      note: values.note,
      salary: values.salary,
      title: values.title,
      type: values.type,
      country_id: values.country_id,
      career_field_id: values.career_field_id,
    };

    await axiosPost({
      axiosInstance: axios,
      url: `${process.env.REACT_APP_BASE_URL}/jobs/update/${slug}`,
      data: data,
    });
  };

  const formik = useFormik({
    enableReinitialize: job ? true : false,
    initialValues: {
      title: job.title || "", //nazib
      salary: job.salary || "", //iznos plate
      type: job.type || "", //praksa ili zaposlenje
      note: job.note || "", //napomena
      duration: job.duration || "", //trajanje posla ili prakse
      city_id: job.city?.id || "",
      career_field_id: job.career_field?.id || "",
      country_id: job.country?.id || "",
    },
    validationSchema: EditJobOfferValidation,

    onSubmit: (values) => {
      postJobOffer(values);
    },
  });
  if (jobIsLoading) return null;
  if (jobError) return <h1>Doslo je do greske</h1>;
  return (
    <div className="create-offer">
      <div className="create-offer-title-wrapper">
        <h1 className="create-offer-title">
          {formik.values.title ? formik.values.title : "Novi oglas"}
        </h1>
      </div>
      <form className="create-offer-form" onSubmit={formik.handleSubmit}>
        <div className="inline mobile-column">
          <InputField
            type="text"
            placeholder=""
            label="NAZIV OGLASA"
            name="title"
            onBlur={formik.handleBlur}
            touched={formik.touched.title}
            errors={formik.errors.title}
            onChange={formik.handleChange}
            value={formik.values.title}
          />
          <SelectFieldNameValue
            type="select"
            placeholder=""
            label="TIP ANGAŽMANA"
            name="type"
            list={type}
            onBlur={formik.handleBlur}
            touched={formik.touched.type}
            errors={formik.errors.type}
            onChange={formik.handleChange}
            value={formik.values.type}
          />
          {formik.values.type === "Praksa" ? (
            <SelectFieldNameValue
              type="select"
              placeholder=""
              label="PLAĆANJE"
              name="salary"
              list={typePaying}
              onBlur={formik.handleBlur}
              touched={formik.touched.salary}
              errors={formik.errors.salary}
              onChange={formik.handleChange}
              value={formik.values.salary}
            />
          ) : (
            <InputField
              type="text"
              placeholder=""
              label="VISINA ZARADE"
              name="salary"
              onBlur={formik.handleBlur}
              touched={formik.touched.salary}
              errors={formik.errors.salary}
              onChange={formik.handleChange}
              value={formik.values.salary}
            />
          )}
        </div>
        <div className="inline mobile-column">
          {formik.values.type === "1" ? (
            <SelectFieldNameValue
              type="select"
              placeholder=""
              label="TRAJANJE ANGAŽMANA"
              name="duration"
              list={durationInternship}
              onBlur={formik.handleBlur}
              touched={formik.touched.duration}
              errors={formik.errors.duration}
              onChange={formik.handleChange}
              value={formik.values.duration}
            />
          ) : (
            <SelectFieldNameValue
              type="select"
              placeholder=""
              label="TRAJANJE ANGAŽMANA"
              name="duration"
              list={duration}
              onBlur={formik.handleBlur}
              touched={formik.touched.duration}
              errors={formik.errors.duration}
              onChange={formik.handleChange}
              value={formik.values.duration}
            />
          )}
        </div>
        <div className="inline mobile-column">
          <SelectField
            type="select"
            placeholder=""
            label="POLJE"
            name="career_field_id"
            list={fields && fields}
            onBlur={formik.handleBlur}
            touched={formik.touched.career_field_id}
            errors={formik.errors.career_field_id}
            onChange={formik.handleChange}
            value={formik.values.career_field_id}
          />
          <SelectField
            type="select"
            placeholder=""
            label="ZEMLJA"
            name="country_id"
            list={countries && countries}
            onBlur={formik.handleBlur}
            touched={formik.touched.country_id}
            errors={formik.errors.country_id}
            onChange={formik.handleChange}
            value={formik.values.country_id}
          />
          <SelectField
            type="select"
            placeholder=""
            label="GRAD"
            name="city_id"
            list={
              cities &&
              cities.filter(
                (filtered) => filtered.country_id == formik.values.country_id
              )
            }
            onBlur={formik.handleBlur}
            touched={formik.touched.city_id}
            errors={formik.errors.city_id}
            onChange={formik.handleChange}
            value={formik.values.city_id}
          />
        </div>
        <div>
          <label className="input-label">OPIS</label>
          <TextEditor
            value={job.description ? job?.description : ""}
            setEditorText={setEditorText}
          />
        </div>

        <TextAreaInput
          type="text"
          placeholder=""
          label="NAPOMENA"
          name="note"
          onBlur={formik.handleBlur}
          touched={formik.touched.note}
          errors={formik.errors.note}
          onChange={formik.handleChange}
          value={formik.values.note}
        />
        {isLoading && <MutatingDots color="#c9d5fd" secondaryColor="#1b587b" />}
        {error && (
          <span className="changes-message">
            {error?.response?.data?.message}
          </span>
        )}
        {response && (
          <span className="changes-message">{response?.message}</span>
        )}
        <button
          type="button"
          onClick={() => formik.handleSubmit()}
          className="form-submit"
        >
          IZMENI OGLAS
        </button>
      </form>
    </div>
  );
};

export default EditJobOffer;
