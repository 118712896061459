import React, { useState } from "react";
import ErrorScreen from "../../components/Global/ErrorScreen";
import FilterJobs from "../../components/Offers/Jobs/FilterJobs";
import { useSearchParams } from "react-router-dom";
import NoData from "../../components/Global/NoData";
import GooglePlay from "../../components/Global/GooglePlay";
import { useInfiniteQuery } from "react-query";
import OfferCardHorizontal from "../../components/Offers/Global/OfferCardHorizontal";
import useDebounce from "../../hooks/useDebounce";
import { useTranslation } from "react-i18next";
import useScrollPosition from "../../hooks/useScrollPosition";
import Rocket from "../../components/Global/Rocket";
import AdsCountAndPricing from "../../components/Offers/Jobs/AdsCountAndPricing";
import useAuth from "../../hooks/useAuth";

const JobsPage = () => {
  const [searchParams] = useSearchParams();
  const [isPaid, setIsPaid] = useState(0);
  const { user } = useAuth();
  const { t } = useTranslation();

  const fetchInfiniteJobs = async ({ pageParam = 1, queryKey }) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL
      }/jobs?page=${pageParam}&search=${searchParams.get("query") ? searchParams.get("query") : ""
      }&city=${searchParams.get("city") ? searchParams.get("city") : ""}
    &type=${searchParams.get("type") ? searchParams.get("type") : ""}&sort=${searchParams.get("sort") ? searchParams.get("sort") : ""
      }&salary=${searchParams.get("salary") ? searchParams.get("salary") : ""
      }&career_field=${searchParams.get("career_field") ? searchParams.get("career_field") : ""
      }`);
    if (!response.ok) {
      throw new Error("Something went wrong!");
    }
    return response.json();
  };
  const debounced = useDebounce(searchParams.get("query"), 500);
  const arr = [
    searchParams.get("career_field"),
    searchParams.get("city"),
    searchParams.get("sort"),
    searchParams.get("type"),
    searchParams.get("salary"),
  ];
  const {
    data: jobs,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    error,
  } = useInfiniteQuery(["jobs", [debounced, arr]], fetchInfiniteJobs, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
        return lastPage?.meta?.current_page + 1;
      return false;
    },
  });

  if (error) return <ErrorScreen />;
  return (
    <div className="offers">
      <div className="width-100 offers-all">
        {jobs?.pages[0]?.data?.length < 1 && isLoading === false && <Rocket />}

        {jobs?.pages[0]?.data?.length > 0 && (
          <div className="cards-horizontal">
            {jobs?.pages?.map((page) =>
              page?.data?.map((job) => (
                <OfferCardHorizontal {...job} key={job.id} />
              ))
            )}
            {hasNextPage && (
              <button className="offers-load-more-btn" onClick={fetchNextPage}>
                {t("load_more")}
              </button>
            )}
          </div>
        )}
      </div>
      <div className="offers-filtering">
        {user?.role === parseInt(process.env.REACT_APP_COMPANY) && <AdsCountAndPricing />}

        <FilterJobs isPaid={isPaid} setIsPaid={setIsPaid} />
      </div>
    </div>
  );
};

export default JobsPage;
