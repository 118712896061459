import React, { useEffect, useState } from "react";
import axios from "../../apis/axiosInstance";
//anim
import { motion } from "framer-motion";
import { authVariants } from "../Functions/Animations";
//components
import RadioField from "../Inputs/RadioField";
import WhiteInput from "../Inputs/WhiteInput";
import { Link } from "react-router-dom";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
//submission_open
//hooks
import useAuth from "../../hooks/useAuth";
import useAxiosPost from "../../hooks/useAxiosPost";
import TransparentLoader from "../Global/TransparentLoader";
import { MutatingDots } from "react-loader-spinner";
import SelectField from "../Inputs/SelectField";
import SelectFieldNameValue from "../Inputs/SelectFieldNameValue";
import { sizes } from "../../constants/LocalData";

const Apply = ({ id, slug }) => {
  const { user } = useAuth();
  const { response, error, isLoading, axiosPost } = useAxiosPost();

  const formik = useFormik({
    initialValues: {
      phone: "",
      transcript_number: "",
      attended_before: false,
      event_id: id,
    },
    validationSchema: Yup.object({
      phone: Yup.string().required("Obavezno polje"),
      transcript_number: Yup.string().required("Obavezno polje"),
      attended_before: Yup.string().required("Obavezno polje"),
    }),
    onSubmit: (values) => {
      axiosPost({
        url: `${process.env.REACT_APP_BASE_URL}/submission`,
        data: values,
      });
    },
  });

  if (
    parseInt(process.env.REACT_APP_STUDENT) === user.role ||
    parseInt(process.env.REACT_APP_SUPER_ADMIN) === user.role ||
    parseInt(process.env.REACT_APP_ADMIN) === user.role
  )
    return (
      <>
        <div className="apply">
          <h2 className="apply-title">Prijava</h2>
          <span className="apply-text">
            Ukoliko ste zainteresovani da prisustvujete događaju popunite formu
            za prijavu
          </span>

          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={authVariants}
          >
            <form onSubmit={formik.handleSubmit} className="apply-form">
              <WhiteInput
                className="input-padding"
                type="text"
                placeholder=""
                label="BROJ TELEFONA"
                name="phone"
                onBlur={formik.handleBlur}
                touched={formik.touched.phone}
                onChange={formik.handleChange}
                errors={formik.errors.phone}
                value={formik.values.phone}
              />

              <WhiteInput
                className="input-padding"
                type="text"
                placeholder=""
                label="BROJ INDEKSA"
                name="transcript_number"
                onBlur={formik.handleBlur}
                touched={formik.touched.transcript_number}
                onChange={formik.handleChange}
                errors={formik.errors.transcript_number}
                value={formik.values.transcript_number}
              />
              {id === 4 && (
                <SelectFieldNameValue
                  type="select"
                  placeholder=""
                  label="VELIČINA MAJICE"
                  name="suggestions"
                  list={sizes}
                  onBlur={formik.handleBlur}
                  touched={formik.touched.suggestions}
                  errors={formik.errors.suggestions}
                  onChange={formik.handleChange}
                  value={formik.values.suggestions}
                  inputClassName="whitebackground"
                  inputLabelClassName="white-input-label"
                />
              )}

              {id != 4 && (
                <RadioField
                  className=""
                  title="POZNAVANJE PROGRAMA"
                  value1="1"
                  value1name="DA"
                  value2="0"
                  value2name="NE"
                  name="attended_before"
                  labelClassname="white-input-label"
                  onBlur={formik.handleBlur}
                  touched={formik.touched.attended_before}
                  onChange={formik.handleChange}
                  errors={formik.errors.attended_before}
                  value={formik.values.attended_before}
                />
              )}
              <span className="auth-message">
                {isLoading && (
                  <MutatingDots secondaryColor="#1e2a3b" color="#c9d5fd" />
                )}
                {error?.message && error.message}
              </span>
              <span className="auth-message">
                {response?.message && response.message}
              </span>
              <motion.button
                whileHover={{ scale: 1.02 }}
                type="submit"
                className="apply-submit-btn"
              >
                PRIJAVI SE
              </motion.button>
              {(parseInt(process.env.REACT_APP_SUPER_ADMIN) === user.role ||
                parseInt(process.env.REACT_APP_ADMIN) === user.role) && (
                <a
                  className="apply-download-btn"
                  target="_blank"
                  download="filename"
                  href={`${process.env.REACT_APP_BASE_URL}/event-export/${slug}`}
                >
                  PREUZMI PRISTIGLE PRIJAVE
                </a>
              )}
            </form>
          </motion.div>
        </div>
      </>
    );
  return (
    <div className="apply">
      <h3 className="apply-text">
        Samo se registrovani korisnici mogu prijaviti za dogadjaj
      </h3>
      <Link to="/auth/fizicko-lice" className="apply-download-btn">
        Prijavi se
      </Link>
    </div>
  );
};

export default Apply;
