import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function ImageSlider(props) {
  return (
    <Carousel
      className="carousel"
      swipeable={true}
      showIndicators={true}
      showArrows={true}
    >
      {props.slides?.map((slide, index) => (
        <div key={index} className="carousel-img-wrapper">
          <img
            className="carousel-img-wrapper"
            alt={`slider${index}`}
            src={`https://studenter.org/${slide.path}`}
          />
        </div>
      ))}
    </Carousel>
  );
}

export default ImageSlider;
