import React from "react";

import { Helmet } from "react-helmet-async";
import NavigationBar from "../../components/NavBar/NavigationBar";
import { useTranslation } from "react-i18next";
import MainSlider from "../../components/Landing/MainSlider";
import DownloadApp from "../../components/Landing/DownloadApp";
import Events from "../../components/Landing/Events";
import Footer from "../../components/Landing/Footer";

const LandingPage = () => {
  const { t } = useTranslation();

  return (
    <div className="landing">
      <NavigationBar navigationStyleBar="nav-absolute" />
      <Helmet>
        <title>{t("aboutus_title")}</title>
        <meta
          name="description"
          content="Po ugledu na druge evropske Univerzitete, a na inicijativu Saveza studenata Mašinskog fakulteta, razvijena je platforma StudENTER."
        />
        <link rel="canonical" href="/o-nama" />
      </Helmet>

      <div className="landing-content">
        <div className="flexCol gap-32">
          <div
            style={{
              paddingTop: 20,
            }}
          >
            <MainSlider />
          </div>

          {/* <BrowseJobs /> */}
          <div className="flexRow mobile-column gap-32 pt-20 ">
            <DownloadApp />

            <Events />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
