import React from "react";
import Modal from "react-modal";
import logo from '../../assets/images/logo-blue.png'
import { useTranslation } from "react-i18next";
import { faMailBulk, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import linkedinSmall from "../../assets/socials/linkedinSmall.png";
import instagramSmall from "../../assets/socials/instagramSmall.png";
import { useFormik } from "formik";
import SelectField from "../Inputs/SelectField";
import ClassicInput from "../Inputs/ClassicInput";
import { currenies } from "../../constants/LocalData";
import usePreInvoice from "../../hooks/offers/usePreInvoice";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "10px",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        maxWidth: '50vw',
        minHeight: '40vh',
        justifyContent: 'space-between',
        flexDirection: 'row',
        border: 0,
        opacity: 1,
        transition: 'opacity 0.5s ease-in-out',
        gap: '32px'

    },
    overlay: { zIndex: 1000, background: 'rgba(137, 134, 130, 0.55)', opacity: 1, transition: 'opacity 0.5s ease-in-out' }, // Also add transition to overlay
};

const GenerateProformaInvoice = (props) => {

    const { mutate: createInvoice, isLoading, isError } = usePreInvoice()
    function closeModal() {
        props.setShowModal(false);
    }

    const { t } = useTranslation()

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            currency: 'rsd',
            package_id: props?.showModal?.id,
            pib: ''
        },
    });
    return (
        <Modal
            isOpen={props.showModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Pricing models"
            ariaHideApp={false}
        >

            <div className="proformal-modal-contact">
                <img src={logo} className="proformal-modal-logo" />
                <div className="separator-gray" />
                <div className="flexCol gap-8">
                    <span className="proformal-modal-text-m" >{t('needBiggerPlan')}</span>
                    <span className="proformal-modal-text-s">{t('contactUs')}</span>
                    <span className="proformal-modal-text-s"> <FontAwesomeIcon icon={faMailBulk} color="grey" /> &nbsp; office@studenter.org</span>
                    <a href="tel:+38162247796" className="proformal-modal-text-s"> <FontAwesomeIcon icon={faPhoneAlt} color="grey" /> &nbsp;+381 62 247 796</a>
                </div>

                <div className="separator-gray" />
                <div className="flexCol gap-8">
                    <span className="proformal-modal-text-m" >{t('visitOurSocials')}</span>

                    <div className="flexRow gap-16">
                        <a
                            href="https://www.instagram.com/_studenter_"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={instagramSmall} alt="INSTAGRAM" className="proformal-modal-social" />
                        </a>
                        <a
                            href="https://www.linkedin.com/company/studenter-serbia/"
                            target="_blank"

                            rel="noreferrer"
                        >
                            <img src={linkedinSmall} alt="LINKEDIN" className="proformal-modal-social" />
                        </a>
                    </div>

                </div>
            </div>
            <div className="proformal-modal-form">
                <span className="proformal-modal-title" >{t('preformal')} - {props?.showModal?.name} PLAN</span>
                <form className="gap-16 flexCol">
                    <ClassicInput
                        label='PIB'
                        name="pib"
                        placeholder='PIB'
                        onChange={formik.handleChange}
                        value={formik.values.pib}
                    />
                    <SelectField
                        label={t('currency')}
                        name="currency"
                        placeholder={t('currency')}
                        onChange={formik.handleChange}
                        value={formik.values.currency}
                        list={currenies}


                    /></form>


                <button disabled={isLoading} onClick={() => createInvoice(formik.values)} className='pricing-cta' >Generiši predračun</button>


            </div>


        </Modal >
    );
}

export default GenerateProformaInvoice;
