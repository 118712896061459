import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router";

const Companies = () => {
  return (
    <div>
      <Helmet>
        <title> Kompanije</title>
        <meta
          name="description"
          content="Pogledajte sve kompanije koje se nalaze na našoj platformi"
        />
        <link rel="canonical" href="/pocetna/firme" />
      </Helmet>
      <Outlet />
    </div>
  );
};

export default Companies;
