import React, { useState } from "react";
//components
import InputField from "../Inputs/InputField";
import SelectField from "../Inputs/SelectField";
import ImagePreviewModal from "../Global/ImagePreviewModal";

//formik
import { useFormik, Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "../../apis/axiosInstance";
//icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
//hooks
import useListsFetch from "../../hooks/useListsFetch";
import useAxiosFormData from "../../hooks/useAxiosFormData";
import TextEditor from "../Global/TextEditor";
import { useTranslation } from "react-i18next";

const UserInfo = ({ profile }) => {
  const [image, setImage] = useState();
  const [isImageOpened, setIsImageOpened] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [editorText, setEditorText] = useState("");
  const { t } = useTranslation();
  const { cities } = useListsFetch("cities");
  const { countries } = useListsFetch("countries");

  const modalImageHandler = (img) => {
    setIsImageOpened(true);
    setModalImage(img);
  };
  const [response, error, isLoading, axiosFetch] = useAxiosFormData();

  const infoChangeHandler = async (values) => {
    const formData = new FormData();
    image && formData.append("image", image);
    formData.append("email", values.email);
    formData.append("name", values.name);
    formData.append("phone", values.phone);
    formData.append("date_of_birth", values.date_of_birth);
    formData.append(
      "description",
      editorText ? editorText : profile?.description ? profile.description : ""
    );
    formData.append("city", values.city);
    formData.append("country", values.country);

    axiosFetch({
      axiosInstance: axios,
      method: "post",
      url: `/update-generals`,
      formData,
    });
  };

  const formik = useFormik({
    enableReinitialize: profile ? true : false,
    initialValues: {
      name: profile?.name || "",
      email: profile?.email || "",
      phone: profile?.phone || "",
      image: profile?.image || "",
      date_of_birth: profile?.date_of_birth || "",
      city: profile?.city?.id || "",
      country: profile?.country?.id || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(30, t("validation_name"))
        .required(t("required_field")),
      email: Yup.string()
        .email()
        .max(50, t("validation_email"))
        .required(t("required_field")),
      phone: Yup.string().min(9, t("validation_phone")),
    }),
    onSubmit: (values) => {
      infoChangeHandler(values);
    },
  });

  return (
    <div className="usersettings-item">
      <span className="usersettings-item-title">{t("basic_info")}</span>
      <Formik initialValues={formik.initialValues}>
        <Form encType="multipart/form-data" className="usersettings-form">
          <div className="usersettings-profile-pic-wrapper">
            <label className="input-label">{t("photo")}</label>
            <div className="usersettings-profileimg">
              {formik.values.image && (
                <img
                  alt="img-prof"
                  className="usersettings-profileimg"
                  src={!image ? profile.image : URL.createObjectURL(image)}
                  onClick={() => modalImageHandler(profile.image)}
                />
              )}
            </div>
            {isImageOpened !== false && (
              <ImagePreviewModal
                setIsImageOpened={setIsImageOpened}
                isImageOpened={isImageOpened}
                modalImage={modalImage}
              />
            )}
            <label htmlFor="files">
              {formik.values.image ? (
                <FontAwesomeIcon className="pointer" icon={faEdit} />
              ) : (
                <span className="input-add-label">{t("add_photo")}</span>
              )}
            </label>
            <input
              name="image"
              type="file"
              id="files"
              accept="image/png, image/jpeg"
              className="display-none"
              onChange={(event) => {
                setImage(event.target.files[0]);
              }}
            />
          </div>
          <div className="inline-inputs-desktop">
            <InputField
              className="input-padding"
              type="text"
              placeholder=""
              name="name"
              onBlur={formik.handleBlur}
              touched={formik.touched.name}
              onChange={formik.handleChange}
              errors={formik.errors.name}
              value={formik.values.name}
              label={t("name_surname")}
            />
            <InputField
              className="input-padding"
              type="email"
              placeholder=""
              label="EMAIL"
              name="email"
              onBlur={formik.handleBlur}
              touched={formik.touched.email}
              errors={formik.errors.email}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </div>
          <div className="inline-inputs-desktop">
            <SelectField
              label={t("city")}
              name="city"
              className="input-padding"
              placeholder={t("filter_city_placeholder")}
              onBlur={formik.handleBlur}
              touched={formik.touched.city}
              errors={formik.errors.city}
              onChange={formik.handleChange}
              value={formik.values.city}
              list={cities?.filter(
                (filteredCity) =>
                  filteredCity.country_id == formik.values.country
              )}
            />
            <SelectField
              label={t("country")}
              name="country"
              className="input-padding"
              placeholder={t("filter_country_placeholder")}
              onBlur={formik.handleBlur}
              touched={formik.touched.country}
              errors={formik.errors.country}
              onChange={formik.handleChange}
              value={formik.values.country}
              list={countries}
            />
          </div>
          <div className="inline-inputs-desktop">
            <InputField
              className="input-padding"
              type="phone"
              placeholder=""
              label={t("phone")}
              name="phone"
              onBlur={formik.handleBlur}
              touched={formik.touched.phone}
              errors={formik.errors.phone}
              onChange={formik.handleChange}
              value={formik.values.phone}
            />
            <InputField
              className="input-padding"
              type="date"
              placeholder=""
              label={t("date_of_birth")}
              name="date_of_birth"
              onBlur={formik.handleBlur}
              touched={formik.touched.date_of_birth}
              errors={formik.errors.date_of_birth}
              onChange={formik.handleChange}
              value={formik.values.date_of_birth}
            />
          </div>

          <div className="input-padding ">
            <span className="input-label">{t("about_me")}</span>
            <TextEditor
              value={profile?.description}
              setEditorText={setEditorText}
            />
          </div>

          <div className="input-padding"></div>

          <span className="changes-message">{error && t("error")}</span>
          <span className="changes-message input-padding">
            {response && response}
          </span>

          <div className="form-submit-wrapper input-padding">
            <button
              type="button"
              className="form-submit"
              onClick={() => formik.handleSubmit()}
            >
              {t("save")}
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default UserInfo;
