import React from "react";
import googlePlay from "../../assets/socials/playstore.png";
import appStore from "../../assets/socials/appstore.png";
const GooglePlay = () => {
  return (
    <div className="">
      <a
        href="https://play.google.com/store/apps/details?id=com.studenter.app&hl=en&gl=US"
        target="_blank"
        rel="noreferrer"
      >
        <img src={googlePlay} alt="Googleplay" className="about-us-google" />
      </a>
      <a
        href="https://apps.apple.com/hr/app/studenter/id1639547864"
        target="_blank"
        rel="noreferrer"
      >
        <img className="about-us-google" src={appStore} alt="PlayStore" />
      </a>
    </div>
  );
};

export default GooglePlay;
