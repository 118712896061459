import { useTranslation } from "react-i18next";
import "../../../languages/i18n";
import { Link } from "react-router-dom";

import React from "react";

const StudentDropDownItems = () => {
  const { t } = useTranslation();

  return (
    <>
      <li>
        <Link className="dropdown-item" to="/pocetna/profil">
          {t("my_profile")}
        </Link>
      </li>
      <li>
        <Link className="dropdown-item" to="/pocetna/mojeprijave">
          {t("my_applications")}
        </Link>
      </li>
    </>
  );
};

export default StudentDropDownItems;
