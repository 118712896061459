import React from "react";
import { MutatingDots, Oval } from "react-loader-spinner";

const Loader = () => {
  return (
    <div className="loader">
      <div className="loader-dots">
        <Oval
          height={50}
          width={50}
          color="#ffffff"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#ffffff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    </div>
  );
};

export default Loader;
