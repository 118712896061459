import { Suspense, useEffect, useState } from "react";
import "./App.scss";
import { UserProvider } from "./context/UserContext";

import { HelmetProvider } from "react-helmet-async";
//components
import ScrollToTop from "./components/Functions/ScrollToTop";
import Routeing from "./components/Routes/Routeing";
import TransparentLoader from "./components/Global/TransparentLoader";
import AdModal from "./components/Global/AdModal";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        cacheTime: Infinity,
      },
    },
  });
  // const [adIsOpen, setAdIsOpen] = useState(false);
  // useEffect(() => {
  //   if (!localStorage.getItem("adShown")) {
  //     setAdIsOpen(true);
  //     localStorage.setItem("adShown", "true");
  //   }
  // }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<TransparentLoader />}>
        <UserProvider>
          <ScrollToTop />
          <HelmetProvider>
            <Routeing />
            {/* <AdModal setAdIsOpen={setAdIsOpen} adIsOpen={adIsOpen} /> */}
          </HelmetProvider>
        </UserProvider>
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;
