import React, { useEffect } from "react";
import { useParams } from "react-router";
import useAxiosFetch from "../../../hooks/useAxiosFetch";
import axios from "../../../apis/axiosInstance";
import Banner from "../../Global/Banner";
import ImageSlider from "../../Global/ImageSlider";

import Loader from "../../Global/Loader";
import ErrorScreen from "../../Global/ErrorScreen";
import { Helmet } from "react-helmet-async";
import useArticleFetch from "../../../hooks/blog/useArticleFetch";

const ArticlePage = () => {
  const { slug } = useParams();
  const { data: article, isLoading, error } = useArticleFetch(slug);

  const createHtml = () => {
    return { __html: article?.data?.data?.text };
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorScreen />;
  return (
    <div>
      <Banner
        h1={article?.data?.data?.title}
        img={`https://www.studenter.org/${article?.data?.data?.image}`}
      />
      <Helmet>
        <title>{article?.data?.data?.title}</title>
      </Helmet>
      <div className="article-page-text-box">
        <p
          className="article-page-text"
          dangerouslySetInnerHTML={createHtml()}
        />
      </div>

      {article?.data?.data?.gallery?.length > 0 && (
        <div className="article-page-gallery">
          <ImageSlider slides={article?.data?.data?.gallery} />
        </div>
      )}
    </div>
  );
};

export default ArticlePage;
