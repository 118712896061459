import {
  faBirthdayCake,
  faBook,
  faBookOpen,
  faDownload,
  faLanguage,
  faLocationArrow,
  faMailBulk,
  faMobile,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
//components
import AboutMe from "./AboutMe";
import Contact from "./Contact";
import { motion } from "framer-motion";
import { moveLeft, moveRight } from "../Functions/Animations";
import { MutatingDots } from "react-loader-spinner";
//hooks
import useAxiosFetch from "../../hooks/useAxiosFetch";
import { useParams } from "react-router";
import axiosInstance from "../../apis/axiosInstance";
import axios from "axios";
import UserInfoBox from "./UserInfoBox";
import { downloadFile } from "../Functions/DownloadFile";
import { useTranslation } from "react-i18next";

const UserProfile = () => {
  const userinfo = useParams();
  const { t } = useTranslation();
  const { response: user, error, isLoading, axiosFetch } = useAxiosFetch();

  const getUserInfo = async () => {
    await axiosFetch({
      axiosInstance: axiosInstance,
      method: "GET",
      url: `profile/${userinfo.id}`,
    });
  };

  const getPdf = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/get-cv/${user.id}`,
        {
          responseType: "blob",
          headers: {
            Accept: "application/octet-stream",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      if (response) {
        downloadFile({
          response: response.data,
          type: "application/pdf",
          ext: "pdf",
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  if (error) return <h2>Došlo je do greške</h2>;
  if (isLoading)
    return (
      <div className="absolute-center">
        <MutatingDots color="#1b587b" secondaryColor="#c9d5fd" />
      </div>
    );
  if (user) {
    return (
      <div className="user-profile">
        <img src={user.image} alt="img" className="user-profile-img" />
        <div className="user-profile-info">
          <h2 className="user-profile-title">{user?.name}</h2>
          <motion.div
            variants={moveRight}
            initial="hidden"
            animate="show"
            className="user-profile-boxes-left"
          >
            {user.educations && (
              <UserInfoBox
                fontclass="user-profile-box-icon-l"
                data={user.educations && user?.educations[0]?.school}
                icon={faBookOpen}
              />
            )}
            {user.educations && (
              <UserInfoBox
                className="user-profile-box2l"
                fontclass="user-profile-box-icon-l"
                data={user.educations && user?.educations[0]?.title}
                icon={faBook}
              />
            )}
            {user.city && (
              <UserInfoBox
                className="user-profile-box3l"
                fontclass="user-profile-box-icon-l"
                data={user.city.name}
                icon={faLocationArrow}
              />
            )}
            {user.phone && (
              <UserInfoBox
                className="user-profile-box4l"
                fontclass="user-profile-box-icon-l"
                data={user.phone}
                icon={faMobile}
              />
            )}
          </motion.div>
          <motion.div
            variants={moveLeft}
            initial="hidden"
            animate="show"
            className="user-profile-boxes-right"
          >
            {user.email && (
              <UserInfoBox
                className="  user-profile-box-right "
                fontclass="user-profile-box-icon-r"
                data={user.email}
                icon={faMailBulk}
              />
            )}

            <a
              onClick={() => getPdf()}
              className="user-profile-box user-profile-box2l user-profile-box-right user-profile-box-red "
              fontclass="user-profile-box-icon-r"
            >
              <FontAwesomeIcon icon={faDownload} />
              &nbsp;{t("download")} CV
            </a>

            {user.date_of_birth && (
              <UserInfoBox
                className=" user-profile-box3l user-profile-box-right"
                fontclass="user-profile-box-icon-r"
                data={user.date_of_birth}
                icon={faBirthdayCake}
              />
            )}
            {/* {user.languages && (
              <UserInfoBox
                className=" user-profile-box3l user-profile-box-right"
                fontclass="user-profile-box-icon-r"
               data="Engleski,Srpski"
                icon={faLanguage}
                  className=" user-profile-box4l  user-profile-boxl user-profile-box-right "
                fontclass="user-profile-box-icon-r"
              />
            )} */}
          </motion.div>
        </div>
        <AboutMe description={user.description} />
        <Contact id={user.id} />
      </div>
    );
  }
};

export default UserProfile;
