import React, { useState, useEffect } from "react";
//Components
import NotificationMessage from "./NotificationMessage";
import HamburgerCompany from "./HamburgerCompany";
import CompanyList from "./ListItems/CompanyList";
import { SwitchLanguage } from "../../languages/components/SwitchLanguage";
import logoText from "../../assets/images/logo-text.png";
import { Link, useLocation } from "react-router-dom";
//anim
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faBars,
  faBell,
  faCommentAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
//hooks
import useAuth from "../../hooks/useAuth";
import CompanyDropdownMenu from "./Dropdowns/CompanyDropDown";
import Notifications from "./Notifications/Notifications";
import useAxiosFetch from "../../hooks/useAxiosFetch";
import axios from "../../apis/axiosInstance";
import NotificationsCount from "./Notifications/NotificationsCount";

function CompanyNavBar({ navigationStyleBar }) {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const { user } = useAuth();
  const location = useLocation();

  const {
    fullResponse: notificationCount,
    isLoading: countLoading,
    error: notificationError,
    axiosFetch: fetchCount,
  } = useAxiosFetch();

  const fetchNotificationCount = () => {
    fetchCount({
      axiosInstance: axios,
      method: "get",
      url: `/check-notifications`,
    });
  };
  useEffect(() => {
    fetchNotificationCount();
    // eslint-disable-next-line
  }, [isNotificationsOpen]);

  useEffect(() => {
    setIsHamburgerOpen(false);
  }, [location]);

  return (
    <motion.div>
      <div className={navigationStyleBar}>
        <div className="nav-top-menu ">
          <Link to="/dogadjaji">
            <img src={logoText} alt="STUDENTER" className="nav-logo" />
          </Link>
          <SwitchLanguage styleClass="hide-for-mobile" />
          <div
            className="nav-profile-info hide-for-mobile"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <span className="nav-profile-name">{user?.user?.name}</span>
            <img
              src={user?.user?.logo}
              alt="Profile"
              className="nav-profile-img"
            />
            <FontAwesomeIcon
              icon={isDropdownOpen ? faAngleUp : faAngleDown}
              color="white"
            />
            {isDropdownOpen && <CompanyDropdownMenu />}
          </div>
        </div>
        <div className="nav-bottom-menu hide-for-mobile">
          <CompanyList />
          <div className="nav-notifications  hide-for-mobile">
            <div className="notification-circle-wrapper">
              {!notificationError && (
                <NotificationsCount
                  notificationCount={notificationCount}
                  notificationError={notificationError}
                  countLoading={countLoading}
                />
              )}

              <FontAwesomeIcon
                icon={faBell}
                color="white"
                className="nav-notification-icon"
                onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
              />
            </div>

            {isNotificationsOpen && (
              <Notifications setIsNotificationsOpen={setIsNotificationsOpen} />
            )}
            <Link to="/pocetna/poruke">
              <NotificationMessage />
              <FontAwesomeIcon
                icon={faCommentAlt}
                color="white"
                className="nav-notification-icon"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="hide-for-desktop notifications-mobile">
        {!notificationError && (
          <NotificationsCount
            notificationCount={notificationCount}
            notificationError={notificationError}
            countLoading={countLoading}
          />
        )}
        <FontAwesomeIcon
          icon={faBell}
          color="white"
          className="nav-notification-icon nav-mobile-notification-icon hide-for-desktop"
          onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
        />
        {isNotificationsOpen && (
          <Notifications setIsNotificationsOpen={setIsNotificationsOpen} />
        )}
      </div>

      <FontAwesomeIcon
        icon={isHamburgerOpen ? faTimes : faBars}
        size="2x"
        color="white"
        className="hamburger-btn hide-for-desktop"
        onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}
      />
      {isHamburgerOpen && <HamburgerCompany />}
    </motion.div>
  );
}

export default CompanyNavBar;
