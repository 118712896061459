import React, { useEffect } from "react";
import ConversationAddMessage from "./ConversationAddMessage";
import ConversationMessage from "./ConversationMessage";
import ConversationMessageRight from "./CoversationMessageRight";
import axios from "../../apis/axiosInstance";
//hooks
import useAxiosFetch from "../../hooks/useAxiosFetch";
import { useParams } from "react-router";
import useAuth from "../../hooks/useAuth";
import TransparentLoader from "../Global/TransparentLoader";
import NoMessages from "./NoMessages";

const Conversations = () => {
  const {
    fullResponse: conversation,
    error,
    isLoading,
    axiosFetch,
  } = useAxiosFetch();
  const {
    fullResponse: companyName,
    err,
    loader,
    axiosFetch: fetchCompanyName,
  } = useAxiosFetch();
  const {
    fullResponse: studentName,
    studentNameError,

    axiosFetch: fetchStudentName,
  } = useAxiosFetch();

  const { id } = useParams();
  const { user } = useAuth();

  const getConversation = async () => {
    axiosFetch({
      axiosInstance: axios,
      method: "get",
      url: `/messages/${id}`,
    });
    if (
      user.role === parseInt(process.env.REACT_APP_SUPER_ADMIN) ||
      user.role === parseInt(process.env.REACT_APP_ADMIN) ||
      user.role === parseInt(process.env.REACT_APP_STUDENT)
    ) {
      fetchCompanyName({
        axiosInstance: axios,
        method: "get",
        url: `/get-company-name/${id}`,
      });
    }
    if (user.role === parseInt(process.env.REACT_APP_COMPANY)) {
      fetchStudentName({
        axiosInstance: axios,
        method: "get",
        url: `/get-user-name/${id}`,
      });
    }
  };

  useEffect(() => {
    getConversation();
  }, [id]);

  if (isLoading) return null;
  if (error) return <span>Došlo je do greške</span>;

  if (user.role === parseInt(process.env.REACT_APP_COMPANY))
    return (
      <div className="conversation scroll">
        <span className="converdsation-name">{studentName}</span>
        {conversation?.map((message) => {
          return message.direction == 1 ? (
            <ConversationMessage key={message.id} message={message} />
          ) : (
            <ConversationMessageRight key={message.id} message={message} />
          );
        })}

        <ConversationAddMessage getConversation={getConversation} id={id} />
      </div>
    );
  return (
    <div className="conversation scroll">
      <span className="converdsation-name">{companyName}</span>
      {conversation.map((message) => {
        return message.direction == 0 ? (
          <ConversationMessage key={message.id} message={message} />
        ) : (
          <ConversationMessageRight key={message.id} message={message} />
        );
      })}
      <ConversationAddMessage getConversation={getConversation} id={id} />
    </div>
  );
};

export default Conversations;
