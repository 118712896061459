import React, { useState } from "react";
//components

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
//formik
import { FieldArray, Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import axios from "axios";
import { useTranslation } from "react-i18next";

const UserReferences = ({ references }) => {
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const initialValues = {
    new_reference:
      references?.length > 0
        ? references
        : [
            {
              organization: "",
              position: "",
              job_description: "",
              date_from: "",
              date_to: "",
            },
          ],
  };
  const schema = Yup.object({
    new_reference: Yup.array().of(
      Yup.object().shape({
        organization: Yup.string().required(t("required_field")),
        position: Yup.string().required(t("required_field")),
        job_description: Yup.string().required(t("required_field")),
        date_from: Yup.string().required(t("required_field")),
        date_to: Yup.string().required(t("required_field")),
      })
    ),
  });
  const referencesChangeHandler = async (value) => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/update-references`,
        value,
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      setMessage(data.data.message);
    } catch (error) {
      setMessage(t("error"));
    }
  };

  return (
    <div className="usersettings-item">
      <span className="usersettings-item-title">{t("references")}</span>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(value) => {
          referencesChangeHandler(value);
        }}
      >
        {({ values }) => (
          <Form className="usersettings-form">
            <FieldArray name="new_reference">
              {({ insert, push, remove }) => (
                <div>
                  {values.new_reference.map((element, index) => (
                    <div key={index} className="form-element">
                      <span className="input-paginatioin input-label">
                        {index + 1}/5
                      </span>
                      {values.new_reference.length > 1 && (
                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className="input-trash-btn"
                        >
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            color="red"
                          ></FontAwesomeIcon>
                        </button>
                      )}

                      <div className="inline-inputs-desktop">
                        <div className="input input-padding">
                          <label
                            className="input-label"
                            htmlFor={`new_reference.${index}.organization`}
                          >
                            {t("organization")}
                          </label>
                          <Field
                            name={`new_reference.${index}.organization`}
                            type="text"
                            className="input-field"
                          />
                          <ErrorMessage
                            name={`new_reference.${index}.organization`}
                            className="field-error"
                          >
                            {(msg) => (
                              <span className="input-errors">{msg}</span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="input input-padding">
                          <label
                            className="input-label"
                            htmlFor={`new_reference.${index}.position`}
                          >
                            {t("position")}
                          </label>
                          <Field
                            name={`new_reference.${index}.position`}
                            type="text"
                            className="input-field"
                          />
                          <ErrorMessage
                            name={`new_reference.${index}.position`}
                            component="div"
                            className="field-error"
                          >
                            {(msg) => (
                              <span className="input-errors">{msg}</span>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="inline-inputs-desktop">
                        <div className="input-dates ">
                          <div className="input input-padding">
                            <label
                              className="input-label"
                              htmlFor={`new_reference.${index}.date_from`}
                            >
                              {t("date_from")}
                            </label>
                            <Field
                              name={`new_reference.${index}.date_from`}
                              type="date"
                              className="input-field"
                              placeholder="dada"
                            />
                            <ErrorMessage
                              name={`new_reference.${index}.date_from`}
                              component="div"
                              className="field-error"
                            >
                              {(msg) => (
                                <span className="input-errors">{msg}</span>
                              )}
                            </ErrorMessage>
                          </div>

                          <div className="input input-padding">
                            <label
                              className="input-label"
                              htmlFor={`new_reference.${index}.date_to`}
                            >
                              {t("date_to")}
                            </label>
                            <Field
                              name={`new_reference.${index}.date_to`}
                              type="date"
                              className="input-field"
                            />
                            <ErrorMessage
                              name={`new_reference.${index}.date_to`}
                              component="div"
                              className="field-error"
                            >
                              {(msg) => (
                                <span className="input-errors">{msg}</span>
                              )}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>
                      <div className="input input-padding">
                        <label
                          className="input-label"
                          htmlFor={`new_reference.${index}.job_description`}
                        >
                          {t("description")}
                        </label>
                        <Field
                          name={`new_reference.${index}.job_description`}
                          type="text"
                          as="textarea"
                          rows="6"
                          className="textarea "
                        />
                        <ErrorMessage
                          name={`new_reference.${index}.job_description`}
                          component="div"
                          className="field-error"
                        >
                          {(msg) => <span className="input-errors">{msg}</span>}
                        </ErrorMessage>
                      </div>
                    </div>
                  ))}
                  <div className="form-add-more">
                    <button
                      type="button"
                      onClick={() =>
                        values.new_reference.length < 5 &&
                        push({
                          organization: "",
                          position: "",
                          job_description: "",
                          date_from: "",
                          date_to: "",
                        })
                      }
                    >
                      {values.new_reference.length < 5 && (
                        <FontAwesomeIcon
                          className="form-add-more-icon"
                          icon={faPlusCircle}
                          color="#64748b"
                        />
                      )}
                    </button>
                  </div>
                  <span className="changes-message  input-padding">
                    {message}
                  </span>
                  <div className="form-submit-wrapper input-padding">
                    <button type="submit" className="form-submit">
                      {t("save")}
                    </button>
                  </div>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default UserReferences;
