//hooks
import React, { useState } from "react";
import useAxiosFormData from "../../hooks/useAxiosFormData";
//components
import axiosinstance from "../../apis/axiosInstance";
import axios from "axios";
import { MutatingDots } from "react-loader-spinner";
import { faFile, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//func
import { downloadFile } from "../Functions/DownloadFile";
import { useTranslation } from "react-i18next";

const UserCV = ({ id }) => {
  const [cvFile, setCvFile] = useState();
  const { t } = useTranslation();
  const [response, error, loading, axiosFetch] = useAxiosFormData();

  const uploadCVHandler = async () => {
    const formData = new FormData();
    cvFile && formData.append("cv", cvFile);
    axiosFetch({
      axiosInstance: axiosinstance,
      method: "post",
      url: `/upload-cv`,
      formData,
    });
  };

  const getPdf = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/get-cv/${id}`,
        {
          responseType: "blob",
          headers: {
            Accept: "application/octet-stream",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      if (response) {
        downloadFile({
          response: response.data,
          type: "application/pdf",
          ext: "pdf",
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className="usersettings-item">
      <span className="usersettings-item-title">CV</span>
      <form className="usersettings-form">
        <div className="inline">
          <div className="input input-padding">
            <label className="input-label-file" htmlFor="cv">
              <FontAwesomeIcon icon={faUpload} /> {t("add")} CV
            </label>
            <input
              id="cv"
              type="file"
              className="display-none"
              onChange={(e) => setCvFile(e.target.files[0])}
            />
            {cvFile && cvFile.name}
          </div>
          <div className="input input-padding">
            <a onClick={() => getPdf()} className="input-file-link">
              {t("download")} CV <FontAwesomeIcon icon={faFile} />
            </a>
          </div>
        </div>
        {loading && <MutatingDots color="blue" secondaryColor="white" />}
        <span className="changes-message input-padding">
          {response && response}
        </span>
        <div className="form-submit-wrapper input-padding">
          <button
            type="button"
            onClick={() => uploadCVHandler()}
            className="form-submit"
          >
            {t("save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserCV;
