import React from "react";

const SelectFieldValue = (props) => {
  return (
    <div className={`input ${props.className}`}>
      <label className={`input-label ${props.inputLabelClassName} `}>
        {props.label}
      </label>

      <select
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        onBlur={props.onBlur}
        className={`select-field ${props.inputsClassName}`}
      >
        <option className="option-disabled" value="">
          {props.placeholder ? props.placeholder : "-- Odaberi --"}
        </option>
        {props.list?.map((item) => (
          <option key={item.id} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>
      {props.touched && props.errors ? (
        <p className={`input-errors  ${props.errorsClassName} `}>
          {props.errors}
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default SelectFieldValue;
