import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import axios from "../../../apis/axiosInstance";
import useAxiosFetch from "../../../hooks/useAxiosFetch";
import ErrorScreen from "../../Global/ErrorScreen";
import TransparentLoader from "../../Global/TransparentLoader";

const Notifications = ({ setIsNotificationsOpen }) => {
  const { t } = useTranslation();
  const {
    fullResponse: notifications,
    isLoading,
    error,
    axiosFetch,
  } = useAxiosFetch();
  const { axiosFetch: markAsRead } = useAxiosFetch();

  useEffect(() => {
    axiosFetch({
      axiosInstance: axios,
      method: "get",
      url: `/get-notifications`,
    });
    markAsRead({
      axiosInstance: axios,
      method: "get",
      url: `/mark-as-read`,
    });
    // eslint-disable-next-line
  }, []);
  function createMarkup(item) {
    return { __html: item };
  }
  if (error)
    return (
      <div className="notifications">
        <ErrorScreen />
      </div>
    );
  return (
    <div className="notifications">
      <FontAwesomeIcon
        icon={faCaretUp}
        className="notifications-arrow"
        onClick={() => setIsNotificationsOpen(false)}
      />
      {isLoading && <TransparentLoader />}
      <span className="notifications-title">{t("notifications")}</span>
      {notifications?.length < 1 && <span>{t("noNotifications")}</span>}
      <ul className="notifications-list">
        {notifications?.map((n) => (
          <li key={n?.id}>
            <Link
              to={n?.data?.url ? n?.data?.url : "/"}
              className="notifications-item"
            >
              <span className="notifications-item-title">{n?.data?.title}</span>
              <span
                className="notifications-item-message"
                dangerouslySetInnerHTML={createMarkup(n?.data?.message)}
              ></span>
              <span
                className="notifications-item-message"
                dangerouslySetInnerHTML={createMarkup(n?.data?.text)}
              ></span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Notifications;
