import axios from "axios";
import { useQuery } from "react-query";

const useCompanyJobsFetch = (slug) => {
  return useQuery([`company-jobs/${slug}`], async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/company-jobs/${slug}`
    );
    return res?.data?.data;
  });
};

export default useCompanyJobsFetch;
