import React from "react";
//components
import TextAreaInput from "../../Inputs/TextAreaInput";
import { useTranslation } from "react-i18next";
import { FieldArray, Formik, useFormik } from "formik";
import useJobApply from "../../../hooks/offers/useJobApply";

const JobApply = ({ slug, questions }) => {
  const { t } = useTranslation();
  const {
    mutate: jobApply,
    isError,
    isSuccess,
    isLoading,
    data: response,
  } = useJobApply(slug);

  const formik = useFormik({
    initialValues: {
      answers: questions, //SHOULD BE ARRAY
    },

    onSubmit: (values) => {
      jobApply(values);
    },
  });

  return (
    <div className="offer-page-apply">
      <h3 className="offer-page-apply-title">{t("offer_apply")}</h3>

      {!isSuccess && (
        <span className="offer-apply-description">
          {t("offer_apply_description")}
        </span>
      )}
      {!isSuccess && (
        <Formik initialValues={formik.initialValues} onSubmit={formik.onSubmit}>
          {() => (
            <FieldArray name="questions">
              {({ push, remove, form }) => (
                <>
                  <span className="offer-apply-description">
                    {t("answersChance")}
                  </span>

                  {questions?.map((question, index) => (
                    <div key={index} className="width-100 padding-8">
                      <TextAreaInput
                        type="text"
                        placeholder=""
                        label={question?.question}
                        name={`answers.${index}.answer`}
                        onBlur={formik.handleBlur}
                        touched={
                          formik.touched.answers &&
                          formik.touched.answers[index]
                        }
                        errors={
                          formik.errors.answers && formik.errors.answers[index]
                        }
                        onChange={formik.handleChange}
                      //  value={`answers.${index}`}
                      />
                    </div>
                  ))}
                </>
              )}
            </FieldArray>
          )}
        </Formik>
      )}
      {response?.data && (
        <span className="success-text-m">{response?.data?.message}</span>
      )}
      {isError && <span className="error-text-m"> {t("error")}</span>}
      {!isSuccess && (
        <button
          disabled={isLoading}
          onClick={() => formik.handleSubmit()}
          className="offer-page-apply-btn"
        >
          {t("offer_apply")}
        </button>
      )}
    </div>
  );
};

export default JobApply;
