import React from "react";
import { useTranslation } from "react-i18next";
import nomsg from "../../assets/images/nomsg.svg";
const NoMessages = () => {
  const { t } = useTranslation();
  return (
    <div className="no-messages">
      <img src={nomsg} alt="Empty" className="no-messages-img" />
      <span className="no-messages-text">{t("no_messagesText")}</span>
    </div>
  );
};

export default NoMessages;
