import React from "react";
import { useTranslation } from "react-i18next";

const AboutMe = ({ description }) => {
  function createMarkup() {
    return { __html: description };
  }
  const { t } = useTranslation();
  return (
    <div className="user-profile-aboutme box-drop-shadow">
      <span className="user-profile-aboutme-label">{t("about_me")}</span>
      <p
        className="user-profile-aboutme-text"
        dangerouslySetInnerHTML={createMarkup()}
      ></p>
    </div>
  );
};

export default AboutMe;
