import React from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

const GuestApply = () => {
  const { t } = useTranslation();
  return (
    <div className="offer-page-apply">
      <h3 className="offer-page-apply-title">{t("offer_apply")}</h3>

      <span className="offer-apply-description">
        {t("offer_apply_description")}
      </span>

      <Link to="/auth/fizicko-lice" className="offer-page-apply-btn">
        {t("login")}
      </Link>
      <Link
        to="/auth/fizicko-lice/register"
        className="offer-page-register-btn"
      >
        {t("register")}
      </Link>
    </div>
  );
};

export default GuestApply;
