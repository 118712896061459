import circleLoader from "../../assets/animations/circleLoader.json";
import { useFormik } from "formik";
import InputField from "../Inputs/InputField";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import useCreateTopic from "../../hooks/forum/useCreateTopic";
import FilterLoader from "../Companies/FilterLoader";
import Lottie from "lottie-react";
import useCreateDiscussion from "../../hooks/forum/useCreateDiscussion";

const CreateDiscussion = ({ id }) => {
  const { user } = useAuth();

  const { mutate, isError, isLoading } = useCreateDiscussion(id);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      forum_topic_id: id,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Obavezno polje"),
      description: Yup.string().required("Obavezno polje"),
    }),
    onSubmit: (values, { resetForm }) => {
      values.forum_topic_id = id;
      mutate(values);
      resetForm({
        values: {
          title: "",
          description: "",
        },
      });
    },
  });

  return (
    <div className="forum-create">
      <span className="forum-create-title">Otvori diskusiju</span>

      <form className="forum-create-form" onSubmit={formik.handleSubmit}>
        <InputField
          type="text"
          placeholder=""
          label="Naziv diskusije"
          name="title"
          onBlur={formik.handleBlur}
          touched={formik.touched.title}
          onChange={formik.handleChange}
          errors={formik.errors.title}
          value={formik.values.title}
        />
        <InputField
          type="text"
          placeholder=""
          label="Opis diskusije"
          name="description"
          onBlur={formik.handleBlur}
          touched={formik.touched.description}
          onChange={formik.handleChange}
          errors={formik.errors.description}
          value={formik.values.description}
        />
        <div>
          {isLoading && <FilterLoader />}
          {isError && <h2 className="forum-create-err ">Greška</h2>}
          <button className="forum-create-btn " type="submit">
            SAČUVAJ
          </button>
          <div className="forum-create-loader">
            {isLoading && <Lottie animationData={circleLoader} loop={true} />}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateDiscussion;
