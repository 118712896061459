import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const InputField = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="input-container">
      <div className={`input ${props.className}`}>
        <label className={`input-label ${props.labelClassName}`}>
          {props.label}
        </label>

        <input
          autoComplete="off"
          name={props.name}
          onChange={props.onChange}
          value={props.value}
          type={isVisible ? "text" : props.type}
          onBlur={props.onBlur}
          placeholder={props.placeholder}
          className={`input-field ${props.inputClassname}`}
        ></input>
        {props.isPasswordVisible && (
          <FontAwesomeIcon
            icon={isVisible ? faEyeSlash : faEye}
            className="input-password-icon"
            color="#94a3b8"
            onClick={() => setIsVisible(!isVisible)}
          />
        )}
      </div>
      {props.touched && props.errors ? (
        <p className="input-errors">{props.errors}</p>
      ) : (
        ""
      )}
    </div>
  );
};

export default InputField;
