import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import NotFound404 from "../Global/NotFound404";
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import Events from "../Events/Events";
import EventPage from "../../pages/Events/EventPage";
import Home from "../Home/Home";
import Forum from "../../pages/Forum/Forum";
import Companies from "../Home/Companies";
import CompaniesPage from "../../pages/Companies/CompaniesPage";
import CompanyPage from "../../pages/Companies/CompanyPage";
import NewPassword from "../Auth/NewPassword";
import Auth from "../Auth/Auth";
import ResetPassword from "../Auth/ResetPassword";
import ConfirmedEmail from "../Auth/ConfirmedEmail";
import AboutUs from "../Home/AboutUs";
import OffersHome from "../Offers/Global/OffersHome";
import JobsPage from "../../pages/Offers/JobsPage";
import JobPage from "../../pages/Offers/JobPage";
import ProjectPage from "../../pages/Offers/ProjectPage";
import ProjectsPage from "../../pages/Offers/ProjectsPage";
import BlogPage from "../Blog/BlogPage";
import BlogHome from "../Blog/BlogHome";
import ArticlePage from "../Blog/ArticlePage/ArticlePage";
import EventsPage from "../../pages/Events/EventsPage";
import Topics from "../Forum/Topics";
import Discussions from "../Forum/Discussions";
import Messages from "../Forum/Messages";
import CandidatesPermission from "../Global/CandidatesPermission";
import LandingPage from "../../pages/Home/LandingPage";

const PublicRoutes = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route exact path="/" element={<Navigate to="pocetna-stranica" />} />
      <Route path="*" element={<NotFound404 />} />
      <Route path="/email-verification/*" element={<ConfirmedEmail />} />
      <Route path="/pocetna-stranica" element={<LandingPage />} />
      <Route exact path="/auth/:type/*" element={<Auth />}>
        <Route path="register" element={<Register />} />
        <Route path="" element={<Login />} />
        <Route path="reset-password-confirmation" element={<ResetPassword />} />
        <Route path="reset-password" element={<NewPassword />} />
      </Route>
      <Route path="/dogadjaji" element={<Events />}>
        <Route
          exact
          path="/dogadjaji/:slug/prijava"
          element={<Navigate to="/register" />}
        />
        <Route exact path="dogadjaj/:slug" element={<EventPage />} />
        <Route exact path="" element={<EventsPage />} />
      </Route>
      <Route path="/blog" element={<BlogPage />}>
        <Route path="" element={<BlogHome />} />
        <Route path=":slug" element={<ArticlePage />} />
      </Route>
      <Route path="/o-nama" element={<AboutUs />} />

      <Route path="/pocetna" element={<Home />}>
        <Route path="korisnici" element={<CandidatesPermission />} />
        <Route path="forum" element={<Forum />}>
          <Route path="teme" element={<Topics />} />
          <Route path="teme/:id/diskusije" element={<Discussions />} />
          <Route path="teme/:id/diskusije/:dId" element={<Messages />} />
        </Route>

        <Route path="oglasi" element={<OffersHome />}>
          <Route path="poslovi" element={<JobsPage />} />
          <Route path="poslovi/:slug" element={<JobPage />} />
          <Route path="projekti" element={<ProjectsPage />} />
          <Route path="projekti/:slug" element={<ProjectPage />} />
        </Route>
        <Route path="firme" element={<Companies />}>
          <Route path="" element={<CompaniesPage />} />
          <Route path=":id" element={<CompanyPage />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default PublicRoutes;
