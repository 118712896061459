import {
  faCaretDown,
  faClock,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DeletePrompt from "./DeletePrompt";

const MyOffer = ({ job }) => {
  const [deleteHandler, setDeleteHandler] = useState(false);
  const { t } = useTranslation();

  function createMarkup() {
    return { __html: job.description };
  }

  return (
    <div className="my-offer-card">
      <div className="mobile-column flexRow width-100 flex-space-between align-items-center">
        <img
          className="offer-img"
          alt="LOGO"
          src={`https://studenter.org/${job?.company?.logo}`}
        />
        <span className="my-offer-card-title">{job.title}</span>
        <span className="my-offer-card-light-text">
          {job.expiry_date} &nbsp; <FontAwesomeIcon icon={faClock} />
        </span>
      </div>
      {/* <span
        className="my-offer-card-light-text"
        dangerouslySetInnerHTML={createMarkup()}
      /> */}
      <div className="space-between space-between-column-mobile">
        <Link className="my-offer-card-btn" to={`izmeni/${job.slug}`}>
          {t("edit")} <FontAwesomeIcon icon={faEdit} />
        </Link>
        <Link
          className="my-offer-card-btn my-offer-card-btn-violet"
          to={`prijave/${job.slug}`}
        >
          {t("show_applications")}
        </Link>
        <button
          onClick={() => setDeleteHandler(!deleteHandler)}
          className="my-offer-card-btn-red my-offer-card-btn"
        >
          {t("delete")} <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
      {deleteHandler && (
        <DeletePrompt
          slug={job.slug}
          route={job.route}
          setDeleteHandler={setDeleteHandler}
        />
      )}
    </div>
  );
};

export default MyOffer;
