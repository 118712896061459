import React from "react";

const SelectField = (props) => {
  return (
    <div className={`input ${props.className}`}>
      <label className="input-label">{props.label}</label>

      <select
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        onBlur={props.onBlur}
        className="select-field"
      >
        <option className="option-disabled" value="" disabled>
          {props.placeholder ? props.placeholder : "-- Odaberi --"}
        </option>
        {props.list?.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
      {props.touched && props.errors ? (
        <p className="input-errors">{props.errors}</p>
      ) : (
        ""
      )}
    </div>
  );
};

export default SelectField;
