export const type = [
  {
    id: 0,
    name: "Praksa",
  },
  {
    id: 1,
    name: "Posao",
  },
];
export const typeEng = [
  {
    id: 0,
    name: "Internship",
  },
  {
    id: 1,
    name: "Job",
  },
];
export const sortOffers = [
  {
    id: 0,
    name: "Najnovije",
  },
  {
    id: 1,
    name: "Pred istekom",
  },
];
export const sortOffersEng = [
  {
    id: 0,
    name: "Newest",
  },
  {
    id: 1,
    name: "Oldest",
  },
];
export const typePaying = [
  {
    id: 2,
    name: "Plaćena praksa",
  },
  {
    id: 3,
    name: "Neplaćena praksa",
  },
];
export const typePayingEng = [
  {
    id: 2,
    name: "Paid internship",
  },
  {
    id: 3,
    name: "Unpaid internship",
  },
];
export const duration = [
  {
    id: 4,
    name: "Stalni radni odnos",
  },
  {
    id: 5,
    name: "Ograničeno trajanje",
  },
];
export const durationInternship = [
  {
    id: 6,
    name: "1 Meseca",
  },
  {
    id: 7,
    name: "2 meseca",
  },
  {
    id: 8,
    name: "3 meseca",
  },
];
export const deadline = [
  {
    id: 7,
    name: "7 dana",
  },
  {
    id: 15,
    name: "15 dana",
  },
  {
    id: 21,
    name: "21 dana",
  },
  {
    id: 30,
    name: "30 dana",
  },
];
export const deadlineEng = [
  {
    id: 7,
    name: "7 days",
  },
  {
    id: 15,
    name: "15 days",
  },
  {
    id: 21,
    name: "21 days",
  },
  {
    id: 30,
    name: "30 days",
  },
];

export const sizes = [
  { id: 0, name: "S" },
  { id: 1, name: "M" },
  { id: 2, name: "L" },
  { id: 3, name: "XL" },
];



export const typeStudies = [
  {
    id: 1,
    name: "Osnovne akademske studije",
  },
  { id: 2, name: "Master akademske studije" },
  { id: 3, name: "Doktorske akademske studije" },
  { id: 4, name: "Specijalističke akademske studije" },

  { id: 5, name: "Osnovne strukovne studije" },
  { id: 6, name: "Specijalističke strukovne studije" },
  { id: 7, name: "Master strukovne studije" },
];
export const typeStudiesEng = [
  {
    id: 1,
    name: "Bachelor's academic studies",
  },
  { id: 2, name: "Master's academic studies" },
  { id: 3, name: "Doctoral academic studies" },
  { id: 4, name: "Specialist academic studies" },
  { id: 5, name: "Bachelor's vocational studies" },
  { id: 6, name: "Specialist vocational studies" },
  { id: 7, name: "Master's vocational studies" },
];

export const pumaTypeOfParticipants = [
  {
    id: 3,
    name: "Student",
  },
  { id: 0, name: "Autor rada" },
  {
    id: 1,
    name: "Panelista",
  },
  { id: 2, name: "Gost" },
];
export const QualityFestivalFields = [
  { id: 0, name: "Autor rada" },
  {
    id: 1,
    name: "Panelista",
  },
];
export const yoursTypeOfParticipants = [
  { id: 0, name: "besplatna kotizacija sa radom (za učesnike i/ili studente)" },
  {
    id: 1,
    name: "kotizacija sa popustom od 50%",
  },
];
export const currenies = [

  {
    id: 'rsd',
    name: "RSD",
    value: 'rsd'
  },
  {
    id: 'mkd',
    name: "MKD",
    value: 'mkd'
  },
  {
    id: 'eur',
    name: "EUR",
    value: 'eur'
  },
  {
    id: 'bam',
    name: "BAM",
    value: 'bam'
  },
];
export const numbers = [
  { id: 1, name: "1" },
  {
    id: 2,
    name: "2",
  },
  {
    id: 3,
    name: "4",
  },
  {
    id: 4,
    name: "4",
  },
  {
    id: 5,
    name: "5",
  },
  {
    id: 6,
    name: "6",
  },
  {
    id: 7,
    name: "7",
  },
  {
    id: 2,
    name: "2",
  },
];
