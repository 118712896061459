import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
//COmponents
import NotFound404 from "../Global/NotFound404";

import Events from "../Events/Events";
import EventPage from "../../pages/Events/EventPage";
import EventsPage from "../../pages/Events/EventsPage";
import Home from "../Home/Home";
//forum
import Forum from "../../pages/Forum/Forum";
import Discussions from "../Forum/Discussions";
import Topics from "../Forum/Topics";

import UserSettings from "../UserSettings/UserSettings";
import Chat from "../Home/Chat";
import Users from "../Home/Users";
import UsersPage from "../../pages/Users/UsersPage";
import UserProfile from "../Users/UserProfile";
import Companies from "../Home/Companies";
import CompaniesPage from "../../pages/Companies/CompaniesPage";
import CompanyPage from "../../pages/Companies/CompanyPage";

import Conversations from "../Chat/Conversations";
import AboutUs from "../Home/AboutUs";
//offers
import OffersHome from "../Offers/Global/OffersHome";
import JobsPage from "../../pages/Offers/JobsPage";
import JobPage from "../../pages/Offers/JobPage";
import ProjectsPage from "../../pages/Offers/ProjectsPage";
import ProjectPage from "../../pages/Offers/ProjectPage";

import MyApplications from "../Users/MyApplications";
import BlogPage from "../Blog/BlogPage";
import BlogHome from "../Blog/BlogHome";
import ArticlePage from "../Blog/ArticlePage/ArticlePage";
import ConfirmedEmail from "../Auth/ConfirmedEmail";
import Messages from "../Forum/Messages";
import LandingPage from "../../pages/Home/LandingPage";

const StudentRoutes = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route exact path="/" element={<Navigate to="/pocetna-stranica" />} />
      <Route path="/pocetna-stranica" element={<LandingPage />} />
      <Route path="/email-verification/*" element={<ConfirmedEmail />} />
      <Route path="*" element={<NotFound404 />} />
      <Route path="/dogadjaji" element={<Events />}>
        <Route exact path="dogadjaj/:slug" element={<EventPage />} />
        <Route exact path="" element={<EventsPage />} />
      </Route>
      <Route path="/blog" element={<BlogPage />}>
        <Route path="" element={<BlogHome />} />
        <Route path=":slug" element={<ArticlePage />} />
      </Route>
      <Route path="o-nama" element={<AboutUs />} />
      <Route path="/pocetna" element={<Home />}>
        <Route exact path="profil" element={<UserSettings />} />
        <Route path="mojeprijave" element={<MyApplications />} />

        <Route path="forum" element={<Forum />}>
          <Route path="teme" element={<Topics />} />
          <Route path="teme/:id/diskusije" element={<Discussions />} />
          <Route path="teme/:id/diskusije/:dId" element={<Messages />} />
        </Route>

        <Route path="poruke" element={<Chat />}>
          <Route path=":id" element={<Conversations />}></Route>
        </Route>
        <Route path="korisnici" element={<Users />}>
          <Route path="" element={<UsersPage />} />
          <Route path=":id" element={<UserProfile />} />
        </Route>
        <Route path="oglasi" element={<OffersHome />}>
          <Route path="poslovi" element={<JobsPage />} />
          <Route path="poslovi/:slug" element={<JobPage />} />
          <Route path="projekti" element={<ProjectsPage />} />
          <Route path="projekti/:slug" element={<ProjectPage />} />
        </Route>
        <Route path="firme" element={<Companies />}>
          <Route path="" element={<CompaniesPage />} />
          <Route path=":id" element={<CompanyPage />} />
        </Route>
      </Route>
    </Routes>
  );
};
export default StudentRoutes;
