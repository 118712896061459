import { useInfiniteQuery } from "react-query";

const fetchMessages = async ({ pageParam = 1, queryKey, dId }) => {
  const response = await fetch(
    `${process.env.REACT_APP_BASE_URL}/discussions-posts/${dId}?page=${pageParam}`
  );
  if (!response.ok) {
    throw new Error("Something went wrong!");
  }
  return response.json();
};

const useMessages = (dId) => {
  return useInfiniteQuery([`messages/${dId}`], () => fetchMessages({ dId }), {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.meta?.current_page < lastPage?.meta?.last_page)
        return lastPage?.meta?.current_page + 1;
      return false;
    },
  });
};
export default useMessages;
