import React, { useState } from "react";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
//components
import { motion } from "framer-motion";
import { moveLeft } from "../Functions/Animations";
import logo from "../../assets/images/logo.png";
import WhiteInput from "../Inputs/WhiteInput";
import axios from "axios";
import { MutatingDots } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { type } = useParams();
  const { t } = useTranslation();
  const handleReset = async (email) => {
    setIsLoading(true);

    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/${
        type === "fizicko-lice" ? "reset-password" : "company-reset-password"
      }`,
      {
        email,
      }
    );
    setMessage(res.data?.message);
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Obavezno polje"),
    }),
    onSubmit: (values) => {
      handleReset(values);
    },
  });

  return (
    <motion.div
      variants={moveLeft}
      animate="show"
      initial="hidden"
      className="auth-sign"
    >
      <img className="auth-sign-logo" src={logo} alt="logo" />
      <WhiteInput
        label="Email"
        type="email"
        placeholder=""
        name="email"
        onBlur={formik.handleBlur}
        touched={formik.touched.email}
        onChange={formik.handleChange}
        errors={formik.errors.email}
        value={formik.values.email}
      />
      {isLoading && <MutatingDots color="#fff" secondaryColor="#1b587b" />}
      <span className="auth-message"> {message}</span>
      <button
        onClick={() => formik.handleSubmit()}
        type="submit"
        className="auth-sign-btn"
      >
        {t("reset_password")}
      </button>

      <Link to={`/auth/${type}`} className="auth-nav-btn">
        <FontAwesomeIcon icon={faArrowCircleLeft} color="#fff" /> {t("back")}
      </Link>
    </motion.div>
  );
};

export default ResetPassword;
