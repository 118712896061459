import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeInModern } from "../Functions/Animations";
const CompanyCard = (company) => {
  const [searchParams, setSearchParams] = useSearchParams({});

  const { careerField, city, slug, logo, description, name } = company;
  function createMarkup() {
    return { __html: description };
  }

  const filterByCareer = (id, e) => {
    e.stopPropagation();
    setSearchParams({ career_field: id });
    window.scrollTo(0, 0);
  };

  if (company)
    return (
      <motion.div
        animate="show"
        initial="hidden"
        variants={fadeInModern}
        className="company-card gap-8"
      >
        <div className="flexCol gap-32 flex-space-between ">
          <Link to={`${slug}`} className="flexRow flex-space-between gap-8">
            <img
              className="company-card-img hide-for-desktop"
              alt="logo"
              src={logo}
            />
            <div className="flexCol  company-card-text-wrapper gap-16">
              <span className="company-card-title"> {name}</span>
              <span
                className="company-card-text-desc"
                dangerouslySetInnerHTML={createMarkup()}
              />
            </div>
          </Link>

          <div className="flexRow flexWrap gap-16">
            {careerField?.name && (
              <span
                onClick={(e) => filterByCareer(careerField?.id, e)}
                className="company-card-filter-btn"
              >
                {careerField?.name}
              </span>
            )}
            {city && <span className="company-card-filter-btn-2">{city}</span>}
          </div>
        </div>
        <Link to={`${slug}`}>
          <img
            className="company-card-img hide-for-mobile"
            alt="logo"
            src={logo}
          />
        </Link>
      </motion.div>
    );
};

export default CompanyCard;
