import React from "react";
//hooks
import { useTranslation } from "react-i18next";
//components
import { Link } from "react-router-dom";

const CompanyCardInfo = ({ company }) => {
  const { t } = useTranslation();
  return (
    <div className="offer-page-apply">
      <img
        alt="LOGO"
        src={`https://studenter.org/${company?.company?.logo}`}
        className="offer-page-contact-logo "
      />
      <h3 className="offer-page-apply-title">{company?.company?.name}</h3>
      <Link
        to={`/pocetna/firme/${company?.company?.slug}`}
        className="offer-page-apply-btn"
      >
        {t("show_details")}
      </Link>
    </div>
  );
};

export default CompanyCardInfo;
