import React, { useState } from "react";
//components
import TextAreaInput from "../../Inputs/TextAreaInput";
import { Link } from "react-router-dom";
//hooks
import useAxiosPost from "../../../hooks/useAxiosPost";
import useAuth from "../../../hooks/useAuth";
import { Formik, Form } from "formik";
import axios from "../../../apis/axiosInstance";
import InputField from "../../Inputs/InputField";
import { ApplyProjectValidation } from "../../../constants/validations/ApplyProjectValidation";
import * as Yup from "yup";
import { MutatingDots } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const ProjectApply = ({ slug }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { response, error, isLoading, axiosPost } = useAxiosPost();

  const applyHandler = async (values) => {
    const formData = new FormData();
    const fileListAsArray = values.files && Array.from(values.files);

    values.files &&
      fileListAsArray.map((value) => formData.append("files[]", value));

    values.deadline && formData.append("deadline", values.deadline);
    values.note && formData.append("note", values.note);
    values.price && formData.append("price", values.price);

    axiosPost({
      axiosInstance: axios,
      url: `${process.env.REACT_APP_BASE_URL}/project-applications/create/${slug}`,
      data: formData,
    });
  };

  return (
    <div className="offer-page-apply">
      <h3 className="offer-page-apply-title">Konkuriši</h3>

      <span className="offer-apply-description">
        {t("offer_apply_description")}
      </span>

      <Formik
        initialValues={{ deadline: "", note: "", price: "" }}
        validationSchema={ApplyProjectValidation}
        onSubmit={(values, actions) => {
          applyHandler(values);
        }}
      >
        {({ setFieldValue, handleBlur, values, touched, errors }) => (
          <Form className="offer-page-apply-form">
            <InputField
              type="text"
              placeholder=""
              label={t("price")}
              name="price"
              onBlur={handleBlur}
              touched={touched.price}
              onChange={(event) => {
                setFieldValue("price", event.target.value);
              }}
              errors={errors.price}
              value={values.price}
            />

            <InputField
              type="text"
              placeholder=""
              label={t("deadline")}
              name="deadline"
              onBlur={handleBlur}
              touched={touched.deadline}
              onChange={(event) => {
                setFieldValue("deadline", event.target.value);
              }}
              errors={errors.deadline}
              value={values.deadline}
            />
            <label className="input-label">{t("addFiles")}</label>
            <input
              name="files"
              type="file"
              multiple
              onChange={(event) => {
                setFieldValue("files", event.currentTarget.files);
              }}
            />
            <TextAreaInput
              onChange={(event) => {
                setFieldValue("note", event.target.value);
              }}
              value={values.note}
              label="napomena (Nije obavezno)"
              name="note"
            />
            {response && (
              <span className="offer-page-light-text">{response?.message}</span>
            )}

            {error && (
              <span className="offer-page-light-text">Došlo je do greške</span>
            )}
            {isLoading && (
              <MutatingDots color="#a77246" secondaryColor="#a7467b" />
            )}
            <button type="submit" className="offer-page-apply-btn">
              Konkuriši
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProjectApply;
