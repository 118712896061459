import React from "react";
import { NavLink } from "react-router-dom";

function LinkItem(props) {
  return (
    <>
      <NavLink
        onClick={props.onClick ? props.onClick : ""}
        className={({ isActive }) => (isActive ? "navitem-active" : "navitem")}
        to={props.link}
      >
        {props.title}
      </NavLink>
    </>
  );
}

export default LinkItem;
