import React, { useState, useEffect } from "react";
//components
import InputField from "../../Inputs/InputField";
import { OfferValidation } from "../../../constants/validations/OfferValidation";
import axios from "../../../apis/axiosInstance";
import TextEditor from "../../Global/TextEditor";
import SelectFieldNameValue from "../../Inputs/SelectFieldNameValue";
import TextAreaInput from "../../Inputs/TextAreaInput";
import {
  type,
  typeEng,
  deadline,
  duration,
  deadlineEng,
  durationInternship,
  typePaying,
  typePayingEng,
} from "../../../constants/LocalData";
import SelectField from "../../Inputs/SelectField";
import useListsFetch from "../../../hooks/useListsFetch";
import { useFormik, Formik, FieldArray } from "formik";
import { Oval } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../../languages/i18n";
import useNewJobOffer from "../../../hooks/offers/useNewJobOffer";
import PricingModels from "../../Global/PricingModels";
import useAdsCount from "../../../hooks/offers/useAdsCount";
import ClassicLoader from "../../Global/ClassicLoader";
import AdModal from "../../Global/AdModal";

const CreateJobOffer = () => {
  const { data: numberOfAds, isLoading: adsIsLoading, isError } = useAdsCount()
  const [editorText, setEditorText] = useState("");

  const { cities, fields, countries } = useListsFetch(
    "cities , career-fields , countries"
  );
  const { t } = useTranslation();

  const {
    mutate: createOffer,
    isLoading,
    error,
    data: response,
  } = useNewJobOffer();



  const formik = useFormik({
    initialValues: {
      title: "", //nazib
      description: editorText, //opis
      salary: "", //iznos plate
      type: "", //praksa ili zaposlenje
      note: "", //napomena
      duration: "", //trajanje posla ili prakse
      deadline: "", //koliko je validan oglas
      career_field_id: "",
      country_id: "",
      questions: [""], //SHOULD BE ARRAY
    },
    validationSchema: OfferValidation,
    onSubmit: (values) => {
      values.description = editorText;
      createOffer(values);
    },
  });

  if (adsIsLoading) return <ClassicLoader />
  if (numberOfAds == 0) return <PricingModels />
  if (!numberOfAds) return null
  return (
    <div className="create-offer">

      <div className="create-offer-title-wrapper">
        <h1 className="create-offer-title">
          {formik.values.title ? formik.values.title : t("new_offer")}
        </h1>
        <span className="create-offer-text">
          {t("create_offer_freelance_msg")} &nbsp;
          <Link to="/pocetna/kreiraj-projekat" className="link">
            {t("click_here")}
          </Link>
        </span>
      </div>
      <Formik
        initialValues={formik.initialValues}
        validationSchema={formik.validationSchema}
        onSubmit={formik.onSubmit}
      >
        {() => (
          <form className="create-offer-form" onSubmit={formik.handleSubmit}>
            <div className="inline mobile-column">
              <InputField
                type="text"
                placeholder=""
                label={`${t("name_offer")} *`}
                name="title"
                onBlur={formik.handleBlur}
                touched={formik.touched.title}
                errors={formik.errors.title}
                onChange={formik.handleChange}
                value={formik.values.title}
              />

              <SelectFieldNameValue
                type="select"
                placeholder={t("select")}
                label={`${t("type")} *`}
                name="type"
                list={i18n.language === "en" ? typeEng : type}
                onBlur={formik.handleBlur}
                touched={formik.touched.type}
                errors={formik.errors.type}
                onChange={formik.handleChange}
                value={formik.values.type}
              />
              {formik.values.type === "Praksa" ? (
                <SelectFieldNameValue
                  type="select"
                  placeholder={t("select")}
                  label={t("sallary_number")}
                  name="salary"
                  list={i18n.language === "en" ? typePayingEng : typePaying}
                  onBlur={formik.handleBlur}
                  touched={formik.touched.salary}
                  errors={formik.errors.salary}
                  onChange={formik.handleChange}
                  value={formik.values.salary}
                />
              ) : (
                <InputField
                  type="text"
                  placeholder=""
                  label={t("sallary_number")}
                  name="salary"
                  onBlur={formik.handleBlur}
                  touched={formik.touched.salary}
                  errors={formik.errors.salary}
                  onChange={formik.handleChange}
                  value={formik.values.salary}
                />
              )}
            </div>
            <div className="inline mobile-column">
              <SelectField
                type="select"
                placeholder={t("select")}
                label={`${t("filter_field")} *`}
                name="career_field_id"
                list={fields}
                onBlur={formik.handleBlur}
                touched={formik.touched.career_field_id}
                errors={formik.errors.career_field_id}
                onChange={formik.handleChange}
                value={formik.values.career_field_id}
              />

              {formik.values.type === "1" ? (
                <SelectFieldNameValue
                  type="select"
                  placeholder={t("select")}
                  label={`${t("duration")} *`}
                  name="duration"
                  list={durationInternship}
                  onBlur={formik.handleBlur}
                  touched={formik.touched.duration}
                  errors={formik.errors.duration}
                  onChange={formik.handleChange}
                  value={formik.values.duration}
                />
              ) : (
                <SelectFieldNameValue
                  type="select"
                  placeholder={t("select")}
                  label={`${t("duration")} *`}
                  name="duration"
                  list={duration}
                  onBlur={formik.handleBlur}
                  touched={formik.touched.duration}
                  errors={formik.errors.duration}
                  onChange={formik.handleChange}
                  value={formik.values.duration}
                />
              )}
            </div>
            <div className="inline mobile-column">
              <SelectField
                type="select"
                placeholder={t("select")}
                label={`${t("offer_duration")} *`}
                name="deadline"
                list={i18n.language === "en" ? deadlineEng : deadline}
                onBlur={formik.handleBlur}
                touched={formik.touched.deadline}
                errors={formik.errors.deadline}
                onChange={formik.handleChange}
                value={formik.values.deadline}
              />
              <SelectField
                type="select"
                placeholder={t("select")}
                label={t("filter_country")}
                name="country_id"
                list={countries && countries}
                onBlur={formik.handleBlur}
                touched={formik.touched.country_id}
                errors={formik.errors.country_id}
                onChange={formik.handleChange}
                value={formik.values.country_id}
              />
              <SelectField
                type="select"
                placeholder={t("select")}
                label={t("filter_city")}
                name="city_id"
                list={
                  cities &&
                  cities.filter(
                    (filtered) =>
                      filtered.country_id == formik.values.country_id
                  )
                }
                onBlur={formik.handleBlur}
                touched={formik.touched.city_id}
                errors={formik.errors.city_id}
                onChange={formik.handleChange}
                value={formik.values.city_id}
              />
            </div>
            <div>
              <label className="input-label">{t("description")} *</label>
              <TextEditor setEditorText={setEditorText} />
            </div>

            <TextAreaInput
              type="text"
              placeholder=""
              label={t("note")}
              name="note"
              onBlur={formik.handleBlur}
              touched={formik.touched.note}
              errors={formik.errors.note}
              onChange={formik.handleChange}
              value={formik.values.note}
            />
            <FieldArray name="questions">
              {({ push, remove, form }) => (
                <>
                  <span className="create-offer-text">
                    {t("questionForCandidates")}
                  </span>

                  {formik.values.questions.map((question, index) => (
                    <div key={index}>
                      <InputField
                        type="text"
                        placeholder=""
                        label={t("question") + " " + (index + 1)}
                        name={`questions.${index}`}
                        onBlur={formik.handleBlur}
                        touched={
                          formik.touched.questions &&
                          formik.touched.questions[index]
                        }
                        errors={
                          formik.errors.questions &&
                          formik.errors.questions[index]
                        }
                        onChange={formik.handleChange}
                        value={question}
                      />
                      <button
                        type="button"
                        className="offer-filter-btn-5"
                        onClick={() => {
                          remove(index);
                          formik.setFieldValue(
                            "questions",
                            formik.values.questions.filter(
                              (_, i) => i !== index
                            )
                          );
                        }}
                      >
                        {t("removeQuestion")}
                      </button>
                    </div>
                  ))}
                  <button
                    className="offer-filter-btn-type"
                    type="button"
                    onClick={() => {
                      push("");
                      formik.setFieldValue("questions", [
                        ...formik.values.questions,
                        "",
                      ]);
                    }}
                  >
                    {t("addQuestion")}
                  </button>
                </>
              )}
            </FieldArray>

            {error && (
              <span className="changes-message">
                {error?.response?.data?.message}
              </span>
            )}
            {response && (
              <span className="changes-message">{response?.message}</span>
            )}
            <button
              type="submit"
              className="form-submit"
              disabled={isLoading}
            //  onClick={() => formik.handleSubmit()}
            >
              {!isLoading ? (
                t("submit")
              ) : (
                <Oval
                  height={37}
                  width={37}
                  color="#f7f7f7"
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#cbd5e1"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              )}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CreateJobOffer;
